import React, { Fragment, useEffect, useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/auth/AuthContext";
import LandLordContext from "../context/landlord/LandLordContext";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import '../assets/css/auth.css'
import Loader from "../components/Loader";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/esm/Container";
import { BsLinkedin } from "react-icons/bs";
import useTranslator from "../hooks/useTranslator";
import i18next from "i18next";
import { haveValue } from "../helper/common";
import constants from "../Utils/constants";

const UserRole = () => {
  const { _t } = useTranslator();

  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { hash } = useLocation();

  const authContext = useContext(AuthContext);
  const { toggleLogin, loading, setLoading, loadUser, setUserType, userType } =
    authContext;
  const landLordContext = useContext(LandLordContext);
  const { loadingLandlord, demoFunLandlord, setProperties, properties } =
    landLordContext;

  const queryParameters = new URLSearchParams(window.location.search);
  const logincode = queryParameters.get("code");

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("i18nextLng").toLowerCase() || "en-us"
  );

  const handleLanguageChange = async (e) => {
    console.log(e.target.value);
    var langVal = e.target.value;
    await i18next.changeLanguage(langVal);
    console.log("Lng should be changed by now");
    setCurrentLang(langVal);
  };

  useEffect(() => {
    if (logincode) {
      setLoading(true);
      console.log(logincode);
      let user_type = localStorage.getItem("usertype");
      if (!user_type) {
        toast.error(_t("please select your user type"));
      } else {
        axios
          .get(
            `${process.env.REACT_APP_SERVER_URL
            }/users/linkedin-login/${logincode}?usertype=${user_type}&user_type=${window.location.hostname == "macc.skortorent.com"
              ? constants.MACC
              : constants.SKOR
            }`,
            {
              headers: {
                "Accept-Language": localStorage.getItem("i18nextLng"),
              },
            }
          )
          .then((res) => {
            let serverResponse = res;
            console.log(res.data);
            localStorage.setItem("token", serverResponse.data.token);
            toggleLogin(true);
            loadUser(user_type);
            setLoading(false);
            if (serverResponse.data.user.has_profile) {
              if (
                serverResponse.data.user.role == "tenant" &&
                !serverResponse.data.user.kyc_success
              ) {
                navigate("/tenant");
              } else {
                navigate("/home");
                //setting properties in landlord state
                console.log("profile exist of landlord");
              }
            } else {
              if (localStorage.getItem("usertype") == "tenant") {
                navigate("tenant");
              } else {
                navigate("/home");
              }
              console.log("profile  NOT exist");
            }
            //navigate('/home');
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            navigate("/login");
            //toast.error(err.data.message);
            toast.error(_t("Please sign up first using linkedin"));
          });
      }
    }
  }, [logincode]);

  const [userLogin, setUserLogin] = useState({
    email: "",
    password: "",
    type: "tenant",
    checkedTenant: true,
    checkedLandlord: false,
    checked: false,
    user_type:
      window.location.hostname == "macc.skortorent.com"
        ? constants.MACC
        : constants.SKOR,
  });

  useEffect(() => {
    const useeffectFunc = async () => {
      if (
        haveValue(queryParameters.get("lng")) &&
        queryParameters.get("lng") == "es"
      ) {
        await i18next.changeLanguage("es");
        setCurrentLang("es");
      } else if (
        haveValue(localStorage.getItem("i18nextLng")) &&
        localStorage.getItem("i18nextLng") == "es"
      ) {
        await i18next.changeLanguage("es");
        setCurrentLang("es");
      } else {
        await i18next.changeLanguage("en-us");
        setCurrentLang("en-us");
      }

      if (hash === "#tenant") {
        setUserType("tenant");
        setUserLogin({
          ...userLogin,
          checkedLandlord: false,
          checkedTenant: true,
          type: "tenant",
        });
      } else if (hash === "#landlord") {
        setUserType("landlord");
        setUserLogin({
          ...userLogin,
          checkedLandlord: true,
          checkedTenant: false,
          type: "landlord",
        });
      } else {
        setUserType("tenant");
        setUserLogin({
          ...userLogin,
          checkedLandlord: false,
          checkedTenant: true,
          type: "tenant",
        });
      }
    };

    useeffectFunc();
  }, []);

  const { checked, checkedLandlord, checkedTenant, user } = userLogin;

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    toast.error(_t("Invalid email address"));
    return false;
  };

  const onChange = (e) => {
    setUserLogin({ ...userLogin, [e.target.name]: e.target.value });
  };
  const handleCheck = (e) => {
    if (checked === false) {
      setUserLogin({ ...userLogin, checked: true });
    } else {
      setUserLogin({ ...userLogin, checked: false });
    }
  };
  const [loginType, setLoginType] = useState(null);

  const handleUsertype = (e) => {
    setUserType(e.target.value);
    if (e.target.value === "landlord") {
      setUserLogin({
        ...userLogin,
        checkedLandlord: true,
        checkedTenant: false,
        type: "landlord",
      });
    }
    if (e.target.value === "tenant") {
      setUserLogin({
        ...userLogin,
        checkedLandlord: false,
        checkedTenant: true,
        type: "tenant",
      });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(userLogin);
    //login(userLogin);

    if (userLogin.email === "" || userLogin.password === "") {
      toast.error(_t("Please Input Your Credentials"));
    } else {
      if (validateEmail(userLogin.email)) {
        setLoading(true);
        //login(userLogin);
        axios
          .post(`${process.env.REACT_APP_SERVER_URL}/users/login`, userLogin, {
            headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
          })
          .then((res) => {
            let serverResponse = res;
            console.log(serverResponse.data);
            if (serverResponse.data.token) {
              localStorage.setItem("token", serverResponse.data.token);
              localStorage.setItem("saveduserid", serverResponse.data.user._id);
              localStorage.setItem(
                "external_customer_id",
                serverResponse.data.user._id
              );
              setLoading(false);
              toggleLogin(true);
              loadUser(userLogin.type);
              //console.log(serverResponse.data.user.has_profile);
              snackbar.showMessage(_t("Logged In Successfully"));
              // navigate('/')
              if (serverResponse.data.user.has_profile) {
                if (
                  serverResponse.data.user.role == "tenant" &&
                  !serverResponse.data.user.kyc_success
                ) {
                  navigate("/tenant");
                } else {
                  navigate("/home");
                  //setting properties in landlord state
                  console.log("profile exist of landlord");
                }
              } else {
                if (localStorage.getItem("usertype") == "tenant") {
                  navigate("/tenant");
                } else {
                  navigate("/home");
                }
                console.log("profile  NOT exist");
              }
            } else {
              setLoading(false);
              toast.error(_t("Login Failed"));
            }
          })
          .catch((err) => {
            setLoading(false);
            let emsg = err;
            console.log(emsg);

            if (err.response) {
              toast.error(err?.response?.data?.message);
              toast.error(err?.response?.data?.msg);
            } else {
              toast.error(err?.message);
            }

            navigate("/login");
          });
      }
    }
  };

  const handleRedirectToLandingPage = () => {
    window.location.replace("https://skortorent.com/");
  };

  const handleRedirect = (URL) => {
    console.log(userLogin);
    if (
      userLogin?.checkedTenant == true ||
      userLogin?.checkedLandlord == true
    ) {
      navigate(`${URL}#${userLogin?.type}`);
      // navigate('/login#check')
    }
    // else {
    //   toast.error(_t("Please specify user type first"));
    // }
    console.log("hi");
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="Auth-wrapper">
        <img
          className="auth-shape-center"
          src="../images/LineAuth.svg"
          alt="logo"
        />

        <div className="Auth-bar">
          <div className="authbar">
            <div className="logobar" onClick={handleRedirectToLandingPage}>
              <Container>
                {userLogin.user_type == constants.MACC ? (
                  <>
                    <img
                      style={{ width: "20vw" }}
                      src="/images/Macc.svg"
                      alt=""
                    />
                  </>
                ) : (
                  <Link to="https://skortorent.com/">
                    <img src="../images/logo.svg" alt="logo" />
                  </Link>
                )}
              </Container>
            </div>
            <Container>
              <div className="authbar-inner">
                <div className="leftbar">
                  <div className="hero-center">
                    {userLogin["type"] == "tenant" ? (
                      <img src="../images/TenantUserRole.webp" alt="logo" />
                    ) : (
                      <img
                        className="signp_img"
                        src="../images/LandlordUserRole.webp"
                        alt="logo"
                      />
                    )}
                  </div>
                </div>
                <div className="rightbar">
                  <img
                    className="auth-shapetopright"
                    src="../images/handAuth.svg"
                    alt="logo"
                  />
                  <div className="right-contentbar">
                    <div className="heading_lng_div">
                      <h4>{_t("Get started as")}</h4>
                      <div class="menu-togglebar">
                        <label class="customradiobtn">
                          <input
                            type="radio"
                            onClick={handleLanguageChange}
                            checked={currentLang == "en-us" ? "checked" : ""}
                            name="radio"
                            value={"en-us"}
                          />
                          <span class="checkmark1">EN</span>
                        </label>
                        <label class="customradiobtn">
                          <input
                            type="radio"
                            onClick={handleLanguageChange}
                            checked={currentLang == "es" ? "checked" : ""}
                            name="radio"
                            value={"es"}
                          />
                          <span class="checkmark1">ES</span>
                        </label>
                      </div>
                    </div>
                    <form onSubmit={onSubmit}>
                      <div className="checkboxbar">
                        <div className="inlinecheckbar">
                          <div className="uilabel">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="tenant"
                              id="flexCheckDefaultTenant"
                              checked={checkedTenant}
                              onChange={handleUsertype}
                            />
                            <label
                              className="form-check-label img-diff"
                              htmlFor="flexCheckDefaultTenant"
                            >
                              <div className="imgbar">
                                <img src="/images/t1.svg" alt="icon" />
                              </div>
                              <div className="contentbar">
                                <h5>{_t("Tenant")}</h5>
                              </div>
                            </label>
                          </div>
                          <div className="uilabel">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="landlord"
                              id="flexCheckDefaultLandlord"
                              checked={checkedLandlord}
                              onChange={handleUsertype}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefaultLandlord"
                            >
                              <div className="imgbar">
                                <img src="/images/t2.svg" alt="icon" />
                              </div>
                              <div className="contentbar">
                                <h5>{_t("Landlord")}</h5>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="btngroup">
                        <a
                          href={undefined}
                          onClick={(e) => handleRedirect("/login")}
                          className="btn btnoutline"
                          type="submit"
                        >
                          {_t("Login")}
                        </a>
                        <a
                          href={undefined}
                          onClick={(e) => handleRedirect("/register")}
                          className="btn btnorange"
                          type="submit"
                        >
                          {_t("Sign Up")}
                        </a>
                      </div>
                    </form>
                  </div>
                  <div className="hero-foot">
                    <Container>
                      <div className="terms-bar">
                        {localStorage.getItem("i18nextLng") == "es" ? (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/es/terms-conditions.html"
                          >
                            {_t("Terms & Conditions")}
                          </Link>
                        ) : (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/terms-conditions.html"
                          >
                            {_t("Terms & Conditions")}
                          </Link>
                        )}
                        {localStorage.getItem("i18nextLng") == "es" ? (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/es/privacy-policy.html"
                          >
                            {" "}
                            {_t("Privacy Policy")}
                          </Link>
                        ) : (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/privacy-policy.html"
                          >
                            {" "}
                            {_t("Privacy Policy")}
                          </Link>
                        )}
                        {localStorage.getItem("i18nextLng") == "es" ? (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/es/cookies-es.html"
                          >
                            {" "}
                            {_t("Cookies Policy")}
                          </Link>
                        ) : (
                          <Link
                            className="link-bar"
                            to="https://skortorent.com/cookies.html"
                          >
                            {" "}
                            {_t("Cookies Policy")}
                          </Link>
                        )}
                      </div>
                    </Container>
                  </div>
                  {/* <img
                    className="logo-img"
                    src="../images/logo.svg"
                    alt="logo"
                  /> */}
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserRole;
