import { Container } from "react-bootstrap";
import useTranslator from "../../../hooks/useTranslator";
import Select from "react-select";
import { useContext, useEffect, useMemo, useState } from "react";
import countryList from "react-select-country-list";
import AuthContext from "../../../context/auth/AuthContext";
import { haveValue } from "../../../helper/common";
import { Link, useNavigate } from "react-router-dom";
import { withAsyncPaginate } from "react-select-async-paginate";
import Creatable from "react-select/creatable";
import TenantContext from "../../../context/tenant/TenantContext";
import constants from "../../../Utils/constants";
import { MdInfoOutline } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";

const TenantCompleteProfile = () => {
  const { _t } = useTranslator();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const { user, setLoading, loadUser } = authContext;
  const { getUniversityListing, updateTenant } = tenantContext;
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [step, setStep] = useState(1);
  const [countrySelect, setCountrySelect] = useState(
    haveValue(user?.nationality)
      ? { value: user?.nationality, label: user?.nationality }
      : {}
  );
  const [formData, setFormData] = useState({});
  const [uniSelect, setUniSelect] = useState(
    haveValue(user?.name_of_university)
      ? { value: user?.name_of_university, label: user?.name_of_university }
      : {}
  );
  const [currUniSelect, setCurrUniSelect] = useState(
    haveValue(user?.name_of_current_university)
      ? {
        value: user?.name_of_current_university,
        label: user?.name_of_current_university,
      }
      : {}
  );
  const [idType, setIdType] = useState(
    haveValue(user?.passport_id) ? "passport_id" : "dni_nie"
  );

  useEffect(() => {
    applyProfileData();
  }, [user]);

  const options = useMemo(() => {
    return countryList()
      .getData()
      .map((country) => ({
        ...country,
        label: _t(country.label), // Wrap the label with the translation function
        value: country.label,
      }));
  }, [_t]);

  const CreatableAsyncPaginate = withAsyncPaginate(Creatable);
  const formatCreateLabel = (inputValue) => {
    if (isNaN(Number(inputValue)))
      return (
        <div className="btngroup row100">
          <button className="btn btnorange btn-selectbox">
            {/* <img src="/images/add-white.svg" alt="" /> */}
            {_t("Cant find your Institution? Type and Add")}
          </button>
        </div>
      );
    else return null;
  };

  const applyProfileData = () => {
    setFormData({
      fname: user?.fname,
      lname: user?.lname,
      fname_companyName: user?.fname_companyName,
      phone_number: user?.phone_number,
      nationality: user?.nationality || "",
      dni_nie: user?.dni_nie || "",
      passport_id: user?.passport_id || "",
      duration_type: user?.duration_type || "",
      years_of_living_in_country: user?.years_of_living_in_country || "",
      rented_locally_before: user?.rented_locally_before || "",
      email_previous_landlord: user?.email_previous_landlord || "",
      type_of_student: user?.type_of_student || "",
      name_of_university: user?.name_of_university || "",
      employment_status: user?.employment_status || "",
      position: user?.position || "",
      name_of_company: user?.name_of_company || "",
      current_annual_income: user?.current_annual_income || "",
      email_of_university: user?.email_of_university || "",
      role: user?.role || "",
      is_freelancer: user?.is_freelancer || "false",
      landlordtype: user?.landlordtype || "",
      isLandlordInComp: user?.isLandlordInComp || "",
      company_name: user?.company_name || "",
      email: user?.email,
      cif_dni_nie: user?.cif_dni_nie || "",
      current_course: user?.current_course || "",
      name_of_current_university: user?.name_of_current_university || "",
      university_end_date: user?.university_end_date || "",
      have_sharer: user?.have_sharer || "",
      sharers:
        user?.sharers.length > 0 ? user?.sharers : [{ name: "", email: "" }],
      lang: localStorage.getItem("i18nextLng"),
      // profile_completed: true,
    });
  };

  const handleNationalityChange = (e) => {
    console.log(e, "check change here");
    setFormData((prevData) => ({ ...prevData, nationality: e.value }));
    setCountrySelect(e);
  };

  const handleGoNext = () => {
    setCurrentStep((prev) => prev + 1);
    setStep((prev) => prev + 1);
    handleSaveData();
  };

  const handleGoBack = () => {
    setCurrentStep((prev) => prev - 1);
    setStep((prev) => prev - 1);
  };

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
    handleSaveData();
  };

  const handleBackStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleIdTypeChange = (type) => {
    setIdType(type);
    if (type == "dni_nie") {
      setFormData((prevData) => ({ ...prevData, passport_id: "" }));
    } else {
      setFormData((prevData) => ({ ...prevData, dni_nie: "" }));
    }
  };

  const loadOptions = async (search, loadedOptions) => {
    const results = await getUniversityListing(search, loadedOptions);
    const responseJSON = results.success_res.universityListing.map((res) => ({
      label: res._source.name,
      value: res._source.name,
    }));
    var hasMore = false;
    if (results.success_res.count > loadedOptions.length * 10) {
      hasMore = true;
    }

    return {
      options: responseJSON,
      hasMore: hasMore,
    };
  };

  const handleSelectChange = (e) => {
    console.log(e, "uni data here");
    setFormData((prevData) => ({ ...prevData, name_of_university: e.label }));
    setUniSelect(e);
  };

  const handleCurrSelectChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      name_of_current_university: e.label,
    }));
    setCurrUniSelect(e);
  };

  const handleSharerChange = (index, e) => {
    const { name, value } = e.target;
    const sharers = [...formData.sharers];
    sharers[index][name] = value;
    setFormData((prev) => {
      return { ...prev, sharers };
    });
  };

  const handleAddMoreComponent = () => {
    setFormData((prevState) => ({
      ...prevState,
      sharers: [...prevState.sharers, { name: "", email: "", relation: "" }],
    }));
  };

  const handleSubmit = () => {
    const usertype = localStorage.getItem("usertype");
    formData.role = usertype;
    formData.profile_completed = true;
    formData.lang = localStorage.getItem("i18nextLng");
    let userid = localStorage.getItem("saveduserid");
    createUserProfile(userid, formData);
  };
  const handleSaveData = () => {
    const usertype = localStorage.getItem("usertype");
    formData.role = usertype;
    let userid = localStorage.getItem("saveduserid");
    saveCurrentStep(userid, formData);
  };

  const saveCurrentStep = async (id, profileData) => {
    const temp = { ...profileData, is_edit: true };
    axios
      .patch(`${process.env.REACT_APP_SERVER_URL}/users/profile/${id}`, temp, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then(async (res) => { })
      .catch((err) => {
        let serverResponse = err;
      });
  };
  const createUserProfile = async (id, profileData) => {
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/users/profile/${id}`,
        profileData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then(async (res) => {
        setLoading(false);
        const usertype = localStorage.getItem("usertype");
        await loadUser(usertype);
        addRewardPoints();
        setCurrentStep(8);
      })
      .catch((err) => {
        setLoading(false);
        let serverResponse = err;
        toast.error(serverResponse.response.data.error);
      });
  };

  const addRewardPoints = () => {
    const data = {
      reward_points:
        (user?.reward_points || 0) + +constants.PROFILE_COMPLETE_POINTS,
    };
    updateTenant(data);
  };

  const navigateHome = () => {
    navigate("/tenant");
  };

  const handleNavigateNextStep = () => {
    navigate("/tenant/verifyId");
  };

  return (
    <>
      <div className="step-form-main-wrapper">
        <Container className="p-0 text-center step-form-header">
          <div className="d-flex justify-content-between align-items-center">
            {currentStep != 8 && (
              <div className="">
                <a
                  href={undefined}
                  className="d-flex gap-2 align-items-center back-btn"
                  onClick={navigateHome}
                >
                  <img src="/images/back-btn-white.svg" alt="" />
                  {/* <span>{_t("Exit to home")} </span> */}
                </a>
              </div>
            )}
            <h3 className="step-name d-flex align-items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="54"
                height="54"
                viewBox="0 0 54 54"
                fill="none"
              >
                <circle cx="27" cy="27" r="27" fill="#253837" />
                <path
                  d="M32.12 24.88C31.5577 24.3177 30.7952 24.0019 30 24.0019C29.2048 24.0019 28.4423 24.3177 27.88 24.88C27.3177 25.4423 27.0019 26.2048 27.0019 27C27.0019 27.7952 27.3177 28.5577 27.88 29.12C28.1584 29.3984 28.4889 29.6192 28.8527 29.7699C29.2164 29.9206 29.6063 29.9981 30 29.9981C30.3937 29.9981 30.7836 29.9206 31.1473 29.7699C31.5111 29.6192 31.8416 29.3984 32.12 29.12C32.3984 28.8416 32.6192 28.5111 32.7699 28.1473C32.9206 27.7836 32.9981 27.3937 32.9981 27C32.9981 26.6063 32.9206 26.2164 32.7699 25.8527C32.6192 25.4889 32.3984 25.1584 32.12 24.88ZM22 21V33H38V21H22ZM36 29C35.47 29 34.96 29.21 34.59 29.59C34.21 29.96 34 30.47 34 31H26C26 30.47 25.79 29.96 25.41 29.59C25.04 29.21 24.53 29 24 29V25C24.53 25 25.04 24.79 25.41 24.41C25.79 24.04 26 23.53 26 23H34C34 23.53 34.21 24.04 34.59 24.41C34.96 24.79 35.47 25 36 25V29ZM20 23H18C17.45 23 17 22.55 17 22C17 21.45 17.45 21 18 21H20V23ZM20 28H17C16.45 28 16 27.55 16 27C16 26.45 16.45 26 17 26H20V28ZM20 33H16C15.448 33 15 32.55 15 32C15 31.45 15.448 31 16 31H20V33Z"
                  fill="#A3EB64"
                />
              </svg>
              <span>{_t("Complete Profile")}</span>{" "}
            </h3>
            {currentStep != 8 && (
              <p className="step-count">
                <span>
                  {"Step"} {step}
                </span>{" "}
                of 5
              </p>
            )}
          </div>
        </Container>
        {currentStep != 8 && (
          <div className="logobarwrapper">
            <Container className="progress-bar">
              <ul className="progress-ul">
                <li
                  className={`progress-li ${step == 1 ? "current-progress" : ""
                    }  ${step > 1 ? "current-completed" : ""}`}
                ></li>
                <li
                  className={`progress-li ${step == 2 ? "current-progress" : ""
                    }  ${step > 2 ? "current-completed" : ""}`}
                ></li>
                <li
                  className={`progress-li ${step == 3 ? "current-progress" : ""
                    }  ${step > 3 ? "current-completed" : ""}`}
                ></li>
                <li
                  className={`progress-li ${step == 4 ? "current-progress" : ""
                    }  ${step > 4 ? "current-completed" : ""}`}
                ></li>
                <li
                  className={`progress-li ${step == 5 ? "current-progress" : ""
                    }  ${step > 5 ? "current-completed" : ""}`}
                ></li>
              </ul>
            </Container>
          </div>
        )}
        {currentStep == 1 && (
          <div className="steps-wrapper">
            <h3 className="step-name">{"What is your nationality"}?</h3>
            <p className="label-text">{"Nationality"}</p>
            <Select
              options={options}
              value={countrySelect}
              onChange={handleNationalityChange}
            />
            <button
              className="btn btngreen step-btn"
              disabled={!haveValue(formData.nationality)}
              onClick={handleGoNext}
            >
              {"Next"}
            </button>
          </div>
        )}
        {currentStep == 2 &&
          (idType == "dni_nie" ? (
            <div className="steps-wrapper">
              <h3 className="step-name">{_t("Provide your DNI/NIE number")}</h3>
              <p className="label-text">{_t("DNI/NIE Number")}</p>
              <input
                type="text"
                className="form-control user-auth"
                name="dni_nie"
                value={formData.dni_nie}
                required
                onChange={handleInputChange}
                placeholder={_t("Enter DNI/NIE Number")}
              />
              <div className="orangetext">
                <Link onClick={(e) => handleIdTypeChange("passport_id")}>
                  {_t("Do you have a DNI? NIE number?")}?
                </Link>
              </div>
              <div className="d-flex align-items-center gap-3 step-btns-group">
                <button
                  className="btn btnoutline step-btn"
                  type="button"
                  onClick={handleGoBack}
                >
                  {_t("Go Back")}
                </button>
                <button
                  className="btn btnorange step-btn"
                  onClick={handleGoNext}
                  disabled={!haveValue(formData.dni_nie)}
                >
                  {_t("Next")}
                </button>
              </div>
            </div>
          ) : (
            <div className="steps-wrapper">
              <h3 className="step-name">
                {_t("Provide your passport number")}
              </h3>
              <p className="label-text">{_t("Passport Number")}</p>
              <input
                type="text"
                className="form-control user-auth"
                name="passport_id"
                value={formData.passport_id}
                required
                onChange={handleInputChange}
                placeholder={_t("Enter Passport Number")}
              />
              <div className="orangetext">
                <Link onClick={(e) => handleIdTypeChange("dni_nie")}>
                  {_t("Want to enter DNI/NIE number instead")}?
                </Link>
              </div>
              <div className="d-flex align-items-center gap-3 step-btns-group">
                <button
                  className="btn btnoutline step-btn"
                  type="button"
                  onClick={handleGoBack}
                >
                  {_t("Go Back")}
                </button>
                <button
                  className="btn btnorange step-btn"
                  onClick={handleGoNext}
                  disabled={!haveValue(formData.passport_id)}
                >
                  {_t("Next")}
                </button>
              </div>
            </div>
          ))}
        {currentStep == 3 && (
          <div className="steps-wrapper">
            <h3 className="step-name">{_t("Highest completed education")}</h3>
            <div className="forminput row50">
              <label className="forminput">
                {_t("Highest Education Level")}
              </label>
              <select
                id="degree"
                name="type_of_student"
                value={formData.type_of_student}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">{_t("Select a degree")}</option>
                <option data-key="degree" value="Associate's">
                  {_t("Associate's")}
                </option>
                <option data-key="degree" value="Bachelor's">
                  {_t("Bachelor's")}
                </option>
                <option data-key="degree" value="Master's">
                  {_t("Master's")}
                </option>
                <option data-key="degree" value="Doctorate">
                  {_t("Doctorate")}
                </option>
                <option data-key="degree" value="Certificate / Diploma">
                  {_t("Certificate / Diploma")}
                </option>
                <option data-key="degree" value="Graduate Certificates">
                  {_t("Graduate Certificates")}
                </option>
                <option data-key="degree" value="Bootcamps">
                  {_t("Bootcamps")}
                </option>
              </select>
            </div>
            <div className="forminput row50">
              <label className="forminput">{_t("Institution Name")}</label>
              <CreatableAsyncPaginate
                value={uniSelect}
                loadOptions={loadOptions}
                className="form-control user-auth uni_select"
                name="name_of_university"
                onChange={handleSelectChange}
                isCreatable={true}
                formatCreateLabel={formatCreateLabel}
              />
            </div>
            <div className="d-flex align-items-center gap-3 step-btns-group">
              <button
                className="btn btnoutline step-btn"
                type="button"
                onClick={handleGoBack}
              >
                {_t("Go Back")}
              </button>
              <button
                className="btn btnorange step-btn"
                onClick={handleGoNext}
                disabled={
                  haveValue(formData.name_of_university) &&
                    haveValue(formData.type_of_student)
                    ? false
                    : true
                }
              >
                {_t("Next")}
              </button>
            </div>
          </div>
        )}
        {currentStep == 4 && (
          <div className="steps-wrapper">
            <h3 className="step-name">
              {_t("What is your employment status")}?
            </h3>
            <p className="label-text">{_t("Employment Status")}</p>
            <select
              className="form-control"
              name="employment_status"
              value={formData?.employment_status}
              onChange={handleInputChange}
            >
              <option value="">{_t("Please Select")}</option>
              <option value={constants.employed}>{_t("Employed")}</option>
              <option value={constants.self_employed}>
                {_t("Self-employed")}
              </option>
              <option value={constants.student}>{_t("Student")}</option>
              <option value={constants.unemployed}>{_t("Unemployed")} </option>
              <option value={constants.retired}>{_t("Retired")} </option>
            </select>
            <div className="d-flex align-items-center gap-3 step-btns-group">
              <button
                className="btn btnoutline step-btn"
                type="button"
                onClick={handleGoBack}
              >
                {_t("Go Back")}
              </button>
              <button
                className="btn btnorange step-btn"
                onClick={handleNextStep}
                disabled={!haveValue(formData?.employment_status)}
              >
                {_t("Next")}
              </button>
            </div>
          </div>
        )}
        {currentStep == 5 && (
          <div className="steps-wrapper">
            {formData?.employment_status == constants.employed && (
              <>
                <h3 className="step-name">{_t("Your job details")}</h3>
                <div className="forminput">
                  <label className="forminput label-text">
                    {_t("Position")}
                  </label>
                  <input
                    required
                    type="text"
                    className="form-control user-auth"
                    name="position"
                    value={formData.position}
                    onChange={handleInputChange}
                    placeholder={_t("Enter Position ")}
                  />
                </div>
                <div className="forminput">
                  <label className="forminput label-text">
                    {_t("Name Of Employer/Company")}
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control user-auth"
                    name="name_of_company"
                    value={formData.name_of_company}
                    onChange={handleInputChange}
                    placeholder={_t("Enter Employer/Company ")}
                  />
                </div>
                <div className="forminput">
                  <label className="forminput annual_income_tooltip label-text">
                    {_t("Annual Income")}
                    <ul className="infodorpdown summarydropdum">
                      <li>
                        <button className="iconinfo">
                          <MdInfoOutline />
                        </button>{" "}
                      </li>
                      <ul className="tooltip">
                        <li>
                          <p className=" label-text">
                            {_t(
                              "Enter your annual income accurately to help us match and verify your banking records for a seamless verification process"
                            )}
                            .
                          </p>
                        </li>
                      </ul>
                    </ul>
                  </label>
                  <input
                    type="number"
                    required
                    className="form-control user-auth"
                    name="current_annual_income"
                    value={formData.current_annual_income}
                    onChange={handleInputChange}
                    placeholder={_t("Declare your Income")}
                  />
                </div>

                <div className="d-flex align-items-center btngroup gap-3 step-btns-group">
                  <button
                    className="btn btnoutline step-btn"
                    type="button"
                    onClick={handleBackStep}
                  >
                    {_t("Go Back")}
                  </button>
                  <button
                    className="btn btnorange step-btn"
                    onClick={handleGoNext}
                    disabled={
                      haveValue(formData.position) &&
                        haveValue(formData.name_of_company) &&
                        haveValue(formData.current_annual_income)
                        ? false
                        : true
                    }
                  >
                    {_t("Next")}
                  </button>
                </div>
              </>
            )}
            {formData?.employment_status == constants.self_employed && (
              <>
                <h3 className="step-name">
                  {_t("Your self employed income details")}
                </h3>
                <label className="forminput label-text">
                  {_t("Employment Type")}
                </label>
                <div className="customradio_box height-equal">
                  <label className="forminput label-text">
                    <input
                      type="radio"
                      required
                      className="user-auth"
                      name="is_freelancer"
                      checked={formData.is_freelancer == "false"}
                      value={false}
                      onChange={handleInputChange}
                      placeholder={_t("Enter")}
                    />
                    {_t("Own a Company")}
                  </label>
                  <label className="forminput label-text">
                    <input
                      type="radio"
                      required
                      className="user-auth"
                      name="is_freelancer"
                      checked={formData.is_freelancer == "true"}
                      value={true}
                      onChange={handleInputChange}
                      placeholder={_t("Enter")}
                    />
                    {_t("Freelancer")}
                  </label>
                </div>
                {formData.is_freelancer == "false" && (
                  <div className="forminput row100">
                    <label className="forminput">{_t("Company Name")}</label>
                    <input
                      type="text"
                      required
                      className="form-control user-auth"
                      name="name_of_company"
                      value={formData.name_of_company}
                      onChange={handleInputChange}
                      placeholder={_t("Enter")}
                    />
                  </div>
                )}
                <div className="forminput row100">
                  <label className="forminput annual_income_tooltip">
                    {_t("Annual Income")}
                    <ul className="infodorpdown summarydropdum">
                      <li>
                        <button className="iconinfo">
                          <MdInfoOutline />
                        </button>{" "}
                      </li>
                      <ul className="tooltip">
                        <li>
                          <p>
                            {_t(
                              "Enter your annual income accurately to help us match and verify your banking records for a seamless verification process"
                            )}
                            .
                          </p>
                        </li>
                      </ul>
                    </ul>
                  </label>
                  <input
                    type="number"
                    required
                    className="form-control user-auth"
                    name="current_annual_income"
                    value={formData.current_annual_income}
                    onChange={handleInputChange}
                    placeholder={_t("Enter")}
                  />
                </div>
                <div className="d-flex align-items-center btngroup gap-3 step-btns-group">
                  <button
                    className="btn btnoutline step-btn"
                    type="button"
                    onClick={handleBackStep}
                  >
                    {_t("Go Back")}
                  </button>
                  <button
                    disabled={
                      haveValue(formData.current_annual_income) ? false : true
                    }
                    className="btn btnorange step-btn"
                    onClick={handleGoNext}
                  >
                    {_t("Next")}
                  </button>
                </div>
              </>
            )}
            {formData?.employment_status == constants.student && (
              <>
                <h3 className="step-name">{_t("Your institution details")}</h3>
                <div className="forminput">
                  <label className="forminput label-text">
                    {_t("Education Level")}
                  </label>
                  <select
                    id="degree"
                    name="current_course"
                    value={formData.current_course}
                    onChange={handleInputChange}
                    className="form-control"
                  >
                    <option value="">{_t("Select a degree")}</option>
                    <option data-key="degree" value="Associate's">
                      {_t("Associate's")}
                    </option>
                    <option data-key="degree" value="Bachelor's">
                      {_t("Bachelor's")}
                    </option>
                    <option data-key="degree" value="Master's">
                      {_t("Master's")}
                    </option>
                    <option data-key="degree" value="Doctorate">
                      {_t("Doctorate")}
                    </option>
                    <option data-key="degree" value="Certificate / Diploma">
                      {_t("Certificate / Diploma")}
                    </option>
                    <option data-key="degree" value="Graduate Certificates">
                      {_t("Graduate Certificates")}
                    </option>
                    <option data-key="degree" value="Bootcamps">
                      {_t("Bootcamps")}
                    </option>
                  </select>
                </div>
                <div className="forminput">
                  <label className="forminput label-text">
                    {_t("Institution")}
                  </label>
                  <CreatableAsyncPaginate
                    value={currUniSelect}
                    loadOptions={loadOptions}
                    className="form-control user-auth uni_select"
                    name="name_of_current_university"
                    onChange={handleCurrSelectChange}
                    isCreatable={true}
                    formatCreateLabel={formatCreateLabel}
                  />
                </div>
                <div className="forminput">
                  <label className="forminput label-text">
                    {_t("Institution Email")}
                  </label>
                  <input
                    type="text"
                    className="form-control user-auth"
                    name="email_of_university"
                    value={formData.email_of_university}
                    onChange={handleInputChange}
                    required
                    placeholder={_t("Enter Your Institution Email")}
                  />
                </div>
                <div className="forminput">
                  <label className="forminput label-text">
                    {_t("Expected Course End Date")}
                  </label>
                  <input
                    type="date"
                    className="form-control user-auth"
                    name="university_end_date"
                    value={formData.university_end_date}
                    onChange={handleInputChange}
                    required
                  // placeholder={_t("Enter Your Institution Email")}
                  />
                </div>
                <div className="forminput">
                  <label className="forminput label-text">
                    {_t("Current Annual Income")}
                  </label>
                  <input
                    type="number"
                    className="form-control user-auth"
                    name="current_annual_income"
                    value={formData.current_annual_income}
                    onChange={handleInputChange}
                    placeholder={_t("Enter Income (If Applicable)")}
                  />
                </div>
                <div className="d-flex align-items-center gap-3 step-btns-group">
                  <button
                    className="btn btnoutline step-btn"
                    type="button"
                    onClick={handleBackStep}
                  >
                    {_t("Go Back")}
                  </button>
                  <button
                    disabled={
                      haveValue(formData.current_course) &&
                        haveValue(formData.name_of_current_university) &&
                        haveValue(formData.email_of_university) &&
                        haveValue(formData.university_end_date)
                        ? false
                        : true
                    }
                    className="btn btnorange step-btn"
                    onClick={handleGoNext}
                  >
                    {_t("Next")}
                  </button>
                </div>
              </>
            )}
            {(formData?.employment_status == constants.unemployed ||
              formData?.employment_status == constants.retired) && (
                <>
                  <h3 className="step-name">{_t("Income details")}</h3>
                  <label className="forminput annual_income_tooltip label-text">
                    {_t("Annual Income")}
                    <ul className="infodorpdown summarydropdum">
                      <li>
                        <button className="iconinfo">
                          <MdInfoOutline />
                        </button>{" "}
                      </li>
                      <ul className="tooltip">
                        <li>
                          <p className="label-text">
                            {_t(
                              "Enter your annual income accurately to help us match and verify your banking records for a seamless verification process"
                            )}
                            .
                          </p>
                        </li>
                      </ul>
                    </ul>
                  </label>
                  <input
                    type="number"
                    required
                    className="form-control user-auth"
                    name="current_annual_income"
                    value={formData.current_annual_income}
                    onChange={handleInputChange}
                    placeholder={_t("Enter")}
                  />
                  <div className="d-flex align-items-center gap-3 step-btns-group">
                    <button
                      className="btn btnoutline step-btn"
                      type="button"
                      onClick={handleBackStep}
                    >
                      {_t("Go Back")}
                    </button>
                    <button
                      disabled={
                        haveValue(formData.current_annual_income) ? false : true
                      }
                      className="btn btnorange step-btn"
                      onClick={handleGoNext}
                    >
                      {_t("Next")}
                    </button>
                  </div>
                </>
              )}
          </div>
        )}
        {currentStep == 6 && (
          <div className="steps-wrapper">
            <h3 className="step-name">{_t("Will you share your rent")}?</h3>

            <div className="two-items-row">
              <label class="customradiobar forminput">
                <input
                  type="radio"
                  name="have_sharer"
                  onChange={handleInputChange}
                  checked={formData?.have_sharer == "yes" ? true : false}
                  value="yes"
                />
                <div class="boxes">{_t("Yes, I will be sharing it")}</div>
              </label>
              <label class="customradiobar forminput">
                <input
                  type="radio"
                  name="have_sharer"
                  onChange={handleInputChange}
                  checked={formData?.have_sharer == "no" ? true : false}
                  value="no"
                />
                <div class="boxes">{_t("No, I will be the only one")}</div>
              </label>
            </div>
            <div className="d-flex align-items-center gap-3 step-btns-group">
              <button
                className="btn btnoutline step-btn"
                type="button"
                onClick={handleGoBack}
              >
                {_t("Go Back")}
              </button>
              <button
                className="btn btnorange step-btn"
                onClick={
                  formData?.have_sharer == "yes" ? handleNextStep : handleSubmit
                }
                disabled={!haveValue(formData?.have_sharer)}
              >
                {_t("Next")}
              </button>
            </div>
          </div>
        )}
        {currentStep == 7 && (
          <div className="steps-wrapper">
            <h3 className="step-name">{_t("Sharer Details")}</h3>
            {formData.sharers.map((sharer, index) => (
              <div id={`flatmate_div_${index}`} className="row100" key={index}>
                <div className="two-items-row">
                  <div className="forminput">
                    <input
                      type="text"
                      className="form-control user-auth"
                      name="name"
                      value={sharer.name}
                      onChange={(e) => handleSharerChange(index, e)}
                      required
                      placeholder="Enter Name"
                    />
                  </div>
                  <div className="forminput">
                    <input
                      type="text"
                      className="form-control user-auth"
                      name="email"
                      value={sharer.email}
                      onChange={(e) => handleSharerChange(index, e)}
                      required
                      placeholder="Enter Email"
                    />
                  </div>
                </div>
                <div className="forminput">
                  <select
                    name="relation"
                    id=""
                    value={sharer?.relation}
                    onChange={(e) => handleSharerChange(index, e)}
                    className="form-control"
                  >
                    <option value="" selected>
                      {_t("Please Select")}
                    </option>
                    <option value="friend">{_t("Friend")}</option>
                    <option value="wife">{_t("Wife")}</option>
                    <option value="couple">{_t("Couple")}</option>
                    <option value="colleague">{_t("Colleague")}</option>
                    <option value="other">{_t("Other")}</option>
                  </select>
                </div>
              </div>
            ))}

            <button
              className="add-more-btn step-btn"
              type="button"
              onClick={handleAddMoreComponent}
            >
              <img src="/images/add-orange.svg" alt="" />
              {_t("Add More")}
            </button>
            <div className="d-flex align-items-center gap-3 step-btns-group">
              <button
                className="btn btnoutline step-btn"
                type="button"
                onClick={handleGoBack}
              >
                {_t("Go Back")}
              </button>
              <button className="btn btnorange step-btn" onClick={handleSubmit}>
                {_t("Submit")}
              </button>
            </div>
          </div>
        )}
        {currentStep == 8 && (
          <div className="step-complete-reward steps-wrapper">
            <div className="rewards-points">
              <span className="points">20</span>
              <p>{_t("Reward Points")}</p>
            </div>
            <p className="greeting-text">{_t("Congratulations")}</p>
            <p className="points-info">
              {_t(
                "You’ve completed your profile info and have been rewarded with 20 points"
              )}
            </p>
            <button className="btn btnorange" onClick={handleNavigateNextStep}>
              {_t("Continue to verify your Id")}
            </button>
            <button className="step-go-home-btn" onClick={navigateHome}>
              {_t("Go Back home")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default TenantCompleteProfile;
