import * as Joi from "joi-browser";

export const haveValue = (val) => {
  if (
    val !== "" &&
    val !== "undefined" &&
    val !== undefined &&
    val !== null &&
    val !== "null"
  ) {
    return true;
  } else {
    return false;
  }
};

export const withBackendUrl = (path, defaultPath = null) => {
  if (typeof path === "object") {
    let src = URL.createObjectURL(path);
    return src;
  } else {
    if (haveValue(path)) {
      path = path?.toString();
      if (path?.startsWith("http://") || path?.startsWith("https://")) {
        return path;
      } else {
        if (path.startsWith("/")) {
          path = path.substring(1);
        }
        return process.env.REACT_APP_SERVER_URL + "/" + path;
      }
    } else {
      return "";
    }
  }
};
export const toLowerCase = (data) => {
  return data.toString().toLowerCase();
};

export const convertCurrencyFormat = (amount) => {
  return new Intl.NumberFormat(localStorage.getItem("i18nextLng"), {
    currency: localStorage.getItem("i18nextLng") === "es" ? "EUR" : "USD",
  }).format(amount);
};

export const toUpperCase = (data) => {
  return data.toString().toUpperCase();
};

export const validateJOIFormField = (formFields, schema) => {
  const result = Joi.validate(formFields, schema, {
    abortEarly: false,
    stripUnknown: true,
  });
  const { error } = result;
  if (!error) {
    return null;
  } else {
    const errorData = {};
    for (let item of error.details) {
      const name = item.path[0];
      errorData[name] = item.message;
    }
    return errorData;
  }
};

export const validateJOIProperty = (schema, event) => {
  const { name, value } = event.target;
  console.log(value, "check iban value");
  const obj = { [name]: value };
  if (schema.hasOwnProperty(name)) {
    const subSchema = { [name]: schema[name] };
    const result = Joi.validate(obj, subSchema, {
      stripUnknown: true,
    });
    const { error } = result;
    return error ? error.details[0].message : null;
  } else {
    return null;
  }
};

export const renderErrorMessage = (errors, type) => {
  if (errors && errors[type]) {
    return errors[type];
  }
  return "";
};

export const getBaseUrl = () => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  return baseUrl;
};
