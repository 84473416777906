import { Modal } from "react-bootstrap"
import useTranslator from "../hooks/useTranslator"


const ScoreWaitModal = (props) => {

    const { _t } = useTranslator()
    const { waitBank } = props

    return (
        <>
            <Modal show={waitBank} centered className="newmodalui asguarantor fetching_algoan_modal">
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="already_guarantor">
                        <img src="../../images/fetchingBank.svg" alt="" />
                        <h3>{_t("Generating Skor")}...</h3>
                        <h4>{_t("Please Wait While We Are Fetching your details to get your Skor")}</h4>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ScoreWaitModal