import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AuthContext from '../context/auth/AuthContext';
import { haveValue } from '../helper/common';
import { useCookies } from 'react-cookie';


const ProtectedRoute = ({ auth, component, ...rest }) => {

    const authContext = useContext(AuthContext);
    const { user, loadUser, logout } = authContext
    const navigate = useNavigate()
    const params = useParams();
    const { assignedPropertyId = null } = params


    if (window.location.host === process.env.REACT_APP_MACC_URL_NAME) {
        // Retrieve values from localStorage
        const token = localStorage.getItem('token');
        const is_verified = localStorage.getItem('is_verified');
        if (token && is_verified) {

            const usertype = localStorage.getItem('usertype');
            const saveduserid = localStorage.getItem('saveduserid');
            const external_customer_id = localStorage.getItem('external_customer_id');
            const app_type = "macc";
            // Create a query string from the constants
            const queryParams = new URLSearchParams({
                token: token || '',                      // Append token
                usertype: usertype || '',                // Append usertype
                is_verified: is_verified || '',          // Append is_verified
                saveduserid: saveduserid || '',          // Append saveduserid
                external_customer_id: external_customer_id || '', // Append external_customer_id
                app_type: app_type || ''                           // // Append app_type
            });
            //clear local storage
            localStorage.removeItem('usertype');
            localStorage.removeItem('is_verified');
            localStorage.removeItem('saveduserid');
            localStorage.removeItem('external_customer_id');
            localStorage.removeItem('macc');
            // Redirect to the new URL with query parameters
            window.location.replace(`${window.location.protocol}//${process.env.REACT_APP_URL_NAME}/redirect?${queryParams.toString()}`);
        }
    }

    const queryParam = new URLSearchParams(window.location.search);
    const [cookies, setCookie, removeCookie] = useCookies(['sharedProfileLink'])
    const [hasError, setHasError] = useState(false)

    if (haveValue(queryParam.get('sharedProfileLink')) && !haveValue(cookies.sharedProfileLink)) {
        setCookie('sharedProfileLink', queryParam.get('sharedProfileLink'), { maxAge: 1800 })
    }
    if (haveValue(queryParam.get('requestProfileLink')) && !haveValue(cookies.requestProfileLink)) {
        setCookie('requestProfileLink', queryParam.get('requestProfileLink'), { maxAge: 604800 })
    }
    if (haveValue(queryParam.get('gurantorFor')) && !haveValue(cookies.gurantorFor)) {
        setCookie('gurantorFor', queryParam.get('gurantorFor'), { maxAge: 604800 })
    }
    if (haveValue(queryParam.get('navigateTo'))) {
        setCookie('navigateTo', queryParam.get('navigateTo'), { maxAge: 1800 })
    }

    if (haveValue(assignedPropertyId) && window.location.pathname.includes('accept/assigned/property') && haveValue(queryParam.get('email'))) {
        setCookie('assignedPropertyId', assignedPropertyId, { maxAge: 1800 })
    }

    if (haveValue(cookies.navigateTo)) {

        if (haveValue(user?._id)) {
            const navigateTo = cookies.navigateTo;
            console.log(navigateTo, "INSIDE PROTECTED ROUTE FUNCTION")
            removeCookie('navigateTo', { maxAge: 1800 })
            navigate(`/${navigateTo}`)
        }
    }
    if (haveValue(cookies.assignedPropertyId)) {

        const id = cookies.assignedPropertyId
        removeCookie('assignedPropertyId', { maxAge: 1800 })
        navigate(`/accept/assigned/property/${id}`)
    }

    useEffect(() => {

        if (user && haveValue(user?.has_profile) && user?.has_profile != true) {

            if (user?.is_university) {
                navigate("/university/stepform")
            } else {
                navigate("/stepform")
            }
        }

    }, [user])


    let token = localStorage.getItem('token')
    let is_verified = localStorage.getItem('is_verified')
    let type = localStorage.getItem('usertype')

    if (!(token && is_verified && is_verified == "true")) {
        console.log("not logged in ")
        const appType = localStorage.getItem('app_type');
        if (haveValue(appType)) {
            localStorage.removeItem('app_type');
            if (appType === "macc") {
                window.location.replace(`${window.location.protocol}//${process.env.REACT_APP_MACC_URL_NAME}`);
            }
        }
        return <Navigate to="/userrole" replace />;
    }

    return component;

};

export default ProtectedRoute;