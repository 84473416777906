import { useContext, useEffect, useState } from "react";
import useTranslator from "../../../hooks/useTranslator";
import { Container, Form, Modal } from "react-bootstrap";
import AuthContext from "../../../context/auth/AuthContext";
import TenantContext from "../../../context/tenant/TenantContext";
import { useNavigate } from "react-router-dom";
import { BsBank2 } from "react-icons/bs";
import { haveValue, getBaseUrl } from "../../../helper/common";
import axios from "axios";
import { toast } from "react-toastify";
import { sendUniversalMail } from "../../../services/sharedService";
import { useSnackbar } from "material-ui-snackbar-provider";
import AlreadyGuarantorOf from "../../../components/AlreadyGuarantorOf";
import SomeoneGuarantor from "../../../components/SomeoneGuarantor";

const TenantBankConnect = () => {
  const { _t } = useTranslator();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const authContext = useContext(AuthContext);
  const tenantContext = useContext(TenantContext);
  const { user, loadUser, userType, loadUserTenant } = authContext;
  const { updateKyc } = tenantContext;
  const queryParameters = new URLSearchParams(window.location.search);
  const error1 = queryParameters.get("error");
  const account_verification_report_id = queryParameters.get(
    "account_verification_report_id"
  );
  const transaction_report_id = queryParameters.get("transaction_report_id");

  useEffect(() => {
    if (error1) {
      toast.error(_t("Bank Details cannot be fetched!"));
    }
    if (account_verification_report_id && transaction_report_id) {
      let userid = localStorage.getItem("saveduserid");
      if (!userid) {
        toast.error(_t("User Not Authorized"));
      } else {
        calculateScore(
          userid,
          account_verification_report_id,
          transaction_report_id
        );
      }
    }
  }, [account_verification_report_id]);

  const [openModal, setOpenModal] = useState(false);
  const [bankShow, setBankShow] = useState(false);
  const [defaultModal, setDefaultModal] = useState(true);
  const [tink_countryCode, setTink_countryCode] = useState("");
  const [countryCodeError, setCountryCodeError] = useState("");
  const [showGurantor, setShowGurantor] = useState(false);
  const [gurantorPresent, setGurantorPresent] = useState(false);
  const [alreadyGurantorOf, setAlreadyGurantorOf] = useState(false);
  const [exitReason, setExitReason] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [showButton, setShowButton] = useState(0);
  const [guarantorData, setGuarantorData] = useState({
    gurantor_name: "",
    gurantor_email: "",
    gurantor_relation: "",
  });

  useEffect(() => {
    if (user?.kyc_success && !haveValue(user?.paymentScores)) {
      getAnalysisDetails();
    }
  }, []);

  const calculateScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    await clearPaymentData(userid);
    loadUserTenant(localStorage.getItem("saveduserid"));
    calculatePaymentScore(userid, accountverificationid, transactionreportid);
    setCurrentStep(1);
  };

  const calculatePaymentScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/calculatescore?tenantid=${userid}&accountverificationid=${accountverificationid}&transactionreportid=${transactionreportid}`
      )
      .then((res) => {
        let serverResponse = res.data;
        if (serverResponse.status !== "success") {
          if (serverResponse.status == "pending") {
            getAnalysisDetails();
          } else {
            toast.error(_t("Skor can not be calculated"));
          }
        }
        if (serverResponse.status === "success") {
          loadUser(userType);
        }
      })
      .catch((err) => {
        let err_res = err;
        //console.log(err_res);
      });
  };

  const clearPaymentData = async (tenantid) => {
    return new Promise((resolve) => {
      axios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/tenants/algoan-info?tenantid=${tenantid}`
        )
        .then((res) => {
          return resolve(true);
        })
        .catch((err) => {
          let err_res = err;
          toast.error(err_res.message);
          return resolve(null);
        });
    });
  };

  const openBackModal = () => {
    setOpenModal(true);
  };

  const handleGobackReason = () => {
    const data = {
      user_id: user?._id,
      exit_reason: exitReason,
    };
    updateKyc(data)
      .then((res) => {
        if (user?.docs_uploaded) {
          navigate("/tenant");
        } else {
          navigate("/tenant/document");
        }
        console.log("Status updated");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openBankModal = () => {
    setBankShow(true);
  };

  const BankhandleClose = () => {
    setBankShow(false);
  };

  const changeCountryCode = (e) => {
    setTink_countryCode(e.target.value);
  };

  const connectToBank = () => {
    if (!tink_countryCode) {
      setCountryCodeError("*Please select country code");
      return;
    } else {
      setCountryCodeError("");
    }
    var lang = "en_US";
    if (localStorage.getItem("i18nextLng") == "es") {
      lang = "es_ES";
    }
    const baseUrl = getBaseUrl();
    let tinkUrlLiveFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_URL_NAME}%2Ftenant%2FconnectBank&market=${tink_countryCode}&locale=${lang}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;
    window.open(tinkUrlLiveFront, "_self");
  };

  const handleShowGurantor = () => {
    setShowGurantor(true);
  };

  const handleHideGurantor = () => {
    setShowGurantor(false);
    setGuarantorData({
      gurantor_name: "",
      gurantor_email: "",
      gurantor_relation: "",
    });
  };

  const handleSubmitGurantor = async (e) => {
    e.preventDefault();
    let userid = localStorage.getItem("saveduserid");
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/tenants/gurantor/${userid}`,
        guarantorData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        if (res.data?.alreadyGurantor) {
          setGurantorPresent(true);
          setAlreadyGurantorOf(true);
          handleHideGurantor();
        } else {
          toast.success(_t("Guarantor Added!"));
          loadUser(userType);
          sendMail(guarantorData?.gurantor_email, "score");
          handleHideGurantor();
          setCurrentStep(2);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.error);
      });
  };

  const sendMail = (receiverEmail, emailType) => {
    let payload = {
      receiverEmail: receiverEmail,
      senderId: user?._id,
      emailType: emailType,
    };
    sendUniversalMail(payload)
      .then((res) => {
        snackbar.showMessage(_t("Email Sent"));
      })
      .catch((err) => {
        snackbar.showMessage(_t("Error Sending Mail"), err?.message);
        console.log(err);
      });
  };

  const navigateBackHome = () => {
    navigate("/tenant");
  };

  const checkNextButton = () => {
    if (!haveValue(user?.onfido_kyc_success) || !user?.onfido_kyc_success) {
      return (
        <button
          className="btn btnorange step-btn w-100"
          onClick={(e) => navigate("/tenant/verifyId")}
        >
          {_t("Continue to Verify Id")}
        </button>
      );
    } else if (!haveValue(user?.docs_uploaded) || !user?.docs_uploaded) {
      return (
        <button
          className="btn btnorange step-btn w-100"
          onClick={(e) => navigate("/tenant/document")}
        >
          {_t("Continue to Provide Document")}
        </button>
      );
    } else {
      return <></>;
    }
  };

  const getAnalysisDetails = async (req, res) => {
    let userid = localStorage.getItem("saveduserid");

    let myInterval = setInterval(async () => {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/tenants/checkmyanalysis/${userid}`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          clearInterval(myInterval);
          loadUserTenant(userType);
        })
        .catch((err) => {
          clearInterval(myInterval);
          return;
        });
    }, 2000);
  };

  return (
    <>
      {currentStep == 0 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="d-flex step-absolute-header step-form-header">
              <Container className="p-0 text-center back-btn">
                <div className="">
                  <a href={undefined} onClick={openBackModal}>
                    <img src="/images/back-btn-white.svg" alt="" />
                    {/* <span>{_t("Go Back To Home")} </span> */}
                  </a>
                </div>
              </Container>
              {/* <h3>{_t("Financial Check")} </h3> */}

              <h3 class="step-form-name">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                >
                  <circle cx="27" cy="27" r="27" fill="#253837" />
                  <path
                    d="M35 19H19C17.897 19 17 19.897 17 21V33C17 34.103 17.897 35 19 35H35C36.103 35 37 34.103 37 33V21C37 19.897 36.103 19 35 19ZM23.715 23C24.866 23 25.715 23.849 25.715 25C25.715 26.151 24.866 27 23.715 27C22.564 27 21.715 26.151 21.715 25C21.715 23.849 22.563 23 23.715 23ZM27.43 31H20V30.535C20 29.162 21.676 27.75 23.715 27.75C25.754 27.75 27.43 29.162 27.43 30.535V31ZM34 30H30V28H34V30ZM34 26H29V24H34V26Z"
                    fill="#A3EB64"
                  />
                </svg>
                <span>{_t("Financial Check")}</span>
              </h3>
            </div>
            <h3 className="step-name">
              {_t("Verify your income to access your Skor")}
            </h3>
            <div className="resend-text steps-wrapper">
              <div className="">
                <div className="icon-text-box">
                  <img src="/images/home-vector.svg" alt="" />
                  <div className="text-box">
                    <h4 className="box-heading">
                      {_t("Option 1: Verify with Bank")}
                    </h4>
                    <p className="box-desc">
                      {_t(
                        "Verify your income instantly with the bank you receive your salary on."
                      )}
                    </p>
                  </div>
                </div>
                <div className="icon-text-box">
                  <img src="/images/profile2.svg" alt="" />
                  <div className="text-box">
                    <h4 className="box-heading">
                      {_t("Option 2: add a guarantor")}
                    </h4>
                    <p className="box-desc">
                      {_t(
                        "If you don't have an account or a salary you can always add someone as your rent guarantor."
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <button
                className="btn btnorange step-btn"
                onClick={openBankModal}
              >
                {_t("Verify with bank")}
              </button>
              {user?.have_gurantor == true || user?.have_gurantor == "true" ? (
                <button
                  className="btn btnoutline bg-none"
                  onClick={(e) => {
                    sendMail(user?.gurantor_email, "score");
                    setCurrentStep(2);
                  }}
                >
                  {_t("Resend Invitation to Guarantor")}
                </button>
              ) : (
                <button
                  className="btn btnoutline bg-none"
                  onClick={handleShowGurantor}
                >
                  {_t("Add Guarantor")}
                </button>
              )}
            </div>
            <Modal
              className="modal-uploadpdf radio-wrapper"
              show={openModal}
              onHide={(e) => setOpenModal(false)}
            >
              <Modal.Header closeButton>
                <h5 className="modal-main-heading">
                  {_t("Why Do You Want to Leave")}?
                </h5>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-sub-heading">{_t("Please Choose One")}</p>
                <label class="customradiobar">
                  <input
                    type="radio"
                    name="go_back_reason"
                    onClick={navigateBackHome}
                  />
                  <div class="boxes">{_t("I will do this later")}</div>
                </label>
                <label class="customradiobar">
                  <input
                    type="radio"
                    name="go_back_reason"
                    onClick={(e) => setExitReason("My bank is not available")}
                  />
                  <div class="boxes">{_t("My bank is not available")}</div>
                </label>
                <label class="customradiobar">
                  <input
                    type="radio"
                    name="go_back_reason"
                    onClick={(e) =>
                      setExitReason(
                        "I don't feel confortable verifying my account"
                      )
                    }
                  />
                  <div class="boxes">
                    {_t("I don't feel confortable verifying my account")}
                  </div>
                </label>

                {haveValue(exitReason) && (
                  <button
                    className="btn btnorange w-100"
                    onClick={handleGobackReason}
                  >
                    {user?.docs_uploaded
                      ? _t("Go Back home")
                      : _t("Continue to Provide your documents")}
                  </button>
                )}
              </Modal.Body>
            </Modal>
            <Modal
              className="modal-uploadpdf"
              show={defaultModal}
              onHide={(e) => setDefaultModal(false)}
            >
              <Modal.Header closeButton>
                <h5 className="modal-main-heading">
                  {_t("How does it work")}?
                </h5>
              </Modal.Header>
              <Modal.Body>
                <p className="modal-sub-heading">
                  {_t("Verify your financials in 3 simple steps")}:
                </p>
                <div className="icon-text-box">
                  <img src="/images/home-icon2.svg" alt="" />
                  <div className="text-box">
                    <h4 className="box-heading">
                      {_t("Connect your bank account")}
                    </h4>
                    <p className="box-desc">
                      {_t(
                        "Use your credentials to access your account in a secure manner"
                      )}
                    </p>
                  </div>
                </div>

                <div className="icon-text-box">
                  <img src="/images/picture-icon2.svg" alt="" />
                  <div className="text-box">
                    <h4 className="box-heading">
                      {_t("Access your movements")}
                    </h4>
                    <p className="box-desc">
                      {_t("To show your financial solvency")}
                    </p>
                  </div>
                </div>
                <div className="icon-text-box">
                  <img src="/images/meter-icon.svg" alt="" />
                  <div className="text-box">
                    <h4 className="box-heading">
                      {_t("Generate your financial Skor")}
                    </h4>
                    <p className="box-desc">
                      {_t(
                        "So you can get benefit from a good financial behaviour"
                      )}
                    </p>
                  </div>
                </div>
                <button
                  className="btn btnorange step-btn w-100"
                  onClick={(e) => setDefaultModal(false)}
                >
                  {_t("Continue")}
                </button>
              </Modal.Body>
            </Modal>
            <Modal
              show={bankShow}
              onHide={BankhandleClose}
              centered
              className="newmodalui connectbankmodal"
              size="lg"
            >
              <Modal.Body className="p-0">
                <div className="row">
                  <div className="col-sm-6 p-2 bankimagediv">
                    <p>
                      {_t(
                        "You will now be redirected to connect your bank account through our partner - Tink, powered by Visa."
                      )}
                    </p>
                    <p>
                      {_t(
                        "It's important for you to know that we do not have direct access to your bank login details. This process is designed to mantain your credentials encripted from Skor or any other third party"
                      )}
                    </p>
                    <img src="../images/connect_bank.svg" alt="bank_logo" />
                    <p>
                      {_t(
                        "We assure you of the utmost privacy and security of your financial data."
                      )}
                    </p>
                  </div>
                  <div className="col-sm-6 p-2 connectbankdiv">
                    <h3>{_t("Connect Bank")}</h3>
                    <Form className="loginform">
                      <div className="auth-form">
                        <div className="forminput row100">
                          <label className="forminput">{_t("Country")}</label>
                          <select
                            className="form-control"
                            onChange={changeCountryCode}
                          >
                            <option value="">
                              {_t("Select your country")}
                            </option>
                            <option value="BE">{_t("Belgium")}</option>
                            <option value="DE">{_t("Germany")}</option>
                            <option value="DK">{_t("Denmark")}</option>
                            <option value="EE">{_t("Estonia")}</option>
                            <option value="ES">{_t("Spain")}</option>
                            <option value="FI">{_t("Finland")}</option>
                            <option value="FR">{_t("France")}</option>
                            <option value="GB">{_t("United Kingdom")}</option>
                            <option value="IT">{_t("Italy")}</option>
                            <option value="LT">{_t("Lithuania")}</option>
                            <option value="LV">{_t("Latvia")}</option>
                            <option value="NL">{_t("Netherlands")}</option>
                            <option value="NO">{_t("Norway")}</option>
                            <option value="PL">{_t("Poland")}</option>
                            <option value="PT">{_t("Portugal")}</option>
                            <option value="SE">{_t("Sweden")}</option>
                          </select>
                        </div>
                        <div className="forminput row100">
                          <label className="forminput">
                            {_t("Connect Bank")}
                          </label>

                          <div className="bankname" onClick={connectToBank}>
                            <BsBank2 />
                            {_t("Connect bank")}
                          </div>
                          <span className="addRedColor">
                            {countryCodeError}
                          </span>
                        </div>
                      </div>
                    </Form>
                    <div className="bank-btm">
                      {_t("Should you have any concerns or questions please ")}
                      <a
                        href={undefined}
                        onClick={(e) => navigate("/bank")}
                        className="btnlink"
                      >
                        {_t("read here")}
                      </a>
                      {_t(" or contact us at ")}
                      <a href="mailTo:info@skortorent.com" className="btnlink">
                        info@skortorent.com.
                      </a>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={showGurantor}
              onHide={handleHideGurantor}
              centered
              className="newmodalui"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h5 className="modal-main-heading mb-3">
                    {_t("Add Guarantor")}
                  </h5>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSubmitGurantor}>
                  <div className="auth-form">
                    <div className="forminput row100">
                      <label className="forminput label-text">
                        {_t("Name Of Guarantor")}
                      </label>
                      <input
                        type="text"
                        name="gurantor_name"
                        placeholder="Name Of Guarantor"
                        onChange={(e) =>
                          setGuarantorData((prevData) => ({
                            ...prevData,
                            gurantor_name: e.target.value,
                          }))
                        }
                        value={guarantorData.gurantor_name}
                      />
                    </div>
                    <div className="forminput row100">
                      <label className="forminput label-text">
                        {_t("Email Of Guarantor")}
                      </label>
                      <input
                        type="text"
                        name="gurantor_email"
                        placeholder="Email Of Guarantor"
                        onChange={(e) =>
                          setGuarantorData((prevData) => ({
                            ...prevData,
                            gurantor_email: e.target.value?.toLowerCase(),
                          }))
                        }
                        value={guarantorData.gurantor_email}
                      />
                    </div>
                    <div className="forminput row100">
                      <label className="forminput label-text">
                        {_t("Relationship With Guarantor")}
                      </label>
                      <select
                        name="gurantor_relation"
                        id=""
                        onChange={(e) =>
                          setGuarantorData((prevData) => ({
                            ...prevData,
                            gurantor_relation: e.target.value,
                          }))
                        }
                      >
                        <option value="" selected>
                          {_t("Please Select")}
                        </option>
                        <option value="Father">{_t("Father")}</option>
                        <option value="Mother">{_t("Mother")}</option>
                        <option value="Friend">{_t("Friend")}</option>
                        <option value="Relative">{_t("Relative")}</option>
                        <option value="Other">{_t("Other")}</option>
                      </select>
                    </div>
                    <div className="btngroup row100 gridbar">
                      <button
                        className="btn btnoutline"
                        type="button"
                        onClick={handleHideGurantor}
                      >
                        {_t("Cancel")}
                      </button>
                      <button
                        className="btn btnorange"
                        type="submit"
                        disabled={
                          haveValue(guarantorData.gurantor_name) &&
                            haveValue(guarantorData.gurantor_email) &&
                            haveValue(guarantorData.gurantor_relation)
                            ? false
                            : true
                        }
                      >
                        {_t("Submit")}
                      </button>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
            {alreadyGurantorOf && gurantorPresent == false && (
              <AlreadyGuarantorOf
                alreadyGurantorOf={alreadyGurantorOf}
                setAlreadyGurantorOf={setAlreadyGurantorOf}
                gurantorPresent={gurantorPresent}
                setGurantorPresent={setGurantorPresent}
              />
            )}
            {alreadyGurantorOf && gurantorPresent == true && (
              <SomeoneGuarantor
                alreadyGurantorOf={alreadyGurantorOf}
                setAlreadyGurantorOf={setAlreadyGurantorOf}
                gurantorPresent={gurantorPresent}
                setGurantorPresent={setGurantorPresent}
              />
            )}
          </div>
        </>
      )}
      {currentStep == 1 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="congratulation-screen steps-wrapper">
              <div className="step-form-header">
                <h3 class="step-form-name">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                  >
                    <circle cx="27" cy="27" r="27" fill="#253837" />
                    <path
                      d="M35 19H19C17.897 19 17 19.897 17 21V33C17 34.103 17.897 35 19 35H35C36.103 35 37 34.103 37 33V21C37 19.897 36.103 19 35 19ZM23.715 23C24.866 23 25.715 23.849 25.715 25C25.715 26.151 24.866 27 23.715 27C22.564 27 21.715 26.151 21.715 25C21.715 23.849 22.563 23 23.715 23ZM27.43 31H20V30.535C20 29.162 21.676 27.75 23.715 27.75C25.754 27.75 27.43 29.162 27.43 30.535V31ZM34 30H30V28H34V30ZM34 26H29V24H34V26Z"
                      fill="#A3EB64"
                    />
                  </svg>
                  <span>{_t("Financial Check")}</span>
                </h3>
                <img
                  className="congrats-image"
                  src="/images/confetti.svg"
                  alt=""
                />
                <h3 className="congrats-text">{_t("Congratulations")}</h3>
                <p className="congrats-desc">
                  {_t(
                    "Your bank verification is in progress. Once verified you will be rewarded with 50 points"
                  )}
                </p>
                {checkNextButton()}
                <button
                  className="w-100 btn btnoutline bg-none"
                  onClick={navigateBackHome}
                >
                  {_t("Go Back home")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {currentStep == 2 && (
        <>
          <div className="step-form-main-wrapper">
            <div className="congratulation-screen steps-wrapper">
              <div className="step-form-header">
                <h3 class="step-form-name">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                  >
                    <circle cx="27" cy="27" r="27" fill="#253837" />
                    <path
                      d="M35 19H19C17.897 19 17 19.897 17 21V33C17 34.103 17.897 35 19 35H35C36.103 35 37 34.103 37 33V21C37 19.897 36.103 19 35 19ZM23.715 23C24.866 23 25.715 23.849 25.715 25C25.715 26.151 24.866 27 23.715 27C22.564 27 21.715 26.151 21.715 25C21.715 23.849 22.563 23 23.715 23ZM27.43 31H20V30.535C20 29.162 21.676 27.75 23.715 27.75C25.754 27.75 27.43 29.162 27.43 30.535V31ZM34 30H30V28H34V30ZM34 26H29V24H34V26Z"
                      fill="#A3EB64"
                    />
                  </svg>
                  <span>{_t("Financial Check")}</span>
                </h3>
                <img
                  className="congrats-image"
                  src="/images/confetti.svg"
                  alt=""
                />
                <h3 className="congrats-text">{_t("Congratulations")}</h3>
                <p className="congrats-desc">
                  {_t(
                    "Your request to your guarantor has been sent. Once accepted you will be rewarded with 50 points"
                  )}
                </p>
                {checkNextButton()}
                <button
                  className="w-100 btn btnoutline bg-none"
                  onClick={navigateBackHome}
                >
                  {_t("Go Back home")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TenantBankConnect;
