import { useContext, useEffect, useState } from "react";
import useTranslator from "../../hooks/useTranslator";
import AuthContext from "../../context/auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { haveValue } from "../../helper/common";

const TenantProfileChecklist = () => {
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { _t } = useTranslator();
  const navigate = useNavigate();

  const [profileCompletion, setProfileCompletion] = useState(0);

  useEffect(() => {
    var temp = 0;
    if (user?.profile_completed) {
      temp += 25;
    }
    if (user?.docs_uploaded) {
      temp += 25;
    }
    if (user?.onfido_kyc_success) {
      temp += 25;
    }
    if (user?.gurantor_id?.paymentScores || user?.paymentScores) {
      temp += 25;
    }

    setProfileCompletion(temp);
  }, [user]);

  const navigateStepform = () => {
    navigate("/tenant/stepform");
  };

  const navigateVerifyId = () => {
    navigate("/tenant/verifyId");
  };

  const navigateBankConnect = () => {
    navigate("/tenant/connectBank");
  };

  const navigateDocument = () => {
    navigate("/tenant/document");
  };

  return (
    <>
      <div id="onfido-mount"></div>
      {profileCompletion == 100 ? (
        <></>
      ) : (
        <div className="pending-steps-wrapper">
          <div className="d-flex align-items-center justify-content-between tile-design profile-status-wrapper">
            <div>
              <h3 className="tiles-main-heading">
                {" "}
                {_t("Complete And Get Skored")}{" "}
              </h3>
              <p className="tile-desc">
                {_t(
                  "Your data is still not enough for a profile. Please complete the following steps to complete your profile and get scored"
                )}
              </p>
            </div>
            <div className="position-relative steps-progress-bar">
              <div class="circle">
                <div
                  class="mask full"
                  style={{ transform: `rotate(${profileCompletion * 1.8}deg)` }}
                >
                  <div
                    class="fill"
                    style={{
                      transform: `rotate(${profileCompletion * 1.8}deg)`,
                    }}
                  ></div>
                </div>
                <div class="mask half">
                  <div
                    class="fill"
                    style={{
                      transform: `rotate(${profileCompletion * 1.8}deg)`,
                    }}
                  ></div>
                </div>
                <div class="inside-circle"> {profileCompletion}% </div>
              </div>
            </div>
          </div>

          <ul>
            <li className="d-flex tile-design">
              <div className="tile-content-wrapper">
                <img src="/images/profile-icon.svg" alt="" />
                <div className="tile-content">
                  <h4 className="tile-heading">{_t("Complete Profile")}</h4>
                  {user?.profile_completed ? (
                    <p className="tile-desc-completed">
                      {_t(
                        "Well done! Now, landlords will start seeing your profile. Verify your identity to further increase your chances!"
                      )}
                    </p>
                  ) : (
                    <p className="tile-desc">
                      {_t(
                        "Welcome to your profile. Complete your basic information to start sharing your profile with landlords!"
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 tile-btns-wrapper">
                <div className="tile-points">
                  <img src="/images/wallet-icon.svg" alt="wallet-icon" />
                  <span>20 {_t("Points")}</span>
                </div>

                {user?.profile_completed ? (
                  <button className="completed-btn">{_t("Completed")}</button>
                ) : (
                  <button className="incomplete-btn" onClick={navigateStepform}>
                    {_t("Complete")}
                  </button>
                )}
              </div>
            </li>
            <li className="d-flex tile-design">
              <div className="tile-content-wrapper">
                <img src="/images/verify-home-icon.svg" alt="" />
                <div className="tile-content">
                  <h4 className="tile-heading">{_t("Verify ID")}</h4>
                  {user?.onfido_kyc_success == true ? (
                    <p className="tile-desc-completed">
                      {_t(
                        "Awesome! Now that you have verified your ID, you've significantly increased your chances of being considered as a candidate."
                      )}
                    </p>
                  ) : (
                    <p className="tile-desc">
                      {_t(
                        "Verifying your identity proves you are a genuine candidate. In fact, 90% of landlords only rent their properties to ID-verified candidates."
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 tile-btns-wrapper">
                <div className="tile-points">
                  <img src="/images/wallet-icon.svg" alt="wallet-icon" />
                  <span>30 {_t("Points")}</span>
                </div>
                {user?.onfido_kyc_success == true ? (
                  <button className="completed-btn">{_t("Completed")}</button>
                ) : user?.onfido_user_kyc?.onfido_doc &&
                  user?.onfido_user_kyc?.onfido_workflow_res?.status !=
                  "declined" ? (
                  <button className="view-progress">{_t("In Progress")}</button>
                ) : (
                  <button
                    onClick={navigateVerifyId}
                    disabled={!user?.profile_completed}
                    className="incomplete-btn"
                  >
                    {_t("Complete")}
                  </button>
                )}
              </div>
            </li>
            <li className="d-flex tile-design">
              <div className="tile-content-wrapper">
                <img src="/images/financial-home-icon.svg" alt="" />
                <div className="tile-content">
                  <h4 className="tile-heading">{_t("Financial Check")}</h4>
                  {((!haveValue(user?.have_gurantor) ||
                    !user?.have_gurantor ||
                    user?.have_gurantor == "false") &&
                    (!haveValue(user?.kyc_success) ||
                      !user?.kyc_success ||
                      user?.kyc_success == "false")) ||
                    (!haveValue(user?.gurantor_id?.paymentScores) &&
                      !haveValue(user?.paymentScores)) ? (
                    <p className="tile-desc">
                      {_t(
                        "Verifying your financial information boosts your credibility. Most of the landlords select profiles with bank verification."
                      )}
                    </p>
                  ) : (
                    <p className="tile-desc-completed">
                      {_t(
                        "Almost there! You are now one of the top profiles landlords will review."
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 tile-btns-wrapper">
                <div className="tile-points">
                  <img src="/images/wallet-icon.svg" alt="wallet-icon" />
                  <span>50 {_t("Points")}</span>
                </div>
                {(!haveValue(user?.have_gurantor) ||
                  !user?.have_gurantor ||
                  user?.have_gurantor == "false") &&
                  (!haveValue(user?.kyc_success) ||
                    !user?.kyc_success ||
                    user?.kyc_success == "false") ? (
                  <button
                    onClick={navigateBankConnect}
                    className="incomplete-btn"
                    disabled={!user?.profile_completed}
                  >
                    {_t("Complete")}
                  </button>
                ) : !haveValue(user?.gurantor_id?.paymentScores) &&
                  !haveValue(user?.paymentScores) ? (
                  <button
                    className="view-progress"
                    onClick={navigateBankConnect}
                  >
                    {_t("View Progress")}
                  </button>
                ) : (
                  <button className="completed-btn">{_t("Completed")}</button>
                )}
              </div>
            </li>
            <li className="d-flex tile-design mb-0 border-0">
              <div className="tile-content-wrapper">
                <img src="/images/document-home-icon.svg" alt="" />
                <div className="tile-content">
                  <h4 className="tile-heading">{_t("Provide Documents")}</h4>
                  {user?.docs_uploaded ? (
                    <p className="tile-desc-completed">
                      {_t(
                        "Great job! Your documents are now checked. Now, you can start sharing your profile with landlords."
                      )}
                    </p>
                  ) : (
                    <p className="tile-desc">
                      {_t(
                        "Documentation is crucial to complete your profile. Submit it and start sharing your profile!"
                      )}
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 tile-btns-wrapper">
                <div className="tile-points">
                  <img src="/images/wallet-icon.svg" alt="wallet-icon" />
                  <span>50 {_t("Points")}</span>
                </div>
                {user?.docs_uploaded ? (
                  <button className="completed-btn">{_t("Completed")}</button>
                ) : (
                  <button
                    onClick={navigateDocument}
                    disabled={!user?.profile_completed}
                    className="incomplete-btn"
                  >
                    {_t("Complete")}
                  </button>
                )}
              </div>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default TenantProfileChecklist;
