import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import TenantContext from "../../../context/tenant/TenantContext";
import AuthContext from "../../../context/auth/AuthContext";
import LandLordContext from "../../../context/landlord/LandLordContext";
import axios from "axios";
import Spinner from "../../../components/Spinner";
import { FaRegCopy } from "react-icons/fa6";
import searchicon from "../../../assets/img/s.png";
import locationicon from "../../../assets/img/location.png";
import nexticon from "../../../assets/img/next.png";
import { IoMdDownload } from "react-icons/io";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { calculateNowTime } from "../../../services/sharedService";
import useTranslator from "../../../hooks/useTranslator";
import QRCode from "react-qr-code";
import { Container, Modal } from "react-bootstrap";
import copy from "copy-to-clipboard";
import { haveValue, getBaseUrl } from "../../../helper/common";
import { useCookies } from "react-cookie";

function downloadBlob(blob, filename) {
  const objectUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = objectUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

const ProfileShared = () => {
  const { _t } = useTranslator();
  const location = useLocation();
  const queryParam = new URLSearchParams(window.location.search);
  const [cookies, setCookie, removeCookie] = useCookies(["sharedProfileLink"]);
  const docuSignEvent = queryParam.get("event");
  const shareWithEvent = queryParam.get("shareWith");
  const agreementShow = queryParam.get("agreement");
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { user, loadMyNotifications, userType, updateUserInfo, setLoading } =
    authContext;
  const tenantContext = useContext(TenantContext);
  const {
    shareProfile,
    loadSharedProfiles,
    fullProfileShared,
    basicProfileShared,
    updateDownloadedCheck,
    removeSharedProfile,
    getDocuSignURL,
  } = tenantContext;
  const landLordContext = useContext(LandLordContext);
  const { loadingLandlord } = landLordContext;
  //toggling between shortlisted and share-profile
  const [profileDiv, setProfileDiv] = useState("share-profile");
  const [landlords, setAllLandlords] = useState([]);
  const [landlordsOrig, setAllLandlordsOrig] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [showSharedModal, setShowSharedModal] = useState(false);
  const [tempLandlord, setTempLandlord] = useState("");
  const [filteredShortlisted, setFilteredShortlisted] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [tab, setTab] = useState("share");
  const [showRemove, setShowRemove] = useState(false);
  const [removeLandlordId, setRemoveLandlordId] = useState(null);
  const [totalLandlordPage, setTotalLandlordPage] = useState(0);
  const [currentLandlordPage, setCurrentLandlordPage] = useState(1);
  const [signModal, setSignModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (haveValue(docuSignEvent)) {
      if (docuSignEvent === "signing_complete") {
        setLoading(true);
        updateUserInfo({ docSigned: true })
          .then((res) => {
            navigate("/profile-shared");
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else if (docuSignEvent === "share_start") {
        //skip nothing
      } else {
        toast.error(_t("Unable to fetch signed document!"));
      }
    }
    if (haveValue(agreementShow)) {
      if (haveValue(cookies.requestProfileLink)) {
        const temp = cookies.requestProfileLink;
        removeCookie("requestProfileLink", { maxAge: 1800 });
        setCookie("agreementVal", temp, { maxAge: 604800 });
        setSignModal(true);
      }
    }
  }, []);

  useEffect(() => {
    if (haveValue(cookies.agreementVal) && user?.docSigned) {
      const userId = localStorage.getItem("saveduserid");
      shareProfile(userId, cookies.agreementVal, "null", "full", {
        shareType: "lLink",
      })
        .then((res) => {
          removeCookie("agreementVal", { maxAge: 604800 });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  useEffect(() => {
    //when profile div will change this use effect will run becasue we have have passed profilediv in
    //dependency array
    const userid = localStorage.getItem("saveduserid");
    if (user && user?._id) {
      loadSharedProfiles(user?._id, "full");
      loadSharedProfiles(user?._id, "basic");
    } else if (haveValue(userid)) {
      loadSharedProfiles(userid, "full");
      loadSharedProfiles(userid, "basic");
    }
    // setLocalBasicProfile(user?.basic_profile_shared_with);
  }, [profileDiv]);

  useEffect(() => {
    const effect_func = async () => {
      await loadLandlords();
      let userid = localStorage.getItem("saveduserid");
      loadMyNotifications(userid, userType);

      if (haveValue(queryParam.get("filter"))) {
        const name = queryParam.get("filter");
        const e = { target: { value: name } };
        setSearchText(name);
        filterlandlord(name);
      }
    };
    effect_func();
  }, []);

  useEffect(() => {
    setFilteredShortlisted(fullProfileShared);
    setAllLandlords((prev) => [...prev]);
    setAllLandlordsOrig((prev) => [...prev]);
  }, [fullProfileShared]);

  useEffect(() => {
    // Get the query parameters from the URL
    const queryParams = new URLSearchParams(location.search);
    // Extract the sharedWith from the query parameters
    const sharedWith = queryParams.get('sharedWith');
    if (haveValue(sharedWith)) {

    }

  }, []);

  const loadLandlords = async () => {
    setLoading1(true);
    const tenantid = localStorage.getItem("saveduserid");
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL
        }/landlords/alllandlords?page=${currentLandlordPage}&limit=${2}&searchText=${searchText}&tenantid=${tenantid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        //console.log(res);
        const temp = res.data.landlords;
        setTotalLandlordPage(res?.data?.totalPages);
        setAllLandlords(res.data.landlords);
        setAllLandlordsOrig(res.data.landlords);
        setLoading1(false);

        if (
          haveValue(shareWithEvent) &&
          haveValue(docuSignEvent)
        ) {
          console.log("temp", temp);
          const foundLL = temp.filter((val) => val._id == shareWithEvent);
          // console.log(user, "foundLL", foundLL);
          var userId = localStorage.getItem("saveduserid");
          // setTempLandlord();
          if (docuSignEvent === "signing_complete") {
            shareProfileFun(userId, foundLL[0]?._id, "full", foundLL[0], true);
          } else if (docuSignEvent === "share_start") {
            shareProfileFun(userId, foundLL[0]?._id, "full", foundLL[0]);
          }
        }


      })
      .catch((err) => {
        //alert(err.message)
        setLoading1(false);
        console.log(err);
      });
    return true;
  };

  const isPropertyLinked = (landlordid) => {
    for (const profileShared of fullProfileShared) {
      if (
        profileShared.landlordid === landlordid &&
        haveValue(profileShared?.propertyid)
      ) {
        return profileShared;
      }
    }
    //console.log("profile not shared");
    return null;
  };

  const isProfileShared = (landlordid) => {
    if (fullProfileShared !== null) {
      for (const profileShared of fullProfileShared) {
        if (profileShared.landlordid === landlordid) {
          //console.log("profile shared for",landlordid);
          return true;
        }
      }
      //console.log("profile not shared");
      return false;
    }
  };

  const shareProfileFun = (
    userid,
    landlordid,
    profiletype,
    landlord,
    confirm = false
  ) => {
    console.log(user, confirm)
    if (user?.docSigned || confirm) {
      shareProfile(userid, landlordid, "null", profiletype, {
        shareType: "shareBtn",
      })
        .then((res) => {
          if (res) setShowSharedModal(true);
          setTempLandlord(landlord);
        })
        .catch((err) => {
          setShowSharedModal(false);
        });
    } else {
      setSignModal(true);
      setTempLandlord(landlord);
    }
  };

  const searchShortlisted = (e) => {
    let q = e.target.value;
    let queryText = q.toLowerCase();
    let updatedList = [...fullProfileShared];
    updatedList = updatedList.filter((item) => {
      if (queryText.length === 0) {
        setFilteredShortlisted(fullProfileShared);
      }
      let fname = item?.landlord_fname.toLowerCase();
      return fname.includes(queryText);
    });
    setFilteredShortlisted(updatedList);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const filterlandlord = (searchText) => {
    const temp = [...landlordsOrig];
    const filtered = temp.filter((val) =>
      `${val?.fname_companyName} ${val?.lname}`
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setAllLandlords(filtered);
  };

  useEffect(() => {
    // loadLandlords(searchText);
    filterlandlord(searchText);
  }, [searchText]);

  const handlecopyUrl = () => {
    copy(
      `https://${process.env.REACT_APP_URL_NAME}/profile-request?sharedProfileLink=${user?._id}`
    );
    toast.success(_t("Link Copied!"));
  };

  const svgRef = useRef();

  const handleDownloadQR = useCallback(async () => {
    const svg = svgRef.current.innerHTML;
    const blob = new Blob([svg], { type: "image/svg+xml" });
    downloadBlob(blob, `myimage.svg`);
    await updateDownloadedCheck(user?._id);
  }, []);

  const handleRemoveLandlord = async () => {
    if (fullProfileShared !== null && haveValue(removeLandlordId)) {
      var sharedProfileId = null;
      for await (const profileShared of fullProfileShared) {
        if (profileShared.landlordid === removeLandlordId) {
          sharedProfileId = profileShared?._id;
          break;
        }
      }
      if (haveValue(sharedProfileId)) {
        removeSharedProfile(sharedProfileId)
          .then((res) => {
            toast.success(_t("Profile Unshared!"));
            loadLandlords();

            loadSharedProfiles(user?._id, "full");
            setShowRemove(false);
          })
          .catch((err) => {
            console.log(err);
            setShowRemove(false);
          });
      }
    }
  };

  const handleSignDocument = async () => {
    setLoading(true);
    getDocuSignURL({ tenant_id: user?._id, landlord_id: tempLandlord?._id })
      .then((res) => {
        setSignModal(false);
        if (res) {
          window.location.replace(res?.success_res?.redirectUrl);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <div className="d-flex step-absolute-header step-form-header">
        <div
          className="p-0 text-center back-btn"
          style={{ "text-align-last": "start" }}
        >
          <div className="">
            <a href={undefined} onClick={(e) => navigate("/tenant")}>
              <img src="/images/back-btn-white.svg" alt="" />
              {/* <span>{_t("Go Back To Home")} </span> */}
            </a>
          </div>
        </div>
      </div>
      <div className="tabs-box-pattern">
        <div className="tabs-item d-block">
          <div className="row">
            <div className="profile-page marless">
              <div className="Search-bar">
                {tab == "share" ? (
                  <input
                    type="text"
                    className="custom-input mb-0"
                    onChange={onSearch}
                    value={searchText}
                    placeholder={_t("Search agent/landlord")}
                  />
                ) : (
                  <input
                    type="text"
                    className="custom-input mb-0"
                    onChange={searchShortlisted}
                    placeholder={_t("Search agent/landlord")}
                  />
                )}
                <a href="#">
                  <img src={searchicon} alt="" />
                </a>
              </div>
              <div className="shareQr">
                <a
                  className="btn btngreen"
                  href={undefined}
                  onClick={(e) =>
                    user?.docSigned ? setShowModal(true) : setSignModal(true)
                  }
                >
                  {_t("Share QR Code")}
                </a>
              </div>
            </div>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                {loading1 && <Spinner />}
                <div className="edit-profile">
                  <div className="Shortlisted-Properties">
                    <h3>{_t("Shortlisted Properties")}</h3>
                    <Link to="/tenant-score">{_t("View Skor")}</Link>
                  </div>
                  <div className="Notifications-row">
                    {filteredShortlisted.length == 0 && (
                      <>
                        {" "}
                        <p style={{ textAlign: "center" }}>
                          {" "}
                          {_t("You Haven't ShortListed Any Properties Yet")}
                        </p>
                      </>
                    )}
                    {filteredShortlisted &&
                      filteredShortlisted?.map((shortlistedProperty, index) => (
                        <>
                          <div
                            className="Notifications-wapper opacity-wapper px-0 rr"
                            key={shortlistedProperty?._id}
                          >
                            <div>
                              <h4>{shortlistedProperty?.landlord_fname}</h4>
                              <p>
                                <a href="#">
                                  <img
                                    className="location-img"
                                    src={locationicon}
                                    alt=""
                                  />
                                </a>{" "}
                                {shortlistedProperty?.property?.address}
                              </p>
                            </div>
                            <div>
                              <Link
                                to={`/landlord-detail-tenant?sharedprofileid=${shortlistedProperty?._id}`}
                                className="back-part-wapper"
                              >
                                <img src={nexticon} alt="" />
                              </Link>
                              <br />
                              <br />
                              <h6 className="mb-0">
                                {calculateNowTime(
                                  shortlistedProperty?.createdAt
                                )}
                              </h6>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade show active"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                {loading1 && <Spinner />}
                <div className="edit-profile">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="edit-profile">
                      <div className="Shortlisted-Properties"></div>
                      <div className="Notifications-row">
                        {landlords &&
                          landlords?.map((landlord, index) => {
                            let property_linked = isPropertyLinked(
                              landlord?._id
                            );
                            return (
                              <>
                                {(haveValue(searchText) ||
                                  isProfileShared(landlord?._id)) && (
                                    <div
                                      className="Notifications-wapper opacity-wapper px-0 tt"
                                      key={landlord?._id}
                                    >
                                      <div>
                                        <h4>
                                          {landlord?.fname_companyName}{" "}
                                          {landlord?.lname}
                                        </h4>
                                        {haveValue(property_linked) &&
                                          (property_linked?.propertyid.length >
                                            0 ? (
                                            <p className="d-flex gap-2">
                                              <a href="#">
                                                <img
                                                  className="location-img"
                                                  src={locationicon}
                                                  alt=""
                                                />
                                              </a>
                                              {property_linked?.propertyid
                                                ?.map(
                                                  (props) =>
                                                    `${props?.street} - ${props?.street_number}, ${props.floor_number}-${props.flat_number}`
                                                )
                                                .join(" / ")}
                                            </p>
                                          ) : (
                                            <p className="d-flex gap-2">
                                              <a href="#"></a>
                                              {_t("No Property Linked")}
                                            </p>
                                          ))}
                                      </div>

                                      <div className="share-it">
                                        {
                                          isProfileShared(landlord?._id) ? (
                                            <Link
                                              onClick={(e) => {
                                                setShowRemove(true);
                                                setRemoveLandlordId(
                                                  landlord?._id
                                                );
                                              }}
                                              className="share-btn"
                                            >
                                              {_t("Un-Share")}
                                            </Link>
                                          ) : (
                                            <Link
                                              onClick={() =>
                                                shareProfileFun(
                                                  user._id,
                                                  landlord._id,
                                                  "full",
                                                  landlord
                                                )
                                              }
                                            >
                                              {_t("Share")}
                                            </Link>
                                          ) //<img src={checkicon} />
                                        }
                                      </div>
                                    </div>
                                  )}
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-5">
              <h2 className=" share_landlord_info text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2.78vw"
                  height="2.78vw"
                  viewBox="0 0 38 38"
                  fill="none"
                >
                  <path
                    d="M27.1313 27.1309L36.0012 36.0007"
                    stroke="#E8E8E8"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.0439 30.0877C23.8001 30.0877 30.0877 23.8001 30.0877 16.0439C30.0877 8.28765 23.8001 2 16.0439 2C8.28765 2 2 8.28765 2 16.0439C2 23.8001 8.28765 30.0877 16.0439 30.0877Z"
                    stroke="#E8E8E8"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {_t("Search Landlord Above to Share Your Profile")}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={(e) => setShowModal(false)}
        centered
        className="newmodalui max-w-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{_t("Your Profile QR")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="qr-code-div" ref={svgRef}>
            <QRCode
              id="qr_code_image"
              value={`https://${process.env.REACT_APP_URL_NAME}/profile-request?sharedProfileLink=${user?._id}`}
            />
          </div>
          <div className="qr-link-copi">
            <input
              type="text"
              disabled
              value={`https://${process.env.REACT_APP_URL_NAME}/profile-request?sharedProfileLink=${user?._id}`}
            />
            <button type="button" onClick={handlecopyUrl}>
              <FaRegCopy />
              {_t("Copy URL")}
            </button>
          </div>
          <div className="qr-code-download">
            <a href={undefined} onClick={handleDownloadQR}>
              <IoMdDownload />
              {_t("Download Now")}
            </a>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showRemove}
        onHide={(e) => {
          setShowRemove(false);
          setRemoveLandlordId(null);
        }}
        centered
        className="newmodalui"
      >
        <Modal.Header>
          <Modal.Title className="modal-type">
            {_t("Are you sure you want to stop sharing your Skor profile?")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="btngroup row100 gridbar d-flex justify-content-between">
            <button
              className="btn cancel-btn btnoutline modal-cancel-btn"
              type="button"
              onClick={(e) => {
                setShowRemove(false);
                setRemoveLandlordId(null);
              }}
            >
              {_t("Cancel")}
            </button>
            <button
              className="btn btnorange modal-submit-btn"
              type="button"
              onClick={handleRemoveLandlord}
            >
              {_t("Remove")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showSharedModal}
        onHide={(e) => {
          setShowSharedModal(false);
          setTempLandlord("");
        }}
        centered
        className="newmodalui max-w-lg"
      >
        <Modal.Header>
          {/* <Modal.Title>{_t("Your Profile QR")}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          {_t("Success! Your Skor profile has been successfully shared with")}{" "}
          {tempLandlord?.fname_companyName} {tempLandlord?.lname}.
        </Modal.Body>
      </Modal>

      <Modal
        show={signModal}
        onHide={(e) => setSignModal(false)}
        centered
        className="newmodalui max-w-lg"
      >
        <Modal.Header>
          <Modal.Title>{_t("Your privacy comes first")}!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {_t(
              "The Landlord needs your authorization to verify your information"
            )}
            .
          </p>
          <div className="text-center m-3">
            <button className="btn btngreen" onClick={handleSignDocument}>
              {_t("Continue and authorize")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileShared;
