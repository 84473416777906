import React, { useContext, useEffect, useState } from 'react';
import Digitalprocess from './digitalprocess';
import BankingProcess from './BankingProcess';
import useTranslator from '../../hooks/useTranslator';
import AuthContext from '../../context/auth/AuthContext';
import { haveValue } from '../../helper/common';
import axios from 'axios';
import Loader from '../Loader';
import { useCookies } from 'react-cookie';
import AcceptGuarantor from '../AcceptGuarantor';
import AlreadyGuarantorTo from '../AlreadyGuarantorTo';
import AlreadyGuarantorOf from '../AlreadyGuarantorOf';
import { BsBank2 } from 'react-icons/bs';
import { IoChevronBack } from 'react-icons/io5';

const DigitalVerification = () => {

    const authContext = useContext(AuthContext)
    const { user, loadUser, setLoading } = authContext
    const [kycDone, setKycDone] = useState(false)
    const [kycStatus, setKycStatus] = useState("pending")
    const [algoanStatus, setAlgoanStatus] = useState("nothing")
    const [loader1, setloader1] = useState(false)

    const [showConfirmGuarantor, setShowConfirmGuarantor] = useState(false)
    const [alreadyGurantorTo, setAlreadyGurantorTo] = useState(false)
    const [alreadyGurantorOf, setAlreadyGurantorOf] = useState(false)
    const [gurantorPresent, setGurantorPresent] = useState(false)

    const [cookies, setCookie, removeCookie] = useCookies(['sharedProfileLink'])
    useEffect(() => {
        if (user?.onfido_kyc_success && user?.onfido_kyc_success == true) {
            // setKycStatus('verified')
            setKycStatus('done')
            setKycDone(true)
        }
        else if (user?.onfido_user_kyc?.onfido_workflow_res?.status && user?.onfido_user_kyc?.onfido_workflow_res?.status == 'declined') {
            // setKycStatus('decline')
            setKycStatus('done')
        }
        else if (user?.onfido_user_kyc?.onfido_doc) {
            setKycStatus('done')
        }
    }, [user])

    useEffect(() => {
        const userId = localStorage.getItem('saveduserid')
        const user_type = localStorage.getItem('usertype')
        if (haveValue(cookies.gurantorFor) && user_type == 'tenant') {
            if (haveValue(userId) && !haveValue(user?.gurantor_for) && !haveValue(user?.gurantor_id)) {
                setShowConfirmGuarantor(true);
            }
            if (haveValue(user?.gurantor_for)) {
                setAlreadyGurantorTo(true)
            }
            if (haveValue(user?.gurantor_id)) {
                setAlreadyGurantorOf(true)
            }
        }

    }, [])

    useEffect(() => {
        if (haveValue(user?.algoanCustomerId) && haveValue(user?.algoanAnalysisId) && haveValue(user?.analysisCompleted) && user?.analysisCompleted == false) {
            setAlgoanStatus('pending')
            setLoading(true)
            let userid = localStorage.getItem("saveduserid");
            axios
                .get(
                    `${process.env.REACT_APP_SERVER_URL}/tenants/checkmyanalysis/${userid}`
                )
                .then((res) => {
                    setLoading(false)
                    if (res.status == '200') {
                        setAlgoanStatus("done")
                    }
                    loadUser('tenant')
                })
                .catch((err) => {
                    setLoading(false)
                    console.log(err)
                });
        }



    }, [])

    const { _t } = useTranslator();
    return (
        <>
            {
                loader1 ?
                    <Loader />
                    :
                    <>
                        <div className='DigitalVerification'>
                            {
                                kycStatus == 'done' &&
                                <div className="row">
                                    <div className=""><button type="button" onClick={e => setKycStatus("pending")} className="backbtn1"><IoChevronBack />{_t("Digital Verification")}</button></div>
                                </div>
                            }


                            <div className='titlebar'>
                                <h4>{_t("Finish Details to get your Skor")}</h4>
                            </div>
                            <div className={`didgital_step_form ux-zindabad flex-col`}>{/* verfiedbar class add then it will enabled  */}
                                <ul className='step-num'>
                                    <li className={`active ${kycStatus == 'done' ? '' : 'iconadd'}`}>
                                        {
                                            kycStatus == 'done' ?
                                                null :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                    <path d="M8.5 8.5C10.8377 8.5 12.75 6.58746 12.75 4.25C12.75 1.91254 10.8377 0 8.5 0C6.16228 0 4.25 1.91254 4.25 4.25C4.25 6.58746 6.16228 8.5 8.5 8.5ZM8.5 10.625C5.68446 10.625 0 12.0595 0 14.875L0 15C0 16.1046 0.895431 17 2 17H15C16.1046 17 17 16.1046 17 15V14.875C17 12.0595 11.3155 10.625 8.5 10.625Z" fill="#223635" />
                                                </svg>
                                        }
                                    </li>
                                    <li className={`active ${kycStatus == 'done' ? 'iconadd' : ''}`}>
                                        {
                                            kycStatus == 'done' ?
                                                <BsBank2 />
                                                :
                                                null
                                        }
                                    </li>
                                </ul>
                                <div className='verify-heading'>
                                    {
                                        kycStatus == 'done' ?
                                            < h4 >
                                                {_t("Verify Your Financials To Lower Deposits")}
                                            </h4>
                                            :
                                            <h4>
                                                {_t("Complete Digital Verification")}
                                            </h4>
                                    }
                                </div>
                            </div>
                            <div className='didgitally-bars'>
                                {
                                    console.log(kycStatus)
                                }
                                {/* {
                                    kycStatus == 'done'
                                        ? */}
                                <div className='active' style={{ display: kycStatus == 'done' ? '' : 'none' }}><BankingProcess setloader1={setloader1} algoanStatus={algoanStatus} setAlgoanStatus={setAlgoanStatus} /></div>
                                {/* : */}
                                <div className='active' style={{ display: kycStatus == 'done' ? 'none' : '' }}><Digitalprocess kycStatus={kycStatus} setKycStatus={setKycStatus} onfido_data={user?.onfido_user_kyc} /></div>
                                {/* } */}
                            </div>
                        </div>
                    </>
            }

            {
                showConfirmGuarantor &&
                <AcceptGuarantor setShowConfirmGuarantor={setShowConfirmGuarantor} showConfirmGuarantor={showConfirmGuarantor} />
            }
            {
                alreadyGurantorOf && gurantorPresent == false &&
                <AlreadyGuarantorOf alreadyGurantorOf={alreadyGurantorOf} setAlreadyGurantorOf={setAlreadyGurantorOf} gurantorPresent={gurantorPresent} setGurantorPresent={setGurantorPresent} />
            }
            {
                alreadyGurantorTo &&
                <AlreadyGuarantorTo alreadyGurantorTo={alreadyGurantorTo} setAlreadyGurantorTo={setAlreadyGurantorTo} gurantor_details={user?.gurantor_for} />
            }
        </>
    );
}

export default DigitalVerification;
