import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "material-ui-snackbar-provider";
import {
  Row,
  Col,
  Modal,
  Accordion,
  Tooltip,
  Button,
  Stack,
  Card,
  Spinner,
  Container,
} from "react-bootstrap";
import axios from "axios";
import AuthContext from "../../context/auth/AuthContext";
import TenantContext from "../../context/tenant/TenantContext";
import Form from "react-bootstrap/Form";
//images and logos import
import {
  CircularProgressbar,
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import ProfileScore from "../../components/Tenant/ProfileScore";
import "react-circular-progressbar/dist/styles.css";
import { sendUniversalMail } from "../../services/sharedService";
import tickNew from "../../assets/img/tick-new.svg";
import exclamation from "../../assets/img/exclamation.svg";
import crossNew from "../../assets/img/cross-new.svg";
import useTranslator from "../../hooks/useTranslator";
import { init } from "onfido-sdk-ui";
import countryList from "country-list";
import countries from "i18n-iso-countries";
import { BsBank2 } from "react-icons/bs";
import { convertCurrencyFormat, haveValue, getBaseUrl } from "../../helper/common";
import { toast } from "react-toastify";
import { FaRotate } from "react-icons/fa6";
import { MdInfoOutline, MdOutlineVerified } from "react-icons/md";
import { useCookies } from "react-cookie";
import AcceptGuarantor from "../../components/AcceptGuarantor";
import AlreadyGuarantorOf from "../../components/AlreadyGuarantorOf";
import AlreadyGuarantorTo from "../../components/AlreadyGuarantorTo";
import SomeoneGuarantor from "../../components/SomeoneGuarantor";
import moment from "moment";
import { RiDeleteBin6Line } from "react-icons/ri";
import { VscEye } from "react-icons/vsc";
import { FiUpload } from "react-icons/fi";
import { CiBank } from "react-icons/ci";
import constants from "../../Utils/constants";
import { IoClose } from "react-icons/io5";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image-more";
import { toPng } from "html-to-image";
import html2canvas from "html2canvas";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const TenantHome = () => {
  const { _t } = useTranslator();
  const authContext = useContext(AuthContext);
  const {
    user,
    loadUser,
    setLoading,
    loadUserTenant,
    setBankLoading,
    setAlgoanLoading,
  } = authContext;
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [showConfirmGuarantor, setShowConfirmGuarantor] = useState(false);
  const [removeGuarantorModel, setRemoveGuarantorModel] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [ident_all, setIdent_all] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [alreadyGurantorTo, setAlreadyGurantorTo] = useState(false);
  const [alreadyGurantorOf, setAlreadyGurantorOf] = useState(false);
  const [gurantorPresent, setGurantorPresent] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [show, setShow] = useState(false);
  const [fileType, setFileType] = useState("");
  const [tenantDocsData, setTenantDocsData] = useState(null);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [currentActiveAccordion, setCurrentActiveAccordion] = useState(0);
  const [zeroDeposit, setZeroDeposit] = useState(false);
  const [ieScore, setIeScore] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [cookies, setCookie, removeCookie] = useCookies(["sharedProfileLink"]);
  //extracting url parameters
  const queryParameters = new URLSearchParams(window.location.search);
  const error1 = queryParameters.get("error");
  const docRef = useRef(null);
  const account_verification_report_id = queryParameters.get(
    "account_verification_report_id"
  );
  const transaction_report_id = queryParameters.get("transaction_report_id");

  useEffect(() => {
    if (error1) {
      toast.error(_t("Bank Details cannot be fetched!"));
    }
    if (account_verification_report_id && transaction_report_id) {
      let userid = localStorage.getItem("saveduserid");
      if (!userid) {
        toast.error(_t("User Not Authorized"));
      } else {
        calculateScore(
          userid,
          account_verification_report_id,
          transaction_report_id
        );
      }
    }
  }, [account_verification_report_id]);

  const calculateScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    await clearPaymentData(userid);
    calculatePaymentScore(userid, accountverificationid, transactionreportid);
  };

  const clearPaymentData = async (tenantid) => {
    //console.log("Clearing Payment Data ===========>> ",);
    return new Promise((resolve) => {
      axios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/tenants/algoan-info?tenantid=${tenantid}`
        )
        .then((res) => {
          //console.log(res);
          //console.log("previous data cleared");
          return resolve(true);
        })
        .catch((err) => {
          let err_res = err;
          toast.error(err_res.message);
          return resolve(null);
        });
    });
  };

  const sendMail = (receiverEmail, emailType) => {
    let payload = {
      receiverEmail: receiverEmail,
      senderId: user?._id,
      emailType: emailType,
    };
    sendUniversalMail(payload)
      .then((res) => {
        snackbar.showMessage(_t("Email Sent"));
      })
      .catch((err) => {
        snackbar.showMessage(_t("Error Sending Mail"), err?.message);
        console.log(err);
      });
  };

  const calculatePaymentScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    setBankLoading(true);
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/calculatescore?tenantid=${userid}&accountverificationid=${accountverificationid}&transactionreportid=${transactionreportid}`
      )
      .then((res) => {
        let serverResponse = res.data;
        if (serverResponse.status !== "success") {
          if (serverResponse.status == "pending") {
            setAlgoanLoading(true);
            getAnalysisDetails();
            setBankLoading(false);
          } else {
            toast.error(_t("Skor can not be calculated"));
          }
        }
        if (serverResponse.status === "success") {
          toast.success(_t("Bank Connected Successfully!"));
          loadUser(userType);
        }
        setBankLoading(false);
      })
      .catch((err) => {
        let err_res = err;
        setBankLoading(false);
        //console.log(err_res);
      });
  };

  const countries1 = countryList.getData();
  const getAlpha3Code = (countryName) => {
    const country = countries1.find(
      (c) => c.name?.toLowerCase() === countryName
    );
    return country ? countries.alpha2ToAlpha3(country.code) : "Not found";
  };

  const alpha3ToName = (code) => {
    return countries.getName(code, "en");
  };
  const { userType } = authContext;
  const [showGurantor, setShowGurantor] = useState(false);
  const [guarantorData, setGuarantorData] = useState({
    gurantor_name: "",
    gurantor_email: "",
  });

  const handleShowGurantor = () => setShowGurantor(true);
  const handleHideGurantor = () => {
    setShowGurantor(false);
    setGuarantorData({
      gurantor_name: "",
      gurantor_email: "",
    });
  };

  const tenantContext = useContext(TenantContext);
  const {
    rentedProperties,
    myRentedProperties,
    myPreviouslyRentedProperties,
    onfidoKyc,
    getKycDocs,
    kycuserinfo,
    shareProfile,
    removeGuarantor,
    uploadFileData,
    fetchTenantDocs,
    deleteTenantDoc,
    checkEmailInDeposit,
    joinWaitlist,
  } = tenantContext;

  const pathcolor = "";
  const [progressColor, setProgressColor] = useState("#F27833");

  const [error, setError] = useState(false);
  const [offers, setOffers] = useState([]);
  const [scoreError, setScoreError] = useState({});
  const [scoreLoading, setScoreLoading] = useState(false);
  const [Bankshow, BanksetShow] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [userKycInfo, setUserKycInfo] = useState({});
  const [recordedSalary, setRecordedSalary] = useState({
    salary: 0,
    matched: 0,
  });
  //console.log("RECORDED SALARY = ", recordedSalary);
  const [isEmployeementDataSet, setIsEmployeementDataSet] = useState(false);
  const [haveGuarantor, setHaveGuarantor] = useState(false);

  const shareLink = (tenantid) => {
    let sharing_url = `https://${process.env.REACT_APP_URL_NAME}/profile-sharing/${tenantid}`;
    navigator.clipboard.writeText(sharing_url);
    //toast.success("Sharing Link has been copied");
    snackbar.showMessage("Sharing Link has been copied");
  };

  useEffect(() => {
    const user_type = localStorage.getItem("usertype");
    const userId = localStorage.getItem("saveduserid");
    if (haveValue(cookies.requestProfileLink) && user_type == "tenant") {
      if (haveValue(userId)) {
        if (user?.docSigned) {
          shareProfile(userId, cookies.requestProfileLink, "null", "full", {
            shareType: "lLink",
          })
            .then((res) => {
              removeCookie("requestProfileLink", { maxAge: 1800 });
            })
            .catch((err) => {
              //console.log(err)
            });
        } else {
          navigate("/profile-shared?agreement=true");
        }
      }
    }

    if (haveValue(cookies.gurantorFor) && user_type == "tenant") {
      if (
        haveValue(userId) &&
        !haveValue(user?.gurantor_for) &&
        !haveValue(user?.gurantor_id)
      ) {
        setShowConfirmGuarantor(true);
      }
      if (haveValue(user?.gurantor_for)) {
        setAlreadyGurantorTo(true);
      }
      if (haveValue(user?.gurantor_id)) {
        setAlreadyGurantorOf(true);
      }
    }

    loadOffers();
    if (!user?.analysisCompleted && user?.algoanAnalysis) {
      // getAnalysisDetails();
      setScoreLoading(true);
    }

    myRentedProperties(user?._id);
    myPreviouslyRentedProperties(user?._id);

    if (!user?.onfido_kyc_success) {
      getKycReport(user?._id);
    }

    if (user?.employment_status == constants.employed) {
      if (user?.name_of_company && user?.position && user?.type_of_employee) {
        setIsEmployeementDataSet(true);
      }
    }

    if (
      user?.have_gurantor == "no" ||
      user?.have_gurantor == null ||
      user?.haveGuarantor == false
    ) {
      setHaveGuarantor(false);
    } else {
      setHaveGuarantor(true);
    }
    fetchFileData();

    checkEmailInDeposit(user?.email)
      .then((res) => {
        if (haveValue(res.success_res.checkPresent)) {
          setZeroDeposit(false);
        } else {
          const checkLocal = localStorage.getItem("hideDeposit");
          const localDate = new Date(checkLocal);
          const newDate = new Date();
          console.log(checkLocal, localDate, newDate, "check dates");
          if (newDate > localDate) {
            setZeroDeposit(true);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    checkSalary();
    getkycDetails();
  }, [kycuserinfo]);

  useEffect(() => {
    checkAllMatches();
    calculateIeScore();
  }, [userKycInfo, user]);

  const calculateIeScore = () => {
    var totalScore = (user?.paymentScores?.creditScore?.value || 0) / 2;

    if (user?.ie_verified && user?.is_university) {
      if (totalScore >= 600) {
        totalScore += 200;
      } else {
        totalScore += 400;
      }
    }
    if (totalScore > 850) {
      totalScore = 850;
    }
    setIeScore(totalScore);
  };

  const checkSalary = () => {
    if (haveValue(user?.gurantor_id)) {
      if (user?.gurantor_id?.algoanIncome) {
        // let monthlyIncome = parseInt(
        //   user?.gurantor_id?.algoanIncome?.monthlyAmount
        // );
        const str = String(user?.gurantor_id?.algoanIncome?.monthlyAmount);
        const numericStr = str.replace(/,/g, ""); // Removes only commas
        const monthlyIncome = Math.round(parseFloat(numericStr));

        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user?.gurantor_id?.current_annual_income)
            ? user?.gurantor_id?.current_annual_income
            : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        setRecordedSalary({
          ...recordedSalary,
          matched: salaryMatch,
          salary: recorded_annual_salary,
        });
      }
      // else if (user?.gurantor_id?.payslip_data) {
      //   let monthlyIncome = parseInt(
      //     user?.gurantor_id?.payslip_data?.netSalary
      //   );
      //   let recorded_annual_salary = monthlyIncome * 12;

      //   let userIncome = parseInt(
      //     haveValue(user?.gurantor_id?.current_annual_income)
      //       ? user?.gurantor_id?.current_annual_income
      //       : 0
      //   );
      //   let salaryMatch = Math.round(
      //     (recorded_annual_salary / userIncome) * 100
      //   );

      //   setRecordedSalary({
      //     ...recordedSalary,
      //     matched: salaryMatch,
      //     salary: recorded_annual_salary,
      //   });
      // }
    } else {
      if (user.algoanIncome) {
        let monthlyIncome = parseInt(user.algoanIncome?.monthlyAmount);
        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user.current_annual_income) ? user.current_annual_income : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        setRecordedSalary({
          ...recordedSalary,
          matched: salaryMatch,
          salary: recorded_annual_salary,
        });
      }
      // else if (user?.payslip_data) {
      //   const str = String(user?.payslip_data?.netSalary);
      //   const numericStr = str.replace(/,/g, ""); // Removes only commas
      //   const monthlyIncome = Math.round(parseFloat(numericStr));

      //   // let monthlyIncome = parseInt();

      //   let recorded_annual_salary = monthlyIncome * 12;

      //   let userIncome = parseInt(
      //     haveValue(user?.gurantor_id?.current_annual_income)
      //       ? user?.gurantor_id?.current_annual_income
      //       : 0
      //   );
      //   let salaryMatch = Math.round(
      //     (recorded_annual_salary / userIncome) * 100
      //   );

      //   setRecordedSalary({
      //     ...recordedSalary,
      //     matched: salaryMatch,
      //     salary: recorded_annual_salary,
      //   });
      // }
    }
  };

  useEffect(() => {
    getkycDetails();
  }, [user]);

  const getkycDetails = () => {
    let userid = localStorage.getItem("saveduserid");
    if (user?.onfido_kyc_success) {
      //// console.log("kyc has been done");
      loadKycReport(userid);
    } else {
      //// console.log("kyc has  been done but result has not been fetched");
      getKycReport(userid);
    }
  };

  const loadKycReport = async (tenantid) => {
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/tenants/getkycreport/${tenantid}`,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        //// console.log(res);
        //toast.success("KYC Report Fetched");
        setUserKycInfo(res.data.kycuser);
      })
      .catch((err) => {
        //console.log(err);
        //toast.error("Kyc Report Not Fetched")
      });
  };

  const getKycReport = async (tenantid) => {
    let myInterval = setInterval(async () => {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/tenants/getkycstatus/${tenantid}`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          ////  console.log(res);
          if (res.data.status === "success") {
            //toast.success("KYC Report Fetched")
            setUserKycInfo(res.data.kycuser);
            clearInterval(myInterval);
          } else {
            clearInterval(myInterval);
          }
        })
        .catch((err) => {
          //console.log(err);
          //toast.error("Kyc Report Not Fetched")
          clearInterval(myInterval);
        });
    }, 5000);
  };

  const checkAddress = () => {
    //return <img src={tickNew} alt="" />
    return "";
  };

  const checkCompanyname = () => {
    let companyname = user?.name_of_company;
    if (user?.algoanIncome?.employers.length !== 0 && user?.algoanIncome) {
      for (let company of user?.algoanIncome?.employers) {
        if (companyname === company) {
          return <img src={tickNew} alt="" />;
        }
      }
    } else {
      return <img src={crossNew} alt="" />;
    }
  };

  const getAnalysisDetails = async (req, res) => {
    let userid = localStorage.getItem("saveduserid");

    let myInterval = setInterval(async () => {
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/tenants/checkmyanalysis/${userid}`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then((res) => {
          //// console.log(res);
          setAlgoanLoading(false);
          setScoreLoading(false);
          clearInterval(myInterval);
          //// console.log("interval stopped");
          loadUserTenant(userType);
        })
        .catch((err) => {
          setScoreLoading(false);
          setAlgoanLoading(false);
          //// console.log(err.response.data);
          setScoreError(err.response.data);
          clearInterval(myInterval);
          return;
        });
    }, 2000);
  };

  const loadOffers = async () => {
    let url = `${process.env.REACT_APP_SERVER_URL}/offers/`;
    await axios
      .get(url, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        setLoading(false);
        setOffers(res.data.offers);
        //// console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        //console.log(err);
      });
  };

  const latestKycdate = () => {
    if (user?.paymentScores) {
      let date = new Date(user?.paymentScores?.createdAt);
      return date.toISOString().substring(0, 10);
    }
    return "no data";
  };

  const paymentOptions = () => {
    //navigate('/rent-payment')
  };

  const verificationNotDone = () => {
    return (
      <ul className="infodorpdown scrorebardrop">
        <li>
          <button className="iconinfo">
            <img src={crossNew} alt="" />
          </button>{" "}
        </li>
        <ul className="tooltip">
          {/* <li>1. Irregular Income</li> */}
          <li>{_t("You haven’t completed your digital verification yet")}.</li>
          <li>
            <div className="verify-btn">
              <button
                className="btn btnorange w-100"
                type="button"
                disabled={buttonDisable}
                onClick={initiateOnfido}
              >
                {_t("Verify Now")}
                <MdOutlineVerified />
              </button>
            </div>
          </li>
        </ul>
      </ul>
    );
  };
  const verificationInProgress = () => {
    return (
      <ul className="infodorpdown scrorebardrop">
        <li>
          <button className="iconinfo">
            <img src={exclamation} alt="" />
          </button>{" "}
        </li>
        <ul className="tooltip">
          <li>{_t("Verification in process")}</li>
        </ul>
      </ul>
    );
  };

  const checkName = () => {
    let fname = user?.fname.toString();
    let lname = user?.lname.toString();
    let newName = fname.split(" ");
    let newLname = lname.split(" ");

    let temp_fname = "";
    for (let i = 0; i < newName.length; i++) {
      newName[i] = newName[i]?.toLowerCase();
      temp_fname = temp_fname + newName[i];
    }

    let temp_lname = "";
    for (let i = 0; i < newLname.length; i++) {
      newLname[i] = newLname[i]?.toLowerCase();
      temp_lname = temp_lname + newLname[i];
    }

    let user_fullname = temp_fname + temp_lname;

    let kyc_fullname = "";
    if (
      (userKycInfo?.onfido_doc_data?.first_name,
        userKycInfo?.onfido_doc_data?.last_name)
    ) {
      let kyc_fname = userKycInfo?.onfido_doc_data?.first_name;
      let kyc_lname = userKycInfo?.onfido_doc_data?.last_name;

      let kycName = kyc_fname.split(" ");
      let kycLname = kyc_lname.split(" ");

      let temp_fname1 = "";
      for (let i = 0; i < newName.length; i++) {
        if (kycName[i]) {
          kycName[i] = kycName[i]?.toLowerCase();
          temp_fname1 = temp_fname1 + kycName[i];
        }
      }

      let temp_lname1 = "";
      for (let i = 0; i < newLname.length; i++) {
        if (kycLname[i]) {
          kycLname[i] = kycLname[i]?.toLowerCase();
          temp_lname1 = temp_lname1 + kycLname[i];
        }
      }

      kyc_fullname = temp_fname1 + temp_lname1;

      //console.log(kyc_fullname);
    }

    if (user_fullname === kyc_fullname) {
      // if (true) {
      return <img src={tickNew} alt="" />;
    } else {
      // return "NOT MATCHED";
      if (user?.onfido_kyc_success == true) {
        return (
          <ul className="infodorpdown scrorebardrop">
            <li>
              <button className="iconinfo">
                <img src={crossNew} alt="" />
              </button>{" "}
            </li>
            <ul className="tooltip">
              {/* <li>1. Irregular Income</li> */}
              <li>{_t("Your name does not match with your digital ID")}</li>
              <li>
                <div className="verify-btn">
                  <button
                    className="btn btnorange w-100"
                    type="button"
                    disabled={buttonDisable}
                    onClick={initiateOnfido}
                  >
                    {_t("Verify Again")}
                    <MdOutlineVerified />
                  </button>
                </div>
              </li>
            </ul>
          </ul>
        );
      } else {
        if (
          user?.onfido_kyc_success == false &&
          user?.onfido_user_kyc?.onfido_doc &&
          user?.onfido_user_kyc?.onfido_workflow_res?.status != "declined"
        )
          return verificationInProgress();
        else return verificationNotDone();
      }
    }
  };

  const checkNationality = () => {
    let kyc_nationality =
      userKycInfo?.onfido_doc_data?.nationality?.toLowerCase();
    let country_code = getAlpha3Code(user?.nationality?.toLowerCase());

    if (
      kyc_nationality &&
      (user?.nationality?.toLowerCase() == kyc_nationality ||
        country_code?.toLowerCase() == kyc_nationality)
    ) {
      // return kyc_nationality;
      return <img src={tickNew} alt="" />;
    } else {
      if (user?.onfido_kyc_success == true) {
        return (
          <ul className="infodorpdown scrorebardrop">
            <li>
              <button className="iconinfo">
                <img src={crossNew} alt="" />
              </button>{" "}
            </li>
            <ul className="tooltip">
              {/* <li>1. Irregular Income</li> */}
              <li>
                {_t("Your nationality does not match with your digital ID")}
              </li>
              <li>
                <div className="verify-btn">
                  <button
                    className="btn btnorange w-100"
                    type="button"
                    disabled={buttonDisable}
                    onClick={initiateOnfido}
                  >
                    {_t("Verify Again")}
                    <MdOutlineVerified />
                  </button>
                </div>
              </li>
            </ul>
          </ul>
        );
      } else {
        if (
          user?.onfido_kyc_success == false &&
          user?.onfido_user_kyc?.onfido_doc &&
          user?.onfido_user_kyc?.onfido_workflow_res?.status != "declined"
        )
          return verificationInProgress();
        else return verificationNotDone();
      }
    }
  };

  const checkPassportKYC = () => {
    let kyc_type = user?.onfido_user_kyc?.onfido_doc?.document_front?.type;
    let document_number = userKycInfo?.onfido_doc_data?.document_number;
    if (kyc_type == "passport") {
      if (document_number == user?.passport_id)
        return <img src={tickNew} alt="" />;
      else {
        if (user?.onfido_kyc_success == true) {
          return (
            <ul className="infodorpdown scrorebardrop">
              <li>
                <button className="iconinfo">
                  <img src={crossNew} alt="" />
                </button>{" "}
              </li>
              <ul className="tooltip">
                {/* <li>1. Irregular Income</li> */}
                <li>
                  {_t("Your Passport ID does not match with your digital ID")}
                </li>
                <li>
                  <div className="verify-btn">
                    <button
                      className="btn btnorange w-100"
                      type="button"
                      disabled={buttonDisable}
                      onClick={initiateOnfido}
                    >
                      {_t("Verify Again")}
                      <MdOutlineVerified />
                    </button>
                  </div>
                </li>
              </ul>
            </ul>
          );
        } else {
          if (
            user?.onfido_kyc_success == false &&
            user?.onfido_user_kyc?.onfido_doc &&
            user?.onfido_user_kyc?.onfido_workflow_res?.status != "declined"
          )
            return verificationInProgress();
          else return verificationNotDone();
        }
      }
    }

    return "";
  };
  const checkDNIKyc = () => {
    let kyc_type = user?.onfido_user_kyc?.onfido_doc?.document_front?.type;
    let document_number = userKycInfo?.onfido_doc_data?.personal_number;
    if (kyc_type != "passport") {
      if (document_number == user?.dni_nie) return <img src={tickNew} alt="" />;
      else {
        if (user?.onfido_kyc_success == true) {
          return (
            <ul className="infodorpdown scrorebardrop">
              <li>
                <button className="iconinfo">
                  <img src={crossNew} alt="" />
                </button>{" "}
              </li>
              <ul className="tooltip">
                {/* <li>1. Irregular Income</li> */}
                <li>
                  {_t("Your Dni/Nie ID does not match with your digital ID")}
                </li>
                <li>
                  <div className="verify-btn">
                    <button
                      className="btn btnorange w-100"
                      type="button"
                      disabled={buttonDisable}
                      onClick={initiateOnfido}
                    >
                      {_t("Verify Again")}
                      <MdOutlineVerified />
                    </button>
                  </div>
                </li>
              </ul>
            </ul>
          );
        } else {
          if (
            user?.onfido_kyc_success == false &&
            user?.onfido_user_kyc?.onfido_doc &&
            user?.onfido_user_kyc?.onfido_workflow_res?.status != "declined"
          )
            return verificationInProgress();
          else return verificationNotDone();
        }
      }
    }

    return "";
  };

  const BankhandleClose = () => BanksetShow(false);
  const bankhandleShow = () => BanksetShow(true);
  const [countryCodeError, setCountryCodeError] = useState("");

  const [tink_countryCode, setTink_countryCode] = useState("");
  const changeCountryCode = (e) => {
    setTink_countryCode(e.target.value);
  };

  const connectToBank = () => {
    if (!tink_countryCode) {
      setCountryCodeError("*Please select country code");
      return;
    } else {
      setCountryCodeError("");
    }
    // let tinkUrlLiveFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fhome&market=${tink_countryCode}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;
    var lang = "en_US";
    if (localStorage.getItem("i18nextLng") == "es") {
      lang = "es_ES";
    }
    let tinkUrlLiveFront = `https://link.tink.com/1.0/reports/create-report?client_id=${process.env.REACT_APP_TINK_CLIENT_ID}&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_URL_NAME}%2Fhome&market=${tink_countryCode}&locale=${lang}&report_types=TRANSACTION_REPORT,ACCOUNT_VERIFICATION_REPORT&refreshable_items=IDENTITY_DATA,CHECKING_ACCOUNTS,SAVING_ACCOUNTS,CHECKING_TRANSACTIONS,SAVING_TRANSACTIONS&account_dialog_type=SINGLE`;
    window.open(tinkUrlLiveFront, "_self");
  };

  const handleGurantorModal = () => {
    handleShowGurantor();
  };

  const handleSubmitGurantor = async (e) => {
    e.preventDefault();
    let userid = localStorage.getItem("saveduserid");
    await axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/tenants/gurantor/${userid}`,
        guarantorData,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        if (res.data?.alreadyGurantor) {
          setGurantorPresent(true);
          setAlreadyGurantorOf(true);
          handleHideGurantor();
        } else {
          toast.success(_t("Guarantor Added!"));
          loadUser(userType);
          sendMail(guarantorData?.gurantor_email, "score");
          handleHideGurantor();
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response?.data?.error);
      });
    //console.log("SUBMITTED")
  };

  const getEmploymentStatus = () => {
    if (user?.employment_status == constants.employed) {
      return _t("Employed");
    }
    if (user?.employment_status == constants.self_employed) {
      return _t("Self-Employed");
    }
    if (user?.employment_status == constants.student) {
      return _t("Student");
    }
    if (user?.employment_status == constants.unemployed) {
      return _t("Unemployed");
    }
  };
  const initiateOnfido = () => {
    setButtonDisable(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/tenants/kyc/${user?._id}`)
      .then((res) => {
        //console.log("ONFIDO WITH WORKFLOW= ", res.data);
        setButtonDisable(false);
        initiateKyc(res.data.sdk_data.token, res.data.workflow_data.id);
      })
      .catch((err) => {
        //console.log("Error Onfido = ", err);
        setButtonDisable(false);
      });
  };
  const initiateKyc = (token, workFlowRunId) => {
    //console.log("On Fido Token = ", token);
    setButtonDisable(true);
    let onfido = {};

    onfido = init({
      token: token,
      useModal: true,
      isModalOpen: true,
      language: localStorage.getItem("i18nextLng") == "es" ? "es" : "en_US",
      containerId: "onfido-mount",
      workflowRunId: `${workFlowRunId}`,
      onComplete: function (data) {
        console.log("everything is complete");

        setButtonDisable(false);
        saveOnfidoKycResponse(data);
        loadUser(userType);
      },
      onError: function (err) {
        //console.log("onfido error happend");
        //console.log("ERROR OnFIDO = ", err);
        setButtonDisable(false);
      },
      onUserExit: function (userExitCode) {
        console.log("user exitted", userExitCode);
        setButtonDisable(false);
      },
      onModalRequestClose: function () {
        // Update options with the state of the modal
        onfido.setOptions({ isModalOpen: false });
        setButtonDisable(false);
        console.log("modal closed of onfido");
      },
      //steps: ['welcome', 'poa', 'document', 'face', 'complete'],  //poa-proof of address
      steps: ["welcome", "document", "face", "complete"],
    });
  };

  const saveOnfidoKycResponse = (kycData) => {
    onfidoKyc(user?._id, kycData)
      .then((res) => {
        //console.log("promise resolved then response", res);
      })
      .catch((err) => {
        //console.log("promise rejected err is ", err);
      });
  };

  const handleViewDocument = async () => {
    setShowSpinner(true);
    let userid = localStorage.getItem("saveduserid");
    getKycDocs(userid)
      .then((res) => {
        const nodeJSBuffer = res.data.images.data;
        const uint8Array = new Uint8Array(nodeJSBuffer);

        // Convert Uint8Array to ArrayBuffer
        const arrayBuffer = uint8Array.buffer;

        // Create a Blob
        const blob = new Blob([arrayBuffer], {
          type: "application/octet-stream",
        });

        // Create a download link
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "documents.zip";

        // Append the link to the body and click it to trigger the download
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(a.href);
        setShowSpinner(false);
      })
      .catch((err) => {
        setShowSpinner(false);
        console.log(err);
      });
  };

  const checkAllMatches = async () => {
    //console.log("CHECKALLMATCHES CALLED!")
    let fname = user?.fname.toString();
    let lname = user?.lname.toString();
    let newName = fname.split(" ");
    let newLname = lname.split(" ");

    let temp_fname = "";
    for (let i = 0; i < newName.length; i++) {
      newName[i] = newName[i]?.toLowerCase();
      temp_fname = temp_fname + newName[i];
    }

    let temp_lname = "";
    for (let i = 0; i < newLname.length; i++) {
      newLname[i] = newLname[i]?.toLowerCase();
      temp_lname = temp_lname + newLname[i];
    }

    let user_fullname = temp_fname + temp_lname;

    let kyc_fullname = "";
    if (
      (userKycInfo?.onfido_doc_data?.first_name,
        userKycInfo?.onfido_doc_data?.last_name)
    ) {
      let kyc_fname = userKycInfo?.onfido_doc_data?.first_name;
      let kyc_lname = userKycInfo?.onfido_doc_data?.last_name;

      let kycName = kyc_fname.split(" ");
      let kycLname = kyc_lname.split(" ");

      let temp_fname1 = "";
      for (let i = 0; i < newName.length; i++) {
        if (kycName[i]) {
          kycName[i] = kycName[i]?.toLowerCase();
          temp_fname1 = temp_fname1 + kycName[i];
        }
      }

      let temp_lname1 = "";
      for (let i = 0; i < newLname.length; i++) {
        if (kycLname[i]) {
          kycLname[i] = kycLname[i]?.toLowerCase();
          temp_lname1 = temp_lname1 + kycLname[i];
        }
      }

      kyc_fullname = temp_fname1 + temp_lname1;
    }
    // if (user_fullname !== kyc_fullname) {
    //   // if (true) {
    //   // console.log("failed in name")
    //   setIdent_all(false);
    //   return false;
    // }

    if (user?.onfido_kyc_success == false) {
      // if (true) {
      // console.log("failed in name")
      setIdent_all(false);
      return false;
    }

    let kyc_nationality =
      userKycInfo?.onfido_doc_data?.nationality?.toLowerCase();
    let country_code = getAlpha3Code(user?.nationality?.toLowerCase());

    // if (
    //   kyc_nationality &&
    //   user?.nationality?.toLowerCase() != kyc_nationality &&
    //   country_code?.toLowerCase() != kyc_nationality
    // ) {
    //   // console.log("failed in nationality")
    //   setIdent_all(false);
    //   return false;
    // }

    let kyc_type = user?.onfido_user_kyc?.onfido_doc?.document_front?.type;
    let document_number = userKycInfo?.onfido_doc_data?.document_number;
    let personal_number = userKycInfo?.onfido_doc_data?.personal_number;
    // if (kyc_type == "passport") {
    //   if (
    //     haveValue(user?.passport_id) &&
    //     document_number != user?.passport_id
    //   ) {
    //     // console.log("failed in passport")
    //     setIdent_all(false);
    //     return false;
    //   }
    // } else {
    //   if (haveValue(user?.dni_nie) && personal_number != user?.dni_nie) {
    //     // console.log("failed in dnie")
    //     setIdent_all(false);
    //     return false;
    //   }
    // }
    // console.log("failed no where")
    setIdent_all(true);
    return true;
  };

  const handleRemoveGuarantor = () => {
    setRemoveGuarantorModel(true);
  };

  const hideRemoveGuarantor = () => {
    setRemoveGuarantorModel(false);
  };

  const submitRemoveGuarantor = async (e) => {
    e.preventDefault();
    await removeGuarantor(user?.gurantor_for?._id, user?._id);
    hideRemoveGuarantor();
    loadUser(userType);
  };

  const handleFileUpload = async () => {
    try {
      setUploadLoading(true);
      await uploadFileData(fileData);

      setFileData(null);
      setFileType("");
      await fetchFileData();
      handleClose();
      setUploadLoading(false);
    } catch (err) {
      setUploadLoading(false);
      console.log(err);
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("tenantDocs", files[i]);
    }
    formData.append("fileType", fileType);

    setFileData(formData);
  };

  const handleSelectChange = (e) => {
    setFileType(e.target.value);
  };

  const fetchFileData = async () => {
    let userid = localStorage.getItem("saveduserid");
    const res = await fetchTenantDocs(userid);
    console.log(res);
    setTenantDocsData(res.data.success_res.tenantDocs);
  };

  const handleDeleteDoc = async (doc_id) => {
    setLoading(true);
    try {
      const res = await deleteTenantDoc(doc_id);
      await fetchFileData();
      setLoading(false);
      setDeleteId(null);
      setDeleteDocumentModal(false);
      if (res != false) toast.success(_t("Document Deleted Successfully"));
    } catch (err) {
      setLoading(false);
      toast.error(_t("Unable To Delete Document"));
    }
  };

  const handleHideDocument = () => {
    setDeleteDocumentModal(false);
    setDeleteId(null);
  };

  const handlePreviewDocument = (url) => {
    window.open(url, "blank");
  };

  const handleAccordionSelect = (eventKey) => {
    console.log(currentActiveAccordion);
    setCurrentActiveAccordion(parseInt(eventKey));
  };

  const handleScrollUploadDoc = () => {
    document.getElementsByClassName("accordion-button collapsed")[0].click();
  };

  const handleJoinWaitlist = () => {
    joinWaitlist(user?.email)
      .then((res) => {
        toast.success(_t("Successfully added in list!"));
        setZeroDeposit(false);
      })
      .catch((err) => toast.error(_t("Error while adding in waitlist!")));
  };

  const handleCloseZero = () => {
    let currentDate = moment();

    let newDate = currentDate.add(1, "days");
    localStorage.setItem("hideDeposit", new Date(newDate));
    setZeroDeposit(false);
  };

  const printDocument = () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      printDocumentSafari();
    } else {
      printDocumentOthers();
    }
  };

  const printDocumentOthers = () => {
    var node = document.getElementById("divToPrint");
    const documentEle = document.getElementById("viewDocumentBtn");
    const downloadProfileBtn = document.getElementById("donwloadProfileBtn");
    const verifyNowBtn = document.getElementById("verify_now_btn");
    if (haveValue(documentEle))
      documentEle.style.setProperty("display", "none", "important");
    if (haveValue(downloadProfileBtn))
      downloadProfileBtn.style.setProperty("display", "none", "important");
    if (haveValue(verifyNowBtn))
      verifyNowBtn.style.setProperty("display", "none", "important");
    domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        const pdf = new jsPDF("p", "mm", "a4");
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const contentWidth = node.offsetWidth;
        const contentHeight = node.offsetHeight;
        // Calculate scale factors for width and height
        const scaleFactorWidth = pdfWidth / contentWidth;
        const scaleFactorHeight = pdfHeight / contentHeight;
        // Choose the smaller scale factor to fit content within A4 size
        const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);
        // Calculate image dimensions
        const imgWidth = contentWidth * scaleFactor;
        const imgHeight = contentHeight * scaleFactor;
        pdf.addImage(dataUrl, "PNG", 0, 0, imgWidth, imgHeight);
        pdf.save("SkorProfile.pdf");
        if (documentEle) {
          documentEle.style.removeProperty("display");
        }
        if (downloadProfileBtn) {
          downloadProfileBtn.style.removeProperty("display");
        }
        if (verifyNowBtn) {
          verifyNowBtn.style.removeProperty("display");
        }
        // document.getElementById("viewDocumentBtn").style.removeProperty('display');
        // document.getElementById("donwloadProfileBtn").style.removeProperty('display');
      })
      .catch(function (error) {
        console.error("Oops, something went wrong!", error);
        if (documentEle) {
          documentEle.style.removeProperty("display");
        }
        if (downloadProfileBtn) {
          downloadProfileBtn.style.removeProperty("display");
        }
        if (verifyNowBtn) {
          verifyNowBtn.style.removeProperty("display");
        }
        // document.getElementById("viewDocumentBtn").style.removeProperty('display');
        // document.getElementById("donwloadProfileBtn").style.removeProperty('display');
      });
  };

  const printDocumentSafari = async () => {
    const node = document.getElementById("divToPrint");
    const documentEle = document.getElementById("viewDocumentBtn");
    const downloadProfileBtn = document.getElementById("donwloadProfileBtn");
    const verifyNowBtn = document.getElementById("verify_now_btn");

    if (documentEle)
      documentEle.style.setProperty("display", "none", "important");
    if (downloadProfileBtn)
      downloadProfileBtn.style.setProperty("display", "none", "important");
    if (verifyNowBtn)
      verifyNowBtn.style.setProperty("display", "none", "important");

    const tempClassList = document.querySelector(
      ".CircularProgressbar .CircularProgressbar-path"
    ).classList;
    try {
      document.querySelector(
        ".CircularProgressbar .CircularProgressbar-path"
      ).classList = "changeClass";
      const canvas = await html2canvas(node, { useCORS: true });
      const dataUrl = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const contentWidth = canvas.width;
      const contentHeight = canvas.height;

      // Calculate scale factors for width and height
      const scaleFactorWidth = pdfWidth / contentWidth;
      const scaleFactorHeight = pdfHeight / contentHeight;

      // Choose the smaller scale factor to fit content within A4 size
      const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

      // Calculate image dimensions
      const imgWidth = contentWidth * scaleFactor;
      const imgHeight = contentHeight * scaleFactor;

      document
        .querySelector(".CircularProgressbar .changeClass")
        .classList.add("CircularProgressbar-path");
      pdf.addImage(dataUrl, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("SkorProfile.pdf");

      if (documentEle) documentEle.style.removeProperty("display");
      if (downloadProfileBtn)
        downloadProfileBtn.style.removeProperty("display");
      if (verifyNowBtn) verifyNowBtn.style.removeProperty("display");
    } catch (error) {
      document
        .querySelector(".CircularProgressbar .changeClass")
        .classList.add("CircularProgressbar-path");
      console.error("Error capturing element:", error);
    }
  };

  const checkIeDetails = () => {
    var check = true;

    if (!haveValue(user?.ie_details_id)) {
      check = false;
    }
    // if (user?.fname?.toLowerCase().replace(/\u00A0/g, ' ') != user?.ie_details_id?.fname?.toLowerCase().replace(/\u00A0/g, ' ')) {
    //   check = false;
    // }
    // if (user?.lname?.toLowerCase().replace(/\u00A0/g, ' ') != user?.ie_details_id?.lname?.toLowerCase().replace(/\u00A0/g, ' ')) {
    //   check = false
    // }
    if (user?.ie_student_id != user?.ie_details_id?.student_id) {
      check = false;
    }
    return check;
  };

  const fetchCompanyName = () => {
    var found = false;
    var res = null;
    if (haveValue(user?.vida_laboral_id)) {
      const response = user?.vida_laboral_id?.response;
      console.log(
        user?.dni_nie == response?.informacionPersonal?.identificacion,
        "CHECK THE HERE"
      );
      if (user?.dni_nie == response?.informacionPersonal?.identificacion) {
        const companyArr = user?.vida_laboral_id?.response?.situaciones;
        var company_name = null;
        for (let val of companyArr) {
          if (!haveValue(val?.fechaBaja)) {
            company_name = val?.nombreEmpresa;
            break;
          }
        }
        if (haveValue(company_name)) {
          found = true;
          res = { type: "Vida Laboral", val: company_name };
        }
      }
    }
    if (
      found == false &&
      haveValue(user?.algoanIncome?.employers) &&
      user?.algoanIncome?.employers.length > 0
    ) {
      found = true;
      res = { type: "Bank", val: user?.algoanIncome?.employers[0]?.name };
    }
    if (found == false && haveValue(user?.name_of_company)) {
      found = true;
      res = { type: "", val: user?.name_of_company };
    }
    if (found == false) {
      res = null;
    }
    if (haveValue(res)) {
      return (
        <>
          <h4 className="d-flex gap-2 align-items-center">
            {_t("Name Of Company")}{" "}
            {haveValue(res.type) && (
              <ul className="infodorpdown summarydropdum position-relative">
                <li>
                  <button className="iconinfo">
                    <MdInfoOutline />
                  </button>{" "}
                </li>
                <ul className="tooltip">
                  <p>
                    {_t("From - ")}
                    {res.type}
                  </p>
                </ul>
              </ul>
            )}
            :
          </h4>
          <h3>
            {res.val} {haveValue(res.type) && <img src={tickNew} alt="" />}
          </h3>
        </>
      );
    }
  };

  const checkPayslipData = () => {

    if (haveValue(user?.payslip_data) && user?.payslip_data?.nieDniNumber == user?.dni_nie) {
      return (
        <>
          <Col md={6}>
            <div className="Score-content-list">
              <h4>{_t("Income from Payslip")}:</h4>€
              {convertCurrencyFormat(parseInt(user?.payslip_data?.netSalary))}
            </div>
          </Col>
        </>
      );
    } else {
      return <Col md={6}>
        <div className="Score-content-list">
          <h4>{_t("Income from Payslip")}:</h4>
        </div>
      </Col>
    }
  };

  return (
    <>
      <div id="onfido-mount"></div>
      <div className="landlord-profile-edit">
        {zeroDeposit && (
          <></>
          // <div className="tenant_zero_notification">
          //   <p>
          //     <svg
          //       xmlns="http://www.w3.org/2000/svg"
          //       width="24"
          //       height="24"
          //       viewBox="0 0 24 24"
          //       fill="none"
          //     >
          //       <path
          //         fill-rule="evenodd"
          //         clip-rule="evenodd"
          //         d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.5239 10.1957L14.188 9.7539L12.5864 16.9971C12.4728 17.5206 12.6328 17.8177 13.0728 17.8177C13.3832 17.8177 13.8504 17.7099 14.1704 17.439L14.0296 18.0794C13.5704 18.6121 12.5576 19 11.6856 19C10.5607 19 10.0823 18.3503 10.3927 16.9694L11.5735 11.6305C11.676 11.1795 11.5831 11.0163 11.1127 10.9085L10.3927 10.7807L10.5239 10.1957ZM13.8314 7.62805C14.1314 7.33934 14.3 6.94777 14.3 6.53948C14.3 6.13118 14.1314 5.73961 13.8314 5.4509C13.5313 5.16219 13.1243 5 12.7 5C12.2756 5 11.8686 5.16219 11.5686 5.4509C11.2685 5.73961 11.0999 6.13118 11.0999 6.53948C11.0999 6.94777 11.2685 7.33934 11.5686 7.62805C11.8686 7.91676 12.2756 8.07895 12.7 8.07895C13.1243 8.07895 13.5313 7.91676 13.8314 7.62805Z"
          //         fill="#253837"
          //       />
          //     </svg>
          //     <span>{_t("Tenant will add Payment Details")}.</span>
          //     <a className="zero_deposit_a" onClick={handleJoinWaitlist}>
          //       {" "}
          //       {_t("Join Waitlist")}{" "}
          //     </a>
          //   </p>
          //   <a
          //     href={undefined}
          //     className="close_zero_deposit"
          //     onClick={handleCloseZero}
          //   >
          //     <IoClose />
          //   </a>
          // </div>
        )}

        <div
          className="py-2 text-center back-btn"
          style={{ "text-align-last": "start" }}
        >
          <div className="">
            <a
              href={undefined}
              onClick={(e) => navigate("/tenant")}
              className="d-flex gap-2 align-items-center"
            >
              <img src="/images/back-btn-white.svg" alt="" />
              {/* <span>{_t("Go Back To Home")} </span> */}
            </a>
          </div>
        </div>

        <Accordion
          className="primaryaccordion"
          defaultActiveKey={currentActiveAccordion.toString()}
          onSelect={handleAccordionSelect}
        >
          <Accordion.Item eventKey="0" id="divToPrint">
            <div className="withdownloadbtn">
              <Accordion.Header className="primaryaccordion-header setaccordionhead sdv">
                <div className="d-flex justify-content-between w-100 score_profile_div">
                  <span className="maintitle">{_t("Skor and Details")}</span>
                  {currentActiveAccordion.toString() != "0" && (
                    <span className="accordion_score">
                      {haveValue(user?.gurantor_id) &&
                        haveValue(user?.gurantor_id?.paymentScores)
                        ? haveValue(
                          user?.gurantor_id?.paymentScores?.creditScore?.value
                        )
                          ? user?.gurantor_id?.paymentScores?.creditScore?.value
                          : 0
                        : haveValue(user?.paymentScores?.creditScore?.value)
                          ? user?.ie_verified && user?.is_university
                            ? ieScore
                            : +user?.paymentScores?.creditScore?.value
                          : user?.ie_verified && user?.is_university
                            ? ieScore
                            : +0}
                    </span>
                  )}
                </div>
              </Accordion.Header>
              {currentActiveAccordion.toString() == "0" && (
                <button
                  className="download_profile_btn downloadProfileBtn"
                  id="donwloadProfileBtn"
                  onClick={(e) =>
                    window.open(
                      `https://${process.env.REACT_APP_URL_NAME}/profile/preview/${user?._id}?user_type=tenant`,
                      "_blank"
                    )
                  }
                >
                  <svg
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="27" cy="27" r="27" fill="#A3EB64" />
                    <path
                      d="M22.0907 17.1836C20.7369 17.1836 19.6362 18.2843 19.6362 19.6381V23.32H22.0907V19.6381H30.7852L31.9089 20.7619V23.32H34.3635V20.7619C34.3635 20.1099 34.1065 19.4847 33.6463 19.0245L32.5225 17.9008C32.0623 17.4406 31.4372 17.1836 30.7852 17.1836H22.0907ZM31.9089 30.6836V34.3654H22.0907V30.6836H31.9089ZM34.3635 31.9109H35.5907C36.2696 31.9109 36.818 31.3624 36.818 30.6836V27.0018C36.818 25.6479 35.7173 24.5472 34.3635 24.5472H19.6362C18.2824 24.5472 17.1816 25.6479 17.1816 27.0018V30.6836C17.1816 31.3624 17.7301 31.9109 18.4089 31.9109H19.6362V34.3654C19.6362 35.7192 20.7369 36.82 22.0907 36.82H31.9089C33.2627 36.82 34.3635 35.7192 34.3635 34.3654V31.9109ZM33.7498 26.695C33.9939 26.695 34.2281 26.7919 34.4007 26.9646C34.5733 27.1372 34.6703 27.3713 34.6703 27.6154C34.6703 27.8595 34.5733 28.0937 34.4007 28.2663C34.2281 28.4389 33.9939 28.5359 33.7498 28.5359C33.5057 28.5359 33.2716 28.4389 33.099 28.2663C32.9263 28.0937 32.8294 27.8595 32.8294 27.6154C32.8294 27.3713 32.9263 27.1372 33.099 26.9646C33.2716 26.7919 33.5057 26.695 33.7498 26.695Z"
                      fill="#253837"
                    />
                  </svg>
                </button>
              )}
            </div>
            <Accordion.Body className="primaryaccordion-body paddingTop0">
              <div className="tabs-box-pattern">
                <div id="main-content">
                  <div id="page-container" className="tab-content">
                    <div
                      id="home"
                      className="tab-pane active padding-full-zero"
                    >
                      <div className="tabs-item d-block">
                        {/* START  */}

                        <div className="userscrollprofile-topbar">
                          {
                            <Row className="mx-0">
                              {haveValue(user?.gurantor_id) &&
                                haveValue(user?.gurantor_id?.paymentScores) ? (
                                user?.gurantor_id?.paymentScores && (
                                  <Col xl={6} className="mb-3">
                                    <div className="border-pd accordion-tile">
                                      <h4>{_t("Profile")}</h4>
                                      <div className="process-bar-wappers process2 ">
                                        {user?.gurantor_id?.paymentScores && (
                                          <>
                                            <div className="gradient-progress-block">
                                              {/* <svg style={{ height: 0 }}>
                                                <defs>
                                                  <linearGradient
                                                    id="progressGradient"
                                                    gradientTransform="rotate(90)"
                                                  >
                                                    <stop
                                                      offset="0%"
                                                      stopColor={"red"}
                                                    />
                                                    <stop
                                                      offset="50%"
                                                      stopColor={"yellow"}
                                                    />
                                                    <stop
                                                      offset="100%"
                                                      stopColor={"green"}
                                                    />
                                                  </linearGradient>
                                                </defs>
                                              </svg>

                                              <CircularProgressbar
                                                width="75%"
                                                maxValue="1000"
                                                value={
                                                  user?.gurantor_id
                                                    ?.paymentScores.creditScore
                                                    .value
                                                }
                                                // value={800}
                                                text={`${user?.gurantor_id?.paymentScores.creditScore.value}`}
                                                circleRatio={0.5}
                                                strokeWidth={6}
                                                styles={buildStyles({
                                                  textColor: `${progressColor}`,
                                                  pathColor: `${progressColor}`,
                                                  rotation: 0.75,
                                                  strokeLinecap: "round",
                                                })}
                                              /> */}
                                              <ProfileScore
                                                creditScore={
                                                  haveValue(
                                                    user?.gurantor_id
                                                      ?.paymentScores
                                                      ?.creditScore?.value
                                                  )
                                                    ? user?.gurantor_id
                                                      ?.paymentScores
                                                      ?.creditScore?.value <
                                                      800
                                                      ? user?.gurantor_id
                                                        ?.paymentScores
                                                        ?.creditScore?.value
                                                      : 800
                                                    : user?.ie_verified &&
                                                      user?.is_university
                                                      ? ieScore < 800
                                                        ? ieScore
                                                        : 800
                                                      : user?.paymentScores
                                                        ?.creditScore?.value <
                                                        800
                                                        ? user?.paymentScores
                                                          ?.creditScore?.value
                                                        : 800
                                                }
                                                rentScore={0}
                                                showRental={false}
                                              >
                                                <h1>
                                                  {haveValue(
                                                    user?.gurantor_id
                                                      ?.paymentScores
                                                      ?.creditScore?.value
                                                  )
                                                    ? user?.gurantor_id
                                                      ?.paymentScores
                                                      ?.creditScore?.value <
                                                      800
                                                      ? user?.gurantor_id
                                                        ?.paymentScores
                                                        ?.creditScore?.value
                                                      : 800
                                                    : user?.ie_verified &&
                                                      user?.is_university
                                                      ? ieScore < 800
                                                        ? ieScore
                                                        : 800
                                                      : user?.paymentScores
                                                        ?.creditScore?.value <
                                                        800
                                                        ? user?.paymentScores
                                                          ?.creditScore?.value
                                                        : 800}
                                                </h1>
                                                <p>Reliable</p>
                                              </ProfileScore>
                                            </div>

                                            <div className="name-box">
                                              <h3 className="score-name">
                                                {
                                                  user?.gurantor_id?.kyc_details
                                                    ?.userDataByProvider[0]
                                                    ?.accounts[0]?.holderName
                                                }
                                                {"*"}
                                              </h3>
                                              <ul className="score-textgray">
                                                {user?.ie_verified &&
                                                  user?.is_university &&
                                                  !haveValue(
                                                    user?.gurantor_id
                                                      ?.paymentScores?.creditScore
                                                      ?.value
                                                  ) &&
                                                  !haveValue(
                                                    user?.paymentScores
                                                      ?.creditScore?.value
                                                  ) ? (
                                                  <span>
                                                    *
                                                    {_t(
                                                      "Skor based on IE profile"
                                                    )}{" "}
                                                  </span>
                                                ) : (
                                                  <span>
                                                    *
                                                    {_t(
                                                      "Bank Account Used For Scoring"
                                                    )}{" "}
                                                  </span>
                                                )}
                                              </ul>
                                            </div>

                                            {/* <div className="scorebtnbar">
                                              <span className="connect_bank_infotext"></span>
                                              <button
                                                className="rytbarbtn btnorangetextonly"
                                                type="button"
                                                onClick={handleScrollUploadDoc}
                                              >
                                                <FiUpload />
                                                {_t("Upload Documents")}{" "}
                                              </button>
                                            </div> */}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                )
                              ) : user?.paymentScores ||
                                (user?.ie_verified && user?.is_university) ? (
                                <Col xl={6} className="mb-3">
                                  <div className="border-pd accordion-tile">
                                    <h4>{_t("Profile")}</h4>
                                    <div className="process-bar-wappers process2 ">
                                      {(user?.paymentScores ||
                                        (user?.ie_verified &&
                                          user?.is_university)) && (
                                          <>
                                            {/* <div className="gradient-progress-block">
                                            <svg style={{ height: 0 }}>
                                              <defs>
                                                <linearGradient
                                                  id="progressGradient"
                                                  gradientTransform="rotate(90)"
                                                >
                                                  <stop
                                                    offset="0%"
                                                    stopColor={"red"}
                                                  />
                                                  <stop
                                                    offset="50%"
                                                    stopColor={"yellow"}
                                                  />
                                                  <stop
                                                    offset="100%"
                                                    stopColor={"green"}
                                                  />
                                                </linearGradient>
                                              </defs>
                                            </svg>

                                            <CircularProgressbar
                                              width="75%"
                                              maxValue="1000"
                                              value={
                                                user?.ie_verified &&
                                                user?.is_university
                                                  ? ieScore
                                                  : user?.paymentScores
                                                      .creditScore.value
                                              }
                                              text={`${
                                                user?.ie_verified &&
                                                user?.is_university
                                                  ? ieScore
                                                  : user?.paymentScores
                                                      .creditScore.value
                                              }`}
                                              circleRatio={0.5}
                                              strokeWidth={6}
                                              styles={buildStyles({
                                                textColor: `${progressColor}`,
                                                pathColor: `${progressColor}`,
                                                rotation: 0.75,
                                                strokeLinecap: "round",
                                              })}
                                            />
                                          </div> */}
                                            <ProfileScore
                                              creditScore={
                                                haveValue(
                                                  user?.gurantor_id?.paymentScores
                                                    ?.creditScore?.value
                                                )
                                                  ? user?.gurantor_id
                                                    ?.paymentScores?.creditScore
                                                    ?.value < 800
                                                    ? user?.gurantor_id
                                                      ?.paymentScores
                                                      ?.creditScore?.value
                                                    : 800
                                                  : user?.ie_verified &&
                                                    user?.is_university
                                                    ? ieScore < 800
                                                      ? ieScore
                                                      : 800
                                                    : user?.paymentScores
                                                      ?.creditScore?.value < 800
                                                      ? user?.paymentScores
                                                        ?.creditScore?.value
                                                      : 800
                                              }
                                              rentScore={0}
                                              showRental={false}
                                            >
                                              <h1>
                                                {haveValue(
                                                  user?.gurantor_id?.paymentScores
                                                    ?.creditScore?.value
                                                )
                                                  ? user?.gurantor_id
                                                    ?.paymentScores?.creditScore
                                                    ?.value < 800
                                                    ? user?.gurantor_id
                                                      ?.paymentScores
                                                      ?.creditScore?.value
                                                    : 800
                                                  : user?.ie_verified &&
                                                    user?.is_university
                                                    ? ieScore < 800
                                                      ? ieScore
                                                      : 800
                                                    : user?.paymentScores
                                                      ?.creditScore?.value < 800
                                                      ? user?.paymentScores
                                                        ?.creditScore?.value
                                                      : 800}
                                              </h1>
                                              <p>Reliable</p>
                                            </ProfileScore>
                                            <div className="name-box">
                                              <h3 className="score-name">
                                                {haveValue(
                                                  user?.gurantor_id?.paymentScores
                                                    ?.creditScore?.value
                                                )
                                                  ? user?.gurantor_id?.kyc_details
                                                    ?.userDataByProvider[0]
                                                    ?.accounts[0]?.holderName
                                                  : haveValue(
                                                    user?.kyc_details
                                                      ?.userDataByProvider[0]
                                                      ?.accounts[0]?.holderName
                                                  )
                                                    ? user?.kyc_details
                                                      ?.userDataByProvider[0]
                                                      ?.accounts[0]?.holderName
                                                    : `${user?.ie_details_id?.fname} ${user?.ie_details_id?.lname}`}
                                                {"*"}
                                              </h3>
                                              <ul className="score-textgray">
                                                {user?.ie_verified &&
                                                  user?.is_university &&
                                                  !haveValue(
                                                    user?.gurantor_id?.paymentScores
                                                      ?.creditScore?.value
                                                  ) &&
                                                  !haveValue(
                                                    user?.paymentScores?.creditScore
                                                      ?.value
                                                  ) ? (
                                                  <span>
                                                    *
                                                    {_t(
                                                      "Skor based on IE profile"
                                                    )}{" "}
                                                  </span>
                                                ) : (
                                                  <span>
                                                    *
                                                    {_t(
                                                      "Bank Account Used For Scoring"
                                                    )}{" "}
                                                  </span>
                                                )}
                                              </ul>
                                            </div>
                                            <div className="scorebtnbar">
                                              <button
                                                className="lleftbarbtn btnorangetextonly"
                                                type="button"
                                                onClick={bankhandleShow}
                                              >
                                                <FaRotate />
                                                {_t("Re-generate Skor")}{" "}
                                              </button>
                                              {/* <button
                                              className="rytbarbtn btnorangetextonly"
                                              type="button"
                                              onClick={handleScrollUploadDoc}
                                            >
                                              <FiUpload />
                                              {_t("Upload Documents")}{" "}
                                            </button> */}
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                <Col xl={6} className="mb-3">
                                  <div className="border-pd accordion-tile">
                                    <h4>
                                      {_t("Connect Bank or Add Guarantor ")}
                                    </h4>
                                    <div className="process-bar-wappers process2 ">
                                      <>
                                        <div className="vreify-box">
                                          <div className="verify-detail"></div>
                                          <div className="verify-btn">
                                            <button
                                              className="btn btnorange w-100"
                                              onClick={bankhandleShow}
                                              type="button"
                                            >
                                              <CiBank /> {_t("Connect Bank")}
                                            </button>
                                          </div>
                                          {haveValue(user?.gurantor_email) ? (
                                            <div className="whenaddgurantor">
                                              <h3>
                                                <svg
                                                  width="14"
                                                  height="14"
                                                  viewBox="0 0 14 14"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M7 0C3.15 0 0 3.15 0 7C0 10.85 3.15 14 7 14C10.85 14 14 10.85 14 7C14 3.15 10.85 0 7 0ZM5.6 10.5L2.1 7L3.087 6.013L5.6 8.519L10.913 3.206L11.9 4.2L5.6 10.5Z"
                                                    fill="#5CBA5F"
                                                  />
                                                </svg>
                                                {_t("Guarantor Added")}
                                              </h3>
                                              {haveValue(
                                                user?.gurantor_id
                                              ) ? null : (
                                                <h5>
                                                  {_t(
                                                    "Waiting for guarantor to accept your request"
                                                  )}
                                                </h5>
                                              )}
                                            </div>
                                          ) : (
                                            <div className="Guarantorbar">
                                              <div className="divider">
                                                {_t("OR")}
                                              </div>
                                              <button
                                                className="btn btnoutline"
                                                onClick={handleGurantorModal}
                                              >
                                                {" "}
                                                {_t("Add Guarantor")}
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                        <div className="scorebtnbar">
                                          {/* <button
                                              className="lleftbarbtn btnorangetextonly"
                                              type="button"
                                            >
                                            </button> */}
                                          <span className="connect_bank_infotext">
                                            {_t("Without an EU bank account?")}
                                          </span>
                                          <button
                                            className="rytbarbtn btnorangetextonly"
                                            type="button"
                                            // onClick={e => docRef.current.scrollIntoView({
                                            //   behavior: "smooth",
                                            //   block: "start"
                                            // })}
                                            onClick={handleScrollUploadDoc}
                                          >
                                            <FiUpload />
                                            {_t("Upload Documents")}{" "}
                                          </button>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </Col>
                              )}
                              { }
                              <Col xl={6} className="mb-3">
                                <div className="score-summary border-pd prime-summary-sec accordion-tile">
                                  <div>
                                    <h4 className="">{_t("Summary")}</h4>
                                    <div className="summary-checkes">
                                      <label className="rounded-check summaryinfobar padding10">
                                        {user?.has_profile == true ? (
                                          <img src={tickNew} alt="" />
                                        ) : (
                                          <img src={crossNew} alt="" />
                                        )}
                                        {_t("Completed Profile")}
                                      </label>
                                      <label className="rounded-check summaryinfobar flexbarinner">
                                        <div className="d-flex items-center justify-content-start gap-2">
                                          {ident_all == true ? (
                                            <img src={tickNew} alt="" />
                                          ) : user?.onfido_user_kyc
                                            ?.onfido_doc &&
                                            user?.onfido_user_kyc
                                              ?.onfido_workflow_res?.status !=
                                            "declined" ? (
                                            <img src={exclamation} alt="" />
                                          ) : (
                                            <img src={crossNew} alt="" />
                                          )}
                                          {_t("Verified identification")}
                                        </div>
                                        {user?.onfido_kyc_success == false &&
                                          user?.onfido_user_kyc?.onfido_doc &&
                                          user?.onfido_user_kyc
                                            ?.onfido_workflow_res?.status !=
                                          "declined" && (
                                            <ul className="infodorpdown summarydropdum">
                                              <li>
                                                <button className="iconinfo">
                                                  <MdInfoOutline />
                                                </button>{" "}
                                              </li>
                                              <ul className="tooltip">
                                                <p>
                                                  {_t(
                                                    "Verification in process"
                                                  )}
                                                </p>
                                              </ul>
                                            </ul>
                                          )}
                                        {user?.onfido_kyc_success == false &&
                                          !haveValue(
                                            user?.onfido_user_kyc?.onfido_doc
                                          ) && (
                                            <ul className="infodorpdown summarydropdum">
                                              <li>
                                                <button className="iconinfo">
                                                  <MdInfoOutline />
                                                </button>{" "}
                                              </li>
                                              <ul className="tooltip">
                                                <li>
                                                  {_t(
                                                    "You haven’t completed your digital verification yet"
                                                  )}
                                                  .
                                                </li>
                                              </ul>
                                            </ul>
                                          )}
                                        {user?.onfido_user_kyc?.onfido_doc &&
                                          user?.onfido_user_kyc
                                            ?.onfido_workflow_res?.status !=
                                          "declined" && (
                                            <Col
                                              md={6}
                                              className="d-flex gap-2 align-items-center justify-content-end"
                                            >
                                              <button
                                                type="button"
                                                href={undefined}
                                                onClick={handleViewDocument}
                                                className="rounded-check summaryinfobar btn btngreen view_doc_btn"
                                                id="viewDocumentBtn"
                                              // className="btn btngreen"
                                              >
                                                {_t("View Document")}
                                              </button>
                                              {showSpinner && (
                                                <Spinner className="view_document_spinner" />
                                              )}
                                            </Col>
                                          )}
                                        {(!haveValue(
                                          user?.onfido_kyc_success
                                        ) ||
                                          user?.onfido_kyc_success == false) &&
                                          !haveValue(
                                            user?.onfido_user_kyc?.onfido_doc
                                          ) ? (
                                          <Col
                                            md={6}
                                            className="d-flex gap-2 align-items-center justify-content-end"
                                          >
                                            {/* <div className="verify-btn"> */}
                                            <button
                                              className="btn btnorange w-100 view_doc_btn"
                                              type="button"
                                              disabled={buttonDisable}
                                              onClick={initiateOnfido}
                                              id="verify_now_btn"
                                            >
                                              {_t("Verify Now")}
                                              <MdOutlineVerified />
                                            </button>
                                            {/* </div> */}
                                            {showSpinner && (
                                              <Spinner className="view_document_spinner" />
                                            )}
                                          </Col>
                                        ) : (
                                          <></>
                                        )}
                                      </label>
                                      <label className="rounded-check summaryinfobar padding10">
                                        {haveValue(user?.gurantor_id) ? (
                                          haveValue(
                                            user?.gurantor_id?.paymentScores
                                          ) ? (
                                            <img src={tickNew} alt="" />
                                          ) : (
                                            <img src={crossNew} alt="" />
                                          )
                                        ) : haveValue(user?.paymentScores) ? (
                                          <img src={tickNew} alt="" />
                                        ) : (
                                          <img src={crossNew} alt="" />
                                        )}
                                        {_t("Verified bank account")}
                                      </label>
                                      <label className="rounded-check summaryinfobar padding10">
                                        {recordedSalary?.matched >= 80 ? (
                                          <img src={tickNew} alt="" />
                                        ) : (
                                          <img src={crossNew} alt="" />
                                        )}
                                        {_t("Verified income")}
                                        {recordedSalary?.matched < 80 ? (
                                          <ul className="infodorpdown summarydropdum">
                                            <li>
                                              <button className="iconinfo">
                                                <MdInfoOutline />
                                              </button>{" "}
                                            </li>
                                            <ul className="tooltip">
                                              <li>
                                                <p>
                                                  {_t("Income ")}{" "}
                                                  {recordedSalary?.matched}{" "}
                                                  {_t(
                                                    "% Match with the Annual Income recorded from your bank account"
                                                  )}
                                                </p>
                                                <button
                                                  className="btnupdate"
                                                  onClick={bankhandleShow}
                                                >
                                                  <BsBank2 />
                                                  {_t("Update Bank")}
                                                </button>
                                              </li>
                                            </ul>
                                          </ul>
                                        ) : (
                                          (haveValue(
                                            user?.gurantor_id
                                              ?.current_annual_income
                                          ) ||
                                            haveValue(
                                              user?.current_annual_income
                                            )) && (
                                            <ul className="infodorpdown summarydropdum">
                                              <li>
                                                <button className="iconinfo">
                                                  <MdInfoOutline />
                                                </button>{" "}
                                              </li>
                                              <ul className="tooltip">
                                                <li>
                                                  <p>
                                                    {/* {_t("Annual Income - ")}{Number(recordedSalary?.salary).toLocaleString()} */}
                                                    {_t("Income ")}{" "}
                                                    {recordedSalary?.matched}{" "}
                                                    {_t(
                                                      "% Match with the Annual Income recorded from your bank account"
                                                    )}
                                                  </p>
                                                </li>
                                              </ul>
                                            </ul>
                                          )
                                        )}
                                      </label>
                                      {user?.have_gurantor &&
                                        user?.have_gurantor == "true" && (
                                          <label className="rounded-check summaryinfobar padding10">
                                            {haveGuarantor == true ||
                                              haveGuarantor == "true" ? (
                                              <img src={tickNew} alt="" />
                                            ) : (
                                              <img src={crossNew} alt="" />
                                            )}
                                            {_t("Guarantor")}
                                            <ul className="infodorpdown summarydropdum">
                                              <li>
                                                <button className="iconinfo">
                                                  <MdInfoOutline />
                                                </button>{" "}
                                              </li>
                                              <ul className="tooltip">
                                                <p>
                                                  {haveValue(
                                                    user?.gurantor_id
                                                  ) &&
                                                    haveValue(
                                                      user?.gurantor_id
                                                        ?.paymentScores
                                                    )
                                                    ? _t(
                                                      "The score you see is of the Guarantor"
                                                    )
                                                    : _t(
                                                      "Guarantor added. Their score will be visible after bank details are provided."
                                                    )}
                                                </p>
                                              </ul>
                                            </ul>
                                          </label>
                                        )}
                                      {haveValue(user?.ie_details_id) &&
                                        user?.is_university && (
                                          <label className="rounded-check summaryinfobar padding10">
                                            {checkIeDetails() ? (
                                              <img src={tickNew} alt="" />
                                            ) : (
                                              <img src={crossNew} alt="" />
                                            )}
                                            {_t("Verified")}
                                            <img
                                              src="/images/ie_logo.svg"
                                              height="30px"
                                              width="30px"
                                              alt=""
                                            />
                                          </label>
                                        )}
                                    </div>
                                  </div>
                                  {user?.onfido_user_kyc?.onfido_workflow_res
                                    ?.status &&
                                    user?.onfido_user_kyc?.onfido_workflow_res
                                      ?.status == "declined" && (
                                      <div className="gurantor_box kyc_failed_div">
                                        <p
                                          className="rounded-check set_gap_2"
                                          style={{ textAlign: "center" }}
                                        >
                                          <img src={crossNew} alt="" />
                                          {_t("Your identity verification failed")}!
                                        </p>
                                        <Row>
                                          <>
                                            <Col md={6}>
                                              {haveValue(
                                                user?.onfido_user_kyc
                                                  ?.onfido_error
                                              ) &&
                                                haveValue(
                                                  user?.onfido_user_kyc
                                                    ?.onfido_error[0]
                                                ) && (
                                                  <p
                                                    className="rounded-check"
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {
                                                      user?.onfido_user_kyc
                                                        ?.onfido_error[0]
                                                    }
                                                  </p>
                                                )}
                                            </Col>
                                            <Col md={6}>
                                              <p
                                                className="rounded-check"
                                                style={{
                                                  textAlign: "center",
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                <Link
                                                  to={undefined}
                                                  onClick={initiateOnfido}
                                                >
                                                  {_t("Click Here To Retry")}
                                                </Link>
                                              </p>
                                            </Col>
                                          </>
                                        </Row>
                                      </div>
                                    )}
                                </div>
                              </Col>

                              {/* {useMemo(
                                () => (
                                  <GaugeChart
                                    // style={{ height: "300px" }}
                                    id="gauge-chart1"
                                    nrOfLevels={420}
                                    arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
                                    colors={[
                                      "#5BE12C",
                                      "#F5CD19",
                                      "#EA4228",
                                      "#F5CD19",
                                      "#EA4228",
                                    ]}
                                    percent={0.4}
                                    arcPadding={0.02}
                                    animate={false}
                                  />
                                ),
                                []
                              )} */}

                              <Col xl={6} className="mb-3">
                                <div className="score-personal-info border-pd accordion-tile">
                                  <h4>{_t("Personal Info")}</h4>
                                  <Row className="mx-0">
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("Name")} :</h4>
                                        {user?.onfido_user_kyc?.onfido_doc_data
                                          ?.first_name ? (
                                          <h3>
                                            {`${user?.onfido_user_kyc?.onfido_doc_data?.first_name} ${user?.onfido_user_kyc?.onfido_doc_data?.last_name}`}{" "}
                                            <img src={tickNew} alt="" />
                                          </h3>
                                        ) : user?.fname ? (
                                          <h3>
                                            {`${user?.fname} ${user?.lname}`}{" "}
                                            {/* {checkName()} */}
                                          </h3>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("+Add Info")}
                                            </a>
                                          </h3>
                                        )}
                                        <h3></h3>
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("Nationality")}</h4>
                                        {user?.nationality ||
                                          haveValue(
                                            user?.onfido_user_kyc?.onfido_doc_data
                                              ?.nationality
                                          ) ? (
                                          <h3>
                                            {haveValue(
                                              user?.onfido_user_kyc
                                                ?.onfido_doc_data?.nationality
                                            ) ? (
                                              <>
                                                {alpha3ToName(
                                                  user?.onfido_user_kyc
                                                    ?.onfido_doc_data
                                                    ?.nationality
                                                )}{" "}
                                                <img src={tickNew} alt="" />
                                              </>
                                            ) : (
                                              _t(user?.nationality)
                                            )}
                                            {/* {checkNationality()} */}
                                          </h3>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("+Add Info")}
                                            </a>
                                          </h3>
                                        )}
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("Passport ID")}</h4>
                                        {user?.passport_id ||
                                          (haveValue(
                                            userKycInfo?.onfido_doc_data
                                              ?.document_number
                                          ) &&
                                            userKycInfo?.onfido_doc_data
                                              ?.document_type == "passport") ? (
                                          <h3>
                                            {haveValue(
                                              userKycInfo?.onfido_doc_data
                                                ?.document_number
                                            ) ? (
                                              <>
                                                {
                                                  userKycInfo?.onfido_doc_data
                                                    ?.document_number
                                                }
                                                <img src={tickNew} alt="" />
                                              </>
                                            ) : (
                                              user?.passport_id
                                            )}
                                            {/* {checkPassportKYC()} */}
                                          </h3>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("+Add Info")}
                                            </a>
                                          </h3>
                                        )}
                                        {/* <h3>{user?.passport_id} </h3> */}
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("DNI/NIE")}:</h4>
                                        {user?.dni_nie ||
                                          haveValue(
                                            userKycInfo?.onfido_doc_data
                                              ?.personal_number
                                          ) ? (
                                          <h3>
                                            {haveValue(
                                              userKycInfo?.onfido_doc_data
                                                ?.personal_number
                                            ) ? (
                                              <>
                                                {" "}
                                                {
                                                  userKycInfo?.onfido_doc_data
                                                    ?.personal_number
                                                }
                                                <img src={tickNew} alt="" />
                                              </>
                                            ) : (
                                              user?.dni_nie
                                            )}
                                            {/* {checkDNIKyc()} */}
                                          </h3>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("+Add Info")}
                                            </a>
                                          </h3>
                                        )}
                                        {/* <h3>{user?.dni_nie}</h3> */}
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("Phone Number")}</h4>
                                        {user?.phone_number ? (
                                          <h3>{user?.phone_number}</h3>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("+Add Info")}
                                            </a>
                                          </h3>
                                        )}
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("Flatmates")}:</h4>
                                        {haveValue(user?.sharers) ? (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate(
                                                  "/edit-profile/tenant#add_flatmate_id"
                                                )
                                              }
                                            >
                                              {user?.sharers.length}{" "}
                                              {_t("Flatmates")}
                                            </a>
                                          </h3>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("+Add Flatmates")}
                                            </a>
                                          </h3>
                                        )}
                                      </div>
                                    </Col>
                                    <Col md={12}>
                                      <div className="Score-content-list">
                                        <h4>{_t("Email")}</h4>
                                        {user?.email ? (
                                          <div className="email_flex">
                                            <h3>{user?.email}</h3>
                                            <img src={tickNew} alt="" />
                                          </div>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("+Add Info")}
                                            </a>
                                          </h3>
                                        )}
                                        {/* <h3>{user?.email} </h3> */}
                                      </div>
                                    </Col>
                                    <div className="gurantor_box">
                                      <h4>{_t("Guarantor Details")}</h4>
                                      <Row>
                                        {user?.have_gurantor == "true" &&
                                          haveValue(user?.gurantor_name) &&
                                          haveValue(user?.gurantor_email) ? (
                                          <>
                                            <Col md={6}>
                                              <div className="Score-content-list">
                                                <h4 className="d-flex align-items-center gap-1">
                                                  {_t("Name Of Guarantor")}
                                                  {!haveValue(
                                                    user?.gurantor_id
                                                  ) &&
                                                    haveValue(
                                                      user?.gurantor_email
                                                    ) && (
                                                      <label className="">
                                                        <ul className="infodorpdown summarydropdum position-relative">
                                                          <li>
                                                            <button className="iconinfo guarantor_tooltip">
                                                              <MdInfoOutline />
                                                            </button>{" "}
                                                          </li>
                                                          <ul className="tooltip">
                                                            <li>
                                                              {_t(
                                                                "Waiting for Guarantor to accept your request"
                                                              )}
                                                              .
                                                            </li>
                                                          </ul>
                                                        </ul>
                                                      </label>
                                                    )}
                                                </h4>

                                                {user?.gurantor_name ? (
                                                  <>
                                                    <h3>
                                                      {user?.gurantor_name}
                                                    </h3>
                                                  </>
                                                ) : (
                                                  <h3>{_t("No Guarantor")}</h3>
                                                )}
                                              </div>
                                            </Col>

                                            <Col md={6}>
                                              <div className="Score-content-list">
                                                <h4 className="d-flex align-items-center">
                                                  {_t("Guarantor Email")}:
                                                </h4>
                                                {user?.gurantor_email ? (
                                                  <h3>
                                                    {user?.gurantor_email}{" "}
                                                    {/* <span>{checkAddress()}</span> */}
                                                  </h3>
                                                ) : (
                                                  <h3 className="setNoInfoColor">
                                                    <a
                                                      href={undefined}
                                                      onClick={(e) =>
                                                        navigate(
                                                          "/edit-profile/tenant"
                                                        )
                                                      }
                                                    >
                                                      {_t("+Add Info")}
                                                    </a>
                                                  </h3>
                                                )}
                                              </div>
                                            </Col>
                                          </>
                                        ) : haveValue(user?.gurantor_for) &&
                                          haveValue(user?.gurantor_for?._id) ? (
                                          <Col md={6}>
                                            <div className="Score-content-list">
                                              <h4>{_t("Guarantor Of")}:</h4>
                                              {user?.gurantor_for?.fname ? (
                                                <h3>
                                                  {user?.gurantor_for?.fname}{" "}
                                                  {user?.gurantor_for?.lname}
                                                  <a
                                                    href={undefined}
                                                    onClick={
                                                      handleRemoveGuarantor
                                                    }
                                                  >
                                                    <svg
                                                      width="7"
                                                      height="13"
                                                      viewBox="0 0 7 13"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M1 12L6 6.5L1 0.999999"
                                                        stroke="#EF7833"
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                      />
                                                    </svg>
                                                  </a>
                                                  {/* <span>{checkAddress()}</span> */}
                                                </h3>
                                              ) : (
                                                <h3 className="setNoInfoColor">
                                                  <a
                                                    href={undefined}
                                                    onClick={(e) =>
                                                      navigate(
                                                        "/edit-profile/tenant"
                                                      )
                                                    }
                                                  >
                                                    {_t("+Add Info")}
                                                  </a>
                                                </h3>
                                              )}
                                            </div>
                                          </Col>
                                        ) : (
                                          <Col md={6}>
                                            <a
                                              href={undefined}
                                              className="setNoInfoColor"
                                              onClick={handleGurantorModal}
                                            >
                                              +{_t("Add Guarantor")}
                                            </a>
                                          </Col>
                                        )}
                                      </Row>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                              <Col xl={6} className="mb-3">
                                <div className="score-professional border-pd accordion-tile">
                                  <h4>{_t("Professional and Academic")} </h4>
                                  <Row className="mx-0">
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("Employment Status")}:</h4>
                                        {user?.employment_status ? (
                                          <h3>{getEmploymentStatus()}</h3>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("+Add Info")}
                                            </a>
                                          </h3>
                                        )}
                                      </div>
                                    </Col>
                                    {user?.employment_status ===
                                      constants.self_employed && (
                                        <>
                                          <Col md={6}>
                                            <div className="Score-content-list">
                                              <h4>{_t("Employment Type")}:</h4>
                                              {haveValue(user?.is_freelancer) ? (
                                                <h3>
                                                  {user?.is_freelancer == true
                                                    ? _t("Freelancer")
                                                    : _t("Own a Company")}
                                                </h3>
                                              ) : (
                                                <h3 className="setNoInfoColor">
                                                  <a
                                                    href={undefined}
                                                    onClick={(e) =>
                                                      navigate(
                                                        "/edit-profile/tenant"
                                                      )
                                                    }
                                                  >
                                                    {_t("+Add Info")}
                                                  </a>
                                                </h3>
                                              )}
                                            </div>
                                          </Col>
                                          {user?.is_freelancer == false && (
                                            <Col md={6}>
                                              <div className="Score-content-list">
                                                <h4>{_t("Company Name")}:</h4>
                                                {user?.name_of_company ? (
                                                  <h3>{user?.name_of_company}</h3>
                                                ) : (
                                                  <h3 className="setNoInfoColor">
                                                    <a
                                                      href={undefined}
                                                      onClick={(e) =>
                                                        navigate(
                                                          "/edit-profile/tenant"
                                                        )
                                                      }
                                                    >
                                                      {_t("+Add Info")}
                                                    </a>
                                                  </h3>
                                                )}
                                              </div>
                                            </Col>
                                          )}
                                        </>
                                      )}
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("Highest Education")}:</h4>
                                        {user?.type_of_student ? (
                                          <h3>{_t(user?.type_of_student)}</h3>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("+Add Info")}
                                            </a>
                                          </h3>
                                        )}
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("University")}:</h4>
                                        {user?.name_of_university ? (
                                          <h3>{user?.name_of_university}</h3>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("+Add Info")}
                                            </a>
                                          </h3>
                                        )}
                                      </div>
                                    </Col>
                                    {user?.employment_status ===
                                      constants.employed && (
                                        <>
                                          <Col md={6}>
                                            <div className="Score-content-list">
                                              <h4>{_t("Position")}:</h4>
                                              <h3>{user?.position} </h3>
                                            </div>
                                          </Col>
                                          <Col md={6}>
                                            <div className="Score-content-list">
                                              {fetchCompanyName()}
                                              {/* {user?.name_of_company}{" "}
                                              <span>{checkCompanyname()}</span>{" "} */}
                                            </div>
                                          </Col>
                                          <Col md={6}>
                                            <div className="Score-content-list">
                                              <h4>
                                                {_t("Email Of Previous Employer")}
                                                :
                                              </h4>
                                              <h3>
                                                {user?.email_previous_employer}{" "}
                                              </h3>
                                            </div>
                                          </Col>
                                        </>
                                      )}

                                    <Col md={6}>
                                      <div className="Score-content-list">
                                        <h4>{_t("LinkedIn Profile")}:</h4>
                                        {user?.linkedin_profile ? (
                                          <h3>{user?.linkedin_profile}</h3>
                                        ) : (
                                          <h3 className="setNoInfoColor">
                                            <a
                                              href={undefined}
                                              onClick={(e) =>
                                                navigate("/edit-profile/tenant")
                                              }
                                            >
                                              {_t("+Add Info")}
                                            </a>
                                          </h3>
                                        )}
                                        {/* <h3>{user?.linkedin_profile} </h3> */}
                                      </div>
                                    </Col>

                                    <div className="row incomes_main_div p-3">
                                      <h4 style={{ textAlign: "left" }}>
                                        {_t("Income Details")}
                                      </h4>
                                      <Col md={6}>
                                        <div className="Score-content-list">
                                          <h4>{_t("Declared income")}:</h4>
                                          {user?.current_annual_income ? (
                                            <h3>
                                              €
                                              {convertCurrencyFormat(
                                                user?.current_annual_income
                                              )}
                                            </h3>
                                          ) : (
                                            <h3 className="setNoInfoColor">
                                              <a
                                                href={undefined}
                                                onClick={(e) =>
                                                  navigate(
                                                    "/edit-profile/tenant"
                                                  )
                                                }
                                              >
                                                {_t("+Add Info")}
                                              </a>
                                            </h3>
                                          )}
                                          {/* <h3>{user?.current_annual_income} P.A.</h3> */}
                                        </div>
                                      </Col>


                                      <Col md={6}>
                                        <div className="Score-content-list">
                                          <h4>
                                            {_t("Verified Income From Bank")}:
                                          </h4>
                                          {haveValue(recordedSalary?.salary) && recordedSalary?.salary ? (
                                            <h3>
                                              €
                                              {convertCurrencyFormat(
                                                recordedSalary?.salary
                                              )}
                                              <img src={tickNew} alt="" />
                                            </h3>
                                          ) : (
                                            <h3 className="setNoInfoColor">
                                              {_t("No Info")}
                                            </h3>
                                          )}
                                        </div>
                                      </Col>



                                      <Col md={6}>
                                        <div className="Score-content-list">
                                          <h4>
                                            {_t("Verified Income from IRPF")}:
                                          </h4>
                                          {
                                            haveValue(user?.itr_status) ?
                                              user?.itr_status == "success" ? (
                                                <>
                                                  <h3>€{convertCurrencyFormat(user?.itr_amount)} <img src={tickNew} alt="" /></h3>
                                                  <img src={tickNew} alt="" />
                                                </>
                                              ) : user?.itr_status == "pending" ? (<h3>{_t("In Process")}</h3>) : (<h3>{_t("Unable To Fetch The Amount")}</h3>
                                              )
                                              : <></>
                                          }

                                        </div>
                                      </Col>


                                      {checkPayslipData()}
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                show={Bankshow}
                onHide={BankhandleClose}
                centered
                className="newmodalui connectbankmodal"
                size="lg"
              >
                {/* <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}
                <Modal.Body className="p-0">
                  <div className="row">
                    <div className="col-sm-6 p-2 bankimagediv">
                      <p>
                        {_t(
                          "You will now be redirected to connect your bank account through our partner - Tink, powered by Visa."
                        )}
                      </p>
                      <p>
                        {_t(
                          "It's important for you to know that we do not have direct access to your bank login details. This process is designed to mantain your credentials encripted from Skor or any other third party"
                        )}
                      </p>
                      <img src="../images/connect_bank.svg" alt="bank_logo" />
                      <p>
                        {_t(
                          "We assure you of the utmost privacy and security of your financial data."
                        )}
                      </p>
                    </div>
                    <div className="col-sm-6 p-2 connectbankdiv">
                      <h3>{_t("Connect Bank")}</h3>
                      <Form className="loginform">
                        <div className="auth-form">
                          <div className="forminput row100">
                            <label className="forminput">{_t("Country")}</label>
                            <select
                              className="form-control"
                              onChange={changeCountryCode}
                            >
                              <option value="">
                                {_t("Select your country")}
                              </option>
                              <option value="BE">{_t("Belgium")}</option>
                              <option value="DE">{_t("Germany")}</option>
                              <option value="DK">{_t("Denmark")}</option>
                              <option value="EE">{_t("Estonia")}</option>
                              <option value="ES">{_t("Spain")}</option>
                              <option value="FI">{_t("Finland")}</option>
                              <option value="FR">{_t("France")}</option>
                              <option value="GB">{_t("United Kingdom")}</option>
                              <option value="IT">{_t("Italy")}</option>
                              <option value="LT">{_t("Lithuania")}</option>
                              <option value="LV">{_t("Latvia")}</option>
                              <option value="NL">{_t("Netherlands")}</option>
                              <option value="NO">{_t("Norway")}</option>
                              <option value="PL">{_t("Poland")}</option>
                              <option value="PT">{_t("Portugal")}</option>
                              <option value="SE">{_t("Sweden")}</option>
                            </select>
                          </div>
                          <div className="forminput row100">
                            <label className="forminput">
                              {_t("Connect Bank")}
                            </label>

                            <div className="bankname" onClick={connectToBank}>
                              <BsBank2 />
                              {_t("Connect bank")}
                            </div>
                            <span className="addRedColor">
                              {countryCodeError}
                            </span>
                          </div>
                        </div>
                      </Form>
                      <div className="bank-btm">
                        {_t(
                          "Should you have any concerns or questions please "
                        )}
                        <a
                          href={undefined}
                          onClick={(e) => navigate("/bank")}
                          className="btnlink"
                        >
                          {_t("read here")}
                        </a>
                        {_t(" or contact us at ")}
                        <a
                          href="mailTo:info@skortorent.com"
                          className="btnlink"
                        >
                          info@skortorent.com.
                        </a>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                show={showGurantor}
                onHide={handleHideGurantor}
                centered
                className="newmodalui"
              >
                <Modal.Header closeButton>
                  <Modal.Title>{_t("Add Guarantor")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={handleSubmitGurantor}>
                    <div className="auth-form">
                      <div className="forminput row100">
                        <label className="forminput">
                          {_t("Name Of Guarantor")}
                        </label>
                        <input
                          type="text"
                          name="gurantor_name"
                          placeholder="Name Of Guarantor"
                          onChange={(e) =>
                            setGuarantorData((prevData) => ({
                              ...prevData,
                              gurantor_name: e.target.value,
                            }))
                          }
                          value={guarantorData.gurantor_name}
                        />
                      </div>
                      <div className="forminput row100">
                        <label className="forminput">
                          {_t("Email Of Guarantor")}
                        </label>
                        <input
                          type="text"
                          name="gurantor_email"
                          placeholder="Email Of Guarantor"
                          onChange={(e) =>
                            setGuarantorData((prevData) => ({
                              ...prevData,
                              gurantor_email: e.target.value?.toLowerCase(),
                            }))
                          }
                          value={guarantorData.gurantor_email}
                        />
                      </div>
                      <div className="btngroup row100 gridbar">
                        <button
                          className="btn btnoutline"
                          type="button"
                          onClick={handleHideGurantor}
                        >
                          {_t("Cancel")}
                        </button>
                        <button
                          className="btn btnorange"
                          type="submit"
                          disabled={
                            haveValue(guarantorData.gurantor_name) &&
                              haveValue(guarantorData.gurantor_email)
                              ? false
                              : true
                          }
                        >
                          {_t("Submit")}
                        </button>
                      </div>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
              {showConfirmGuarantor && (
                <AcceptGuarantor
                  setShowConfirmGuarantor={setShowConfirmGuarantor}
                  showConfirmGuarantor={showConfirmGuarantor}
                />
              )}
              {alreadyGurantorOf && gurantorPresent == false && (
                <AlreadyGuarantorOf
                  alreadyGurantorOf={alreadyGurantorOf}
                  setAlreadyGurantorOf={setAlreadyGurantorOf}
                  gurantorPresent={gurantorPresent}
                  setGurantorPresent={setGurantorPresent}
                />
              )}
              {alreadyGurantorOf && gurantorPresent == true && (
                <SomeoneGuarantor
                  alreadyGurantorOf={alreadyGurantorOf}
                  setAlreadyGurantorOf={setAlreadyGurantorOf}
                  gurantorPresent={gurantorPresent}
                  setGurantorPresent={setGurantorPresent}
                />
              )}
              {alreadyGurantorTo && (
                <AlreadyGuarantorTo
                  alreadyGurantorTo={alreadyGurantorTo}
                  setAlreadyGurantorTo={setAlreadyGurantorTo}
                  gurantor_details={user?.gurantor_for}
                />
              )}
              {removeGuarantorModel == true && (
                <Modal
                  show={removeGuarantorModel}
                  onHide={hideRemoveGuarantor}
                  centered
                  className="newmodalui asguarantor modal-lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {_t("You Are Guarantor Of ")}
                      {`${user?.gurantor_for?.fname} ${user?.gurantor_for?.lname}`}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={submitRemoveGuarantor}>
                      <div className="auth-form">
                        {/* <div className="formtile">
                  <h4>{`${user?.gurantor_for?.fname} ${user?.gurantor_for?.lname}`}<span> {_t("Basic Profile")}</span></h4>
                </div> */}
                        <div className="row">
                          <div className="col-lg-6 col-md-12 mb-4">
                            <label className="forminput">{_t("Name")}:</label>
                            <p>
                              {user?.gurantor_for?.fname}{" "}
                              {user?.gurantor_for?.lname}
                            </p>
                          </div>
                          <div className="col-lg-6 col-md-12 mb-4">
                            <label className="forminput">
                              {_t("Nationality")}:
                            </label>
                            <p>{_t(user?.gurantor_for?.nationality)}</p>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <label className="forminput">
                              {_t("Phone Number")}:
                            </label>
                            <p>
                              {haveValue(user?.gurantor_for?.phone_number)
                                ? user?.gurantor_for?.phone_number
                                : _t("No Info")}
                            </p>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <label className="forminput">{_t("Email")}:</label>
                            <p>{user?.gurantor_for?.email}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row100">
                        <button className="btn-reject" type="submit">
                          {_t("Remove Yourself as a Guarantor")}
                        </button>
                      </div>
                    </form>
                  </Modal.Body>
                </Modal>
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1" ref={docRef} id="accordion_item_1">
            <Accordion.Header className="primaryaccordion-header setaccordionhead sdv">
              <div className="d-flex justify-content-between w-100">
                <span className="maintitle">
                  {_t("Documents")}{" "}
                  <label className="">
                    <ul className="infodorpdown summarydropdum position-relative">
                      <li>
                        <button className="iconinfo guarantor_tooltip">
                          <MdInfoOutline />
                        </button>{" "}
                      </li>
                      <ul className="tooltip">
                        <li>{_t("Pdf files only")}</li>
                        <li>{_t("Each file size 10 MB max")}</li>
                        <li>{_t("10 documents max")}</li>
                      </ul>
                    </ul>
                  </label>
                </span>
                {currentActiveAccordion.toString() != "1" && (
                  <span className="submain">
                    {Array.isArray(tenantDocsData)
                      ? `${tenantDocsData.length} ${_t("Documents")}`
                      : "  "}
                  </span>
                )}
              </div>
            </Accordion.Header>
            <Accordion.Body className="primaryaccordion-body">
              {/* <h4 className="accordion-title">{_t("Select Document Type")}</h4> */}
              {/* <Stack direction="horizontal" gap={2}>
                <Form.Select
                  size="lg"
                  onChange={(e) => {
                    handleSelectChange(e);
                  }}
                  value={fileType}
                >
                  <option value="" hidden>
                    {_t("Select")}
                  </option>

                  {user?.employment_status == constants.student && (
                    <>
                      <option value={constants.national_id}>
                        {_t("National Id")}
                      </option>
                      <option value={constants.ie_student}>
                        {_t("Student registration contract")}
                      </option>
                      <option value={constants.university_acceptance_letter}>
                        {_t("University Acceptance Letter")}
                      </option>
                      <option value={constants.guarantor}>
                        {_t("Guarantor")}
                      </option>
                      <option value={constants.ie_student}>
                        {_t("Student registration contract")}
                      </option>
                    </>
                  )}

                  {user?.employment_status == constants.employed && (
                    <>
                      <option value={constants.national_id}>
                        {_t("National Id")}
                      </option>
                      <option value={constants.employment_contract}>
                        {_t("Employment Contract")}
                      </option>
                      <option value={constants.payslips}>
                        {_t("Last three payslips")}
                      </option>
                      <option
                        value={constants.previous_working_life_certificate}
                      >
                        {_t("Working life certificate")}
                      </option>
                      <option value={constants.income_tax_return}>
                        {_t("Last annual Tax Report (Model 100)")}
                      </option>
                    </>
                  )}

                  {user?.employment_status == constants.self_employed &&
                    user?.is_freelancer == true && (
                      <>
                        <option value={constants.national_id}>
                          {_t("National Id")}
                        </option>
                        <option value={constants.fractioned_tax_return}>
                          {_t("Last Fractionated Tax Report (Model 130)")}
                        </option>
                        <option value={constants.self_employed_certificate}>
                          {_t("Self-Employed Registration Certificate (Model 037)")}
                        </option>
                        <option value={constants.income_tax_return}>
                          {_t("Last annual Tax Report (Model 100)")}
                        </option>
                        <option
                          value={constants.previous_working_life_certificate}
                        >
                          {_t("Working life certificate")}
                        </option>
                        <option value={constants.payslips}>
                          {_t("Last three payslips")}
                        </option>
                      </>
                    )}

                  {user?.employment_status == constants.self_employed &&
                    user?.is_freelancer != true && (
                      <>
                        <option value={constants.national_id}>
                          {_t("National Id")}
                        </option>
                        <option value={constants.self_employed_certificate}>
                          {_t("Self-Employed Registration Certificate (Model 037)")}
                        </option>
                        <option
                          value={constants.previous_working_life_certificate}
                        >
                          {_t("Working life certificate")}
                        </option>
                        <option value={constants.payslips}>
                          {_t("Last three payslips")}
                        </option>
                        <option value={constants.income_tax_return}>
                          {_t("Last annual Tax Report (Model 100)")}
                        </option>
                        <option value={constants.fractioned_tax_return}>
                          {_t("Last Fractionated Tax Report (Model 130)")}
                        </option>
                        <option value={constants.corporate_tax_return}>
                          {_t("Last year's Corporate Tax Return")}
                        </option>
                        <option value={constants.corporate_balance_sheet}>
                          {_t("Last year's Corporate balance sheet")}
                        </option>
                        <option value={constants.corporate_PL}>
                          {_t("Last year's Corporate P&L")}
                        </option>
                      </>
                    )}

                  {
                    <option value="Other Documents">
                      {_t("Other Documents")}
                    </option>
                  }
                </Form.Select>
                <Button
                  onClick={() => {
                    handleShow();
                  }}
                  disabled={haveValue(fileType) ? false : true}
                >
                  {_t("Upload")}
                </Button>
              </Stack>
              <Modal
                className="modal-uploadpdf"
                show={show}
                onHide={handleClose}
              >
                <Modal.Header closeButton>{_t("Select Document")}</Modal.Header>
                <Modal.Body>
                  <Form.Group controlId="formFile" className="formcontrolbar">
                    <input
                      type="file"
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                      multiple={
                        fileType != constants.income_tax_return &&
                        fileType != constants.ie_student
                      }
                      accept="application/pdf"
                    />
                    <Button
                      className="btn-green"
                      onClick={handleFileUpload}
                      disabled={
                        haveValue(fileData) && !uploadLoading ? false : true
                      }
                    >
                      {uploadLoading ? _t("Please Wait") : _t("Upload")}
                    </Button>
                  </Form.Group>
                </Modal.Body>
              </Modal> */}
              <Row className="card-listing">
                {tenantDocsData?.map((eachData, index) => (
                  <Col md={3} key={eachData?._id}>
                    <Card>
                      {/* <Card.Img
                      variant="top"
                      src={`${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`}
                    /> */}
                      <div className="videoframe">
                        <iframe
                          src={`${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`}
                          scrolling="no"
                        />
                      </div>
                      <Card.Body>
                        <div className="listing-head">
                          <div className="head-block">
                            <div
                              className="leftbartext"
                              style={{ maxWidth: "85%" }}
                            >
                              <h5 style={{ paddingTop: "0px" }}>
                                {eachData?.type}
                              </h5>
                              <p>
                                {eachData?.file_name
                                  .split("-")
                                  .slice(1)
                                  .join("-")}
                              </p>
                            </div>
                            <VscEye
                              onClick={(e) =>
                                handlePreviewDocument(
                                  `${process.env.REACT_APP_SERVER_URL}/uploads/${eachData?.file_name}`
                                )
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                        <div className="listing-foot">
                          <span>
                            {moment(eachData?.createdAt).format("DD/MM/YYYY")}
                          </span>
                          {/* <span
                            className="del-icon"
                            onClick={(e) => {
                              setDeleteId(eachData?._id);
                              setDeleteDocumentModal(true);
                            }}
                          >
                            <RiDeleteBin6Line style={{ cursor: "pointer" }} />
                          </span> */}
                        </div>
                      </Card.Body>
                      {eachData?.from == "clave" ? (
                        <img
                          className="upload_doc_icons"
                          src="/images/downloaded_clave.svg"
                          alt=""
                        />
                      ) : (
                        <img
                          className="upload_doc_icons"
                          src="/images/downloaded_upload.svg"
                          alt=""
                        />
                      )}
                    </Card>
                  </Col>
                ))}
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <Modal
        show={deleteDocumentModal}
        className="metermodal"
        centered
        onHide={handleHideDocument}
      >
        <Modal.Body>
          {/* <h4>{_t("Your House Size")} {formData?.area} {_t("Meters, Is That Correct")}?</h4> */}
          <h4>
            {_t(
              "Are you sure you want to delete this document? Please note that this action cannot be undone."
            )}
          </h4>
          <div className="auth-form">
            <div className="btngroup row100 gridbar">
              <button
                className="btn btnoutline"
                type="button"
                onClick={(e) => {
                  handleHideDocument();
                }}
              >
                {_t("No")}
              </button>
              <button
                className="btn btngreen"
                onClick={(e) => handleDeleteDoc(deleteId)}
              >
                {_t("Yes")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TenantHome;
