import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";

import Homepage from "./pages/Homepage";
import { Fragment, useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import ProtectedRoute from "./pages/ProtectedRoute";
import ProtectedRouteLandlord from "./pages/ProtectedRouteLandlord";
import ProtectedRouteTenant from "./pages/ProtectedRouteTenant";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import { SnackbarProvider } from "material-ui-snackbar-provider";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ThemeProvider } from "./hooks/useThemeContext";

//state management context files
import AuthState from "./context/auth/AuthState";
import TenantState from "./context/tenant/TenantState";
import LandLordState from "./context/landlord/LandLordState";

//common routes
import Home from "./pages/Home";
import ForgotPassword from "./pages/ForgotPassword";
import Kyc from "./pages/kyc/Kyc";
import Finance from "./pages/tenant/finance/Finance";
import Offers from "./pages/common/offers/Offers";
import Notification from "./pages/common/notifications/Notification";

//landlord routes
import RentalProperty from "./pages/landlord/rental-property/RentalProperty";
import ProfileRequest from "./pages/landlord/profile-request/ProfileRequest";
import LandLordProfile from "./pages/landlord/landlord-profile/LandLordProfile";

//tenant routes
import ProfileShared from "./pages/tenant/profile-shared/ProfileShared";
import RentedProperty from "./pages/tenant/rented-property/RentedProperty";
import TenantProfile from "./pages/tenant/tenant-profile/TenantProfile";
import AddProperty from "./pages/landlord/property/AddProperty";
import EditProperty from "./pages/landlord/property/EditProperty";
import PropertyLink from "./pages/common/property-link/PropertyLink";
import ProfileAndProperty from "./pages/landlord/profie-property/ProfileAndProperty";
import TenantBasicProfile from "./pages/common/property-link/TenantBasicProfile";
import NotFoundPage from "./pages/NotFoundPage";
import EditTenant from "./pages/common/editProfile/EditTenant";
import EditFinancial from "./pages/common/editProfile/EditFinancial";
import EditLandLord from "./pages/common/editProfile/EditLandLord";
import LandLordDetailForTenant from "./pages/tenant/LandlordDetailForTenant";
import TenantScore from "./pages/tenant/TenantScore";
import RentPayment from "./pages/tenant/payments-emi/RentPayment";
import EmiDetails from "./pages/tenant/payments-emi/EmiDetails";
import ProfileSharing from "./pages/tenant/tenant-profile/ProfileSharing";
import SharingPropertyLink from "./pages/common/SharingPropertyLink/SharingPropertyLink";
import Admin from "./pages/admin/Admin";
import Shared from "./pages/shared/Shared";
import PaymentSetting from "./pages/PaymentSetting";
import UserRole from "./pages/UserRole";
import StepForm from "./pages/StepForm";
import AddPropertyNew from "./pages/landlord/property/AddPropertyNew";
import EnterOtp from "./pages/EnterOtp";
import BankingReadMore from "./pages/tenant/finance/BankingReadMore";
import AssignProperty from "./pages/landlord/property/AssignProperty";
import PropertyPaymentList from "./pages/landlord/property/PropertyPaymentList";
import PaymentDetails from "./pages/landlord/PaymentDetails";
import AcceptProperty from "./pages/tenant/payments-emi/AcceptProperty";
import PropertyPayment from "./pages/tenant/payments-emi/PropertyPayment";
import RentalReview from "./components/Tenant/RentalReview";
import IeLogin from "./pages/IeLogin";
import IeRegister from "./pages/IeRegister";
import StepFormUniversity from "./pages/StepFormUniversity";
import ProfilePreview from "./pages/ProfilePreview";
import TenantHomeNew from "./pages/tenant/tenant-home/TenantHomeNew";
import TenantHome from "./pages/tenant/TenantHome";
import TenantHomeLayout from "./layouts/Tenant/TenantHome";
import TenantCompleteProfile from "./pages/tenant/tenant-home/TenantCompleteProfile";
import TenantVerifyId from "./pages/tenant/tenant-home/TenantVerifyId";
import TenantBankConnect from "./pages/tenant/tenant-home/TenantBankConnect";
import TenantRentalStep from "./pages/tenant/tenant-home/TenantRentalStep";
import TenantProvideDocument from "./pages/tenant/tenant-home/TenantProvideDocument";
import TenantRentalPage from "./pages/tenant/tenant-home/TenantRentalPage";
import RedirectPage from "./pages/RedirectPage";

function App() {
  useEffect(() => { }, []);
  return (
    <AuthState>
      <LandLordState>
        <TenantState>
          <Fragment>
            <ThemeProvider>
              <ToastContainer />
              <SnackbarProvider SnackbarProps={{ autoHideDuration: 30000 }}>
                <Routes>
                  <Route path="/userrole" element={<UserRole />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/university/ie/login" element={<IeLogin />} />
                  <Route
                    path="/university/ie/register"
                    element={<IeRegister />}
                  />
                  <Route
                    path="/university/stepform"
                    element={<StepFormUniversity />}
                  />
                  <Route path="/forget-password" element={<ForgotPassword />} />
                  <Route path="/enter-otp" element={<EnterOtp />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/stepform" element={<StepForm />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/kyc" element={<Kyc />} />
                  <Route path="/propshare" element={<SharingPropertyLink />} />
                  <Route path="/finance" element={<Finance />} />
                  {/* <Route path='/admin' element={<Admin />} /> */}
                  <Route path="/shared" element={<Shared />} />
                  <Route
                    path="/bank"
                    element={<ProtectedRoute component={<BankingReadMore />} />}
                  />
                  {/* <Route path='/send-link' element={<PropertyLink />} /> */}
                  {/* nested routing here */}
                  <Route
                    path="/profile/preview/:UserId"
                    element={<ProtectedRoute component={<ProfilePreview />} />}
                  />
                  <Route path="/tenant" element={<TenantHomeLayout />}>
                    <Route
                      path=""
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<TenantHomeNew />}
                            />
                          }
                        />
                      }
                    />
                    <Route
                      path="/tenant/stepform"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<TenantCompleteProfile />}
                            />
                          }
                        />
                      }
                    />
                    <Route
                      path="/tenant/verifyId"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<TenantVerifyId />}
                            />
                          }
                        />
                      }
                    />
                    <Route
                      path="/tenant/connectBank"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<TenantBankConnect />}
                            />
                          }
                        />
                      }
                    />
                    <Route
                      path="/tenant/rentalStep"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<TenantRentalStep />}
                            />
                          }
                        />
                      }
                    />
                    <Route
                      path="/tenant/document"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<TenantProvideDocument />}
                            />
                          }
                        />
                      }
                    />
                    <Route
                      path="/tenant/review/rental"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<TenantRentalPage />}
                            />
                          }
                        />
                      }
                    />
                  </Route>
                  <Route path="/" element={<Homepage />}>
                    <Route
                      path=""
                      element={<ProtectedRoute component={<Home />} />}
                    />

                    <Route
                      path="home"
                      element={<ProtectedRoute component={<Home />} />}
                    />
                    {/* <Route
                      path="payment"
                      element={
                        <ProtectedRoute component={<PaymentSetting />} />
                      }
                    /> */}
                    <Route
                      path="profile-request"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteLandlord
                              component={<ProfileRequest />}
                            />
                          }
                        />
                      }
                    />
                    {/* <Route
                      path="rent-payment"
                      element={<ProtectedRoute component={<RentPayment />} />}
                    /> */}
                    {/* <Route
                      path="emi-details"
                      element={<ProtectedRoute component={<EmiDetails />} />}
                    /> */}
                    {/* <Route
                      path="rental-property"
                      element={
                        <ProtectedRoute component={<RentalProperty />} />
                      }
                    /> */}
                    <Route
                      path="landlord-profile"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteLandlord
                              component={<LandLordProfile />}
                            />
                          }
                        />
                      }
                    />
                    {/* <Route
                      path="add-property"
                      element={<ProtectedRoute component={<AddProperty />} />}
                    /> */}
                    {/* <Route
                      path="edit-property"
                      element={<ProtectedRoute component={<EditProperty />} />}
                    /> */}
                    <Route
                      path="tenant-profile"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<TenantProfile />}
                            />
                          }
                        />
                      }
                    />
                    <Route
                      path="profile-shared"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<ProfileShared />}
                            />
                          }
                        />
                      }
                    />
                    {/* <Route
                      path="shaing-property-link"
                      element={
                        <ProtectedRoute component={<SharingPropertyLink />} />
                      }
                    /> */}
                    <Route
                      path="rented-property"
                      element={
                        <ProtectedRoute component={<RentedProperty />} />
                      }
                    />
                    <Route
                      path="/edit-profile/tenant"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant component={<EditTenant />} />
                          }
                        />
                      }
                    />
                    <Route
                      path="/edit-profile/financial"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<EditFinancial />}
                            />
                          }
                        />
                      }
                    />
                    <Route
                      path="/edit-profile/landlord"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteLandlord
                              component={<EditLandLord />}
                            />
                          }
                        />
                      }
                    />
                    {/* <Route
                      path="/landlord-detail-tenant"
                      element={
                        <ProtectedRoute
                          component={<LandLordDetailForTenant />}
                        />
                      }
                    /> */}
                    <Route
                      path="/tenant-score"
                      element={<ProtectedRoute component={<TenantScore />} />}
                    />
                    <Route
                      path="/profile-sharing/:tenantid"
                      element={
                        <ProtectedRoute component={<ProfileSharing />} />
                      }
                    />
                    <Route
                      path="notification"
                      element={<ProtectedRoute component={<Notification />} />}
                    />
                    <Route
                      path="send-link"
                      element={<ProtectedRoute component={<PropertyLink />} />}
                    />
                    <Route
                      path="profile-property"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteLandlord
                              component={<ProfileAndProperty />}
                            />
                          }
                        />
                      }
                    />
                    {/* <Route
                      path="tenant-basic-profile/:notificationid"
                      element={
                        <ProtectedRoute component={<TenantBasicProfile />} />
                      }
                    /> */}
                    {/* <Route
                      path="admin"
                      element={<ProtectedRoute component={<Admin />} />}
                    /> */}
                    <Route
                      path="/assignProperty/:property_id"
                      element={
                        <ProtectedRoute component={<AssignProperty />} />
                      }
                    />
                    <Route
                      path="/payment/property/list"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteLandlord
                              component={<PropertyPaymentList />}
                            />
                          }
                        />
                      }
                    />
                    <Route
                      path="/awarded/property/:assignedPropId"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<PropertyPayment />}
                            />
                          }
                        />
                      }
                    />
                    <Route
                      path="/accept/assigned/property/:assignedPropertyId"
                      element={
                        <ProtectedRoute
                          component={
                            <ProtectedRouteTenant
                              component={<AcceptProperty />}
                            />
                          }
                        />
                      }
                    />
                    <Route
                      path="/payment/details/:assingedPropId"
                      element={
                        <ProtectedRoute component={<PaymentDetails />} />
                      }
                    />
                    <Route
                      path="/review/rental/:assignedPropertyId"
                      element={<ProtectedRoute component={<RentalReview />} />}
                    />
                    <Route path="offers" element={<Offers />} />
                    <Route
                      path="/landlord/add-property"
                      element={<AddPropertyNew />}
                    />
                  </Route>
                  <Route path="/redirect" element={<RedirectPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </SnackbarProvider>
            </ThemeProvider>
          </Fragment>
        </TenantState>
      </LandLordState>
    </AuthState>
  );
}

export default App;
