import React, { Fragment, useState, useContext, useEffect } from "react";
import AuthContext from "../context/auth/AuthContext";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "material-ui-snackbar-provider";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import OTPInput, { ResendOTP } from "otp-input-react";

import { FaArrowLeft } from "react-icons/fa6";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/esm/Container";
import { BsLinkedin } from "react-icons/bs";
import Loader from "../components/Loader";
import useTranslator from "../hooks/useTranslator";
import { useCookies } from "react-cookie";
import TenantContext from "../context/tenant/TenantContext";
import { haveValue, getBaseUrl } from "../helper/common";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import i18next from "i18next";
import { MdInfoOutline } from "react-icons/md";
import constants from "../Utils/constants";

const Register = () => {
  const { _t } = useTranslator();

  const [cookies, setCookie, removeCookie] = useCookies(["sharedProfileLink"]);

  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const authContext = useContext(AuthContext);
  const {
    register,
    user,
    otppage,
    setotpPage,
    loadUser,
    setUserType,
    sendOTP,
    sendOTPES,
    toggleLogin,
    toggleLogout,
  } = authContext;

  const queryParameters = new URLSearchParams(window.location.search);
  const logincode = queryParameters.get("code");
  const { hash } = useLocation();
  const user_type = localStorage.getItem("usertype");
  const tenantContext = useContext(TenantContext);
  const { shareProfile } = tenantContext;

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("i18nextLng").toLowerCase() || "en-us"
  );

  const handleLanguageChange = async (e) => {
    console.log(e.target.value);
    var langVal = e.target.value;
    await i18next.changeLanguage(langVal);
    setCurrentLang(langVal);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [showRePassword, setShowRePassword] = useState(false);
  const [rePasswordType, setRePasswordType] = useState("password");
  const [pwdErrorMsg, setpwdErrorMsg] = useState("");

  const [userRegister, setUserregister] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    type: "",
    checked: false,
    qrCode_signup: false,
    analysisCompleted: true,
    user_type:
      window.location.hostname == "macc.skortorent.com"
        ? constants.MACC
        : constants.SKOR,
  });

  useEffect(() => {
    if (hash === "#tenant") {
      setUserregister((state) => ({ ...state, type: "tenant" }));
      setOtp((prev) => ({ ...prev, type: "tenant" }));
    } else if (hash === "#landlord") {
      setUserregister((state) => ({ ...state, type: "landlord" }));
      setOtp((prev) => ({ ...prev, type: "landlord" }));
    } else {
      navigate("/userrole");
    }

    if (haveValue(cookies.sharedProfileLink) && user_type == "landlord") {
      setUserregister((prev) => ({ ...prev, qrCode_signup: true }));
    }
    if (haveValue(cookies.requestProfileLink) && user_type == "tenant") {
      setUserregister((prev) => ({ ...prev, qrCode_signup: true }));
    }
  }, []);

  useEffect(() => {
    if (otppage == 2) {
      console.log("IN OTP PAGE USEEFFECT");
      if (timer > 0) {
        console.log("timer started");
        let myInterval = setInterval(() => {
          if (timer > 0) {
            timer = timer - 1;
            setShowTimer(timer);
          }
          if (timer === 0) {
            clearInterval(myInterval);
            setShowResend(true);
          }
        }, 1000);
      }
    }
  }, [otppage]);

  const [loading, setLoading] = useState(false);
  let timer = 60;
  const [showTimer, setShowTimer] = useState();
  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  };

  const onChange = (e) => {
    setpwdErrorMsg("");
    if (e.target.name == "email")
      setUserregister({
        ...userRegister,
        [e.target.name]: e.target.value.toLowerCase(),
      });
    else setUserregister({ ...userRegister, [e.target.name]: e.target.value });
  };

  let strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );

  const onSubmit = (e) => {
    e.preventDefault();
    //console.log(userRegister);
    if (!userRegister.checked) {
      toast.error(_t("Please accept the term and conditions"));
    } else if (userRegister.email === "") {
      setpwdErrorMsg(_t("Please Enter A Valid Email Address"));
    } else if (userRegister.password === "") {
      setpwdErrorMsg(_t("Please Enter Password!"));
    } else if (userRegister.confirmPassword === "") {
      setpwdErrorMsg(_t("Please Enter Re-Enter Password!"));
    } else if (userRegister.password !== userRegister.confirmPassword) {
      setpwdErrorMsg(_t("Password Does Not Match"));
    } else if (!validateEmail(userRegister.email)) {
      setpwdErrorMsg(_t("Invalid email address"));
    } else if (!strongPassword.test(userRegister.password)) {
      setpwdErrorMsg(
        _t(
          "Please include Uppercase lowercase and special characters in your password and must be atleast 8 character long."
        )
      );
    } else {
      register(userRegister);
    }
  };

  const [otp, setOtp] = useState({
    type: "",
    otp: 0,
  });

  const [OTP, setOTP] = useState("");
  const otpinputChange = (e) => {
    setOTP(e);
    setOtp({ ...otp, otp: e });
  };

  const [showResend, setShowResend] = useState(false);

  useEffect(() => {
    console.log(OTP);
    if (OTP.length == 6) {
      //alert(otp.otp,"6 characters reached");
      let user_type = localStorage.getItem("usertype");
      verifyOTP(user?._id, otp);
    }

    if (logincode) {
      setLoading(true);
      setCounter(0);
      console.log(logincode);
      let user_type = localStorage.getItem("usertype");
      var qrCode_signup = false;
      if (haveValue(cookies.sharedProfileLink) && user_type == "landlord") {
        qrCode_signup = true;
      }
      if (haveValue(cookies.requestProfileLink) && user_type == "tenant") {
        qrCode_signup = true;
      }
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL
          }/users/linkedin-signup/${logincode}?usertype=${user_type}&qrCode_signup=${qrCode_signup}&user_type=${window.location.hostname == "macc.skortorent.com"
            ? constants.MACC
            : constants.SKOR
          }`,
          { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
        )
        .then(async (res) => {
          let serverResponse = res.data;
          console.log(serverResponse);
          localStorage.setItem("token", serverResponse.token);
          localStorage.setItem("is_verified", true);
          toggleLogin(true);
          await loadUser(user_type);
          setLoading(false);

          // if (user_type == 'tenant')
          if (haveValue(cookies.sharedProfileLink) && user_type == "landlord") {
            if (haveValue(res?.data?.user?._id)) {
              shareProfile(
                cookies.sharedProfileLink,
                res?.data?.user?._id,
                "null",
                "full",
                { shareType: "tLink" }
              )
                .then((res) => {
                  removeCookie("sharedProfileLink", { maxAge: 1800 });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
          navigate("/stepform");
          // else
          //   navigate("/home")
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          navigate("/login");
          console.log(err?.response, err?.response?.data?.errormsg);
          if (haveValue(err?.response?.data?.errormsg)) {
            toast.error(err?.response?.data?.errormsg);
          } else {
            toast.error(err.message);
          }
        });
    }
  }, [OTP]);

  const sendOtpAgain = () => {
    if (localStorage.getItem("i18nextLng") == "es")
      sendOTPES(user._id, otp.type);
    else sendOTP(user._id, otp.type);
    setShowResend(false);
  };

  const verifyOTP = async (userid, value) => {
    console.log(value);
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/users/otp-verify/${userid}`,
        value,
        { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
      )
      .then((res) => {
        console.log(res);
        let serverResponse = res;
        let usertype = localStorage.getItem("usertype");
        if (serverResponse.data.status === "verified") {
          snackbar.showMessage(_t("Account verified Successfully"));
          localStorage.setItem("is_verified", true);
          loadUser(usertype);
          // if (usertype == 'tenant')
          if (haveValue(cookies.sharedProfileLink) && usertype == "landlord") {
            if (haveValue(res?.data?.user?._id)) {
              shareProfile(
                cookies.sharedProfileLink,
                res?.data?.user?._id,
                "null",
                "full",
                { shareType: "tLink" }
              )
                .then((res) => {
                  removeCookie("sharedProfileLink", { maxAge: 1800 });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
          navigate("/stepform");
          // else
          //   navigate("/home")
          setotpPage(0);
        }
      })
      .catch((err) => {
        let serverResponse = err;
        console.log(serverResponse);
        toast.error(serverResponse.response.data.msg);
      });
  };

  const [counter, setCounter] = useState(1);

  const linkedinSignup = () => {
    console.log(userRegister.qrCode_signup);
    if (!userRegister.checked) {
      toast.error(_t("Please accept the term and conditions"));
      return false;
    }
    const baseUrl = getBaseUrl();
    // let live_url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=openid%20profile%20email&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=http://localhost:3000/register`;
    let live_url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=openid%20profile%20email&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=https://${process.env.REACT_APP_URL_NAME}/register`;

    // window.open(url,'_self');
    window.open(live_url, "_self");
  };
  const handleRedirectToLandingPage = () => {
    window.location.replace("https://skortorent.com/");
  };

  if (loading) return <Loader />;
  const handleShowPassword = (val) => {
    if (val) {
      setPasswordType("text");
      setShowPassword((oldVal) => !oldVal);
    } else {
      setPasswordType("password");
      setShowPassword((oldVal) => !oldVal);
    }
  };
  const handleShowRePassword = (val) => {
    if (val) {
      setRePasswordType("text");
      setShowRePassword((oldVal) => !oldVal);
    } else {
      setRePasswordType("password");
      setShowRePassword((oldVal) => !oldVal);
    }
  };
  const handleGoHome = () => {
    toggleLogout();
    setotpPage(0);
    navigate("/userrole");
  };

  if (otppage === 0) {
    return (
      <>
        <div className="Auth-wrapper">
          <img
            className="auth-shape-center"
            src="../images/LineAuth.svg"
            alt="logo"
          />

          <div className="Auth-bar">
            <div className="authbar">
              <div className="logobar" onClick={handleRedirectToLandingPage}>
                <Container>
                  {userRegister.user_type == constants.MACC ? (
                    <>
                      <img
                        style={{ width: "20vw" }}
                        src="/images/Macc.svg"
                        alt=""
                      />
                    </>
                  ) : (
                    <Link to="https://skortorent.com/">
                      <img src="../images/logo.svg" alt="logo" />
                    </Link>
                  )}
                </Container>
              </div>
              <Container>
                <div className="authbar-inner">
                  <div className="leftbar">
                    <div className="hero-center">
                      {localStorage.getItem("usertype") == "tenant" ? (
                        <img src="../images/TenantUserRole.webp" alt="logo" />
                      ) : (
                        <img
                          className="signp_img"
                          src="../images/LandlordUserRole.webp"
                          alt="logo"
                        />
                      )}
                    </div>
                    <div className="emptybar"></div>
                  </div>
                  <div className="rightbar">
                    <img
                      className="auth-shapetopright"
                      src="../images/handAuth.svg"
                      alt="logo"
                    />
                    <div className="right-contentbar">
                      <div className="backbtnbar">
                        <Link to="/login" className="backbtn">
                          <FaArrowLeft />
                          {_t("Go Back")}
                        </Link>
                      </div>
                      <div className="heading_lng_div">
                        <h4>
                          {_t("Sign Up as")}{" "}
                          {hash === "#tenant" ? "Tenant" : "Landlord"}
                        </h4>
                        <div class="menu-togglebar">
                          <label class="customradiobtn">
                            <input
                              type="radio"
                              onClick={handleLanguageChange}
                              checked={currentLang == "en-us" ? "checked" : ""}
                              name="radio"
                              value={"en-us"}
                            />
                            <span class="checkmark1">EN</span>
                          </label>
                          <label class="customradiobtn">
                            <input
                              type="radio"
                              onClick={handleLanguageChange}
                              checked={currentLang == "es" ? "checked" : ""}
                              name="radio"
                              value={"es"}
                            />
                            <span class="checkmark1">ES</span>
                          </label>
                        </div>
                      </div>

                      <form className="loginform" onSubmit={onSubmit}>
                        <div className="linkedbtn">
                          <Button className="btn-fill" onClick={linkedinSignup}>
                            <BsLinkedin />
                            {_t("Sign Up With LinkedIn")}
                          </Button>
                        </div>
                        <div className="or-bar">
                          <p>{_t("Or")}</p>
                        </div>
                        <div className="auth-form d-blockform">
                          <div className="forminput">
                            <label className="forminput">{_t("Email")}</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="demo@xyz"
                              onChange={onChange}
                            />
                          </div>
                          <div className="gridbar_block">
                            <div className="forminput sign-up-spacing">
                              <label className="forminput d-flex align-items-center gap-1">
                                {_t("Enter Password")}
                                <ul className="infodorpdown summarydropdum position-relative">
                                  <li>
                                    <button
                                      type="button"
                                      className="iconinfo d-flex"
                                    >
                                      <MdInfoOutline />
                                    </button>{" "}
                                  </li>
                                  <ul className="tooltip">
                                    <li>
                                      {_t(
                                        "Password must be atleast 8 characters long and contain a number, lowercase, uppercase and a special character"
                                      )}
                                      .
                                    </li>
                                  </ul>
                                </ul>
                              </label>
                              <div className="password-div">
                                <input
                                  type={passwordType}
                                  className="form-control"
                                  name="password"
                                  placeholder={_t("Password")}
                                  onChange={onChange}
                                />
                                {showPassword ? (
                                  <VscEye
                                    className="eye-icons"
                                    onClick={(e) => handleShowPassword(false)}
                                  />
                                ) : (
                                  <VscEyeClosed
                                    className="eye-icons"
                                    onClick={(e) => handleShowPassword(true)}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="forminput sign-up-spacing">
                              <label className="forminput d-flex align-items-center">
                                {_t("Re-Enter Password")}
                              </label>
                              <div className="password-div">
                                <input
                                  type={rePasswordType}
                                  className="form-control"
                                  name="confirmPassword"
                                  placeholder={_t("Confirm Password")}
                                  onChange={onChange}
                                />
                                {showRePassword ? (
                                  <VscEye
                                    className="eye-icons"
                                    onClick={(e) => handleShowRePassword(false)}
                                  />
                                ) : (
                                  <VscEyeClosed
                                    className="eye-icons"
                                    onClick={(e) => handleShowRePassword(true)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            {haveValue(pwdErrorMsg) && (
                              <label className="forminput password_error">
                                {pwdErrorMsg}
                              </label>
                            )}
                            {/* <label className="forminput password_condition">{_t("Password must be atleast 8 characters long and contain a number, lowercase, uppercase and a special character")}</label> */}
                          </div>
                          <div className="forminput checkboxtc">
                            <input
                              type="checkbox"
                              id="termsCheckbox"
                              name="termsCheckbox"
                              onClick={(e) =>
                                setUserregister((old) => ({
                                  ...old,
                                  checked: !old.checked,
                                }))
                              }
                            />
                            <label
                              htmlFor="termsCheckbox"
                              className="forminput"
                            >
                              {_t("Agree to all the")}{" "}
                              <a
                                href={
                                  localStorage.getItem("i18nextLng") == "es"
                                    ? "https://skortorent.com/es/terms-conditions.html"
                                    : "https://skortorent.com/terms-conditions.html"
                                }
                                target="_blank"
                                className="setNoInfoColor"
                              >
                                {_t("Terms & Conditions")}
                              </a>
                            </label>
                          </div>
                          <div className="btngroup">
                            <button className="btn btnorange" type="submit">
                              {_t("Register")}
                            </button>
                            <h4 className="signup-text">
                              {_t("Already have an account")}?{" "}
                              <Link
                                to={`/login#${localStorage.getItem(
                                  "usertype"
                                )}`}
                              >
                                {_t("Login")}
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="hero-foot">
                      <Container>
                        <div className="terms-bar">
                          {localStorage.getItem("i18nextLng") == "es" ? (
                            <Link
                              className="link-bar"
                              to="https://skortorent.com/es/terms-conditions.html"
                            >
                              {_t("Terms & Conditions")}
                            </Link>
                          ) : (
                            <Link
                              className="link-bar"
                              to="https://skortorent.com/terms-conditions.html"
                            >
                              {_t("Terms & Conditions")}
                            </Link>
                          )}
                          {localStorage.getItem("i18nextLng") == "es" ? (
                            <Link
                              className="link-bar"
                              to="https://skortorent.com/es/privacy-policy.html"
                            >
                              {" "}
                              {_t("Privacy Policy")}
                            </Link>
                          ) : (
                            <Link
                              className="link-bar"
                              to="https://skortorent.com/privacy-policy.html"
                            >
                              {" "}
                              {_t("Privacy Policy")}
                            </Link>
                          )}
                          {localStorage.getItem("i18nextLng") == "es" ? (
                            <Link
                              className="link-bar"
                              to="https://skortorent.com/es/cookies-es.html"
                            >
                              {" "}
                              {_t("Cookies Policy")}
                            </Link>
                          ) : (
                            <Link
                              className="link-bar"
                              to="https://skortorent.com/cookies.html"
                            >
                              {" "}
                              {_t("Cookies Policy")}
                            </Link>
                          )}
                        </div>
                      </Container>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (otppage === 1) {
    return (
      <Fragment>
        <div className="Auth-wrapper">
          <div className="Auth-bar">
            <div className="logobar">
              <Container className="p-0">
                <img src="../images/logo.svg" alt="logo" />
              </Container>
            </div>

            <div className="authbar">
              <Container>
                <div className="authbar-inner">
                  <div className="leftbar">
                    <h2>
                      {_t("Sign Up As")} {userRegister.type}
                    </h2>
                  </div>
                  <div className="rightbar">
                    <div className="right-contentbar">
                      <div className="right-contentbar">
                        <div className="auth-form"></div>
                        <div className="login-bottom">
                          <h4 className="signup-text">
                            {_t("Already have an account")}?{" "}
                            <Link to="/login">{_t("Login")}</Link>
                          </h4>
                          <div className="terms-bar">
                            {/* <Link to='/' className="none-under">Not A {userRegister.type}? </Link> */}
                            {/* <Link to='/register'>Click here</Link> */}
                          </div>
                          <div className="terms-bar">
                            {localStorage.getItem("i18nextLng") == "es" ? (
                              <Link to="https://skortorent.com/es/terms-conditions.html">
                                {_t("Terms & Conditions")},
                              </Link>
                            ) : (
                              <Link to="https://skortorent.com/terms-conditions.html">
                                {_t("Terms & Conditions")},
                              </Link>
                            )}
                            {localStorage.getItem("i18nextLng") == "es" ? (
                              <Link to="https://skortorent.com/es/privacy-policy.html">
                                {" "}
                                {_t("Privacy Policy")}
                              </Link>
                            ) : (
                              <Link to="https://skortorent.com/privacy-policy.html">
                                {" "}
                                {_t("Privacy Policy")}
                              </Link>
                            )}
                            {localStorage.getItem("i18nextLng") == "es" ? (
                              <Link to="https://skortorent.com/es/cookies-es.html">
                                {" "}
                                {_t("Cookies Policy")}
                              </Link>
                            ) : (
                              <Link to="https://skortorent.com/cookies.html">
                                {" "}
                                {_t("Cookies Policy")}
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  if (otppage === 2) {
    return (
      <Fragment>
        <div className="Auth-wrapper-2">
          <div className="logobar">
            <Container className="p-0 text-center">
              <div className="go-back-home">
                <a href={undefined} onClick={handleGoHome}>
                  <svg
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="27"
                      cy="27"
                      r="26.5"
                      fill="#253837"
                      stroke="#A3EB64"
                    />
                    <path
                      d="M24.9821 22.1376L24.9884 22.1314L24.995 22.1256C25.0335 22.0911 25.0632 22.0505 25.0834 22.007C25.1035 21.9635 25.114 21.9173 25.1149 21.8711C25.1157 21.8249 25.1069 21.7785 25.0885 21.7346C25.07 21.6906 25.0419 21.6493 25.0049 21.6137C24.9679 21.578 24.9226 21.5489 24.8714 21.529C24.8202 21.5091 24.7647 21.4991 24.7085 21.5001C24.6522 21.501 24.5972 21.5129 24.5469 21.5344C24.4967 21.556 24.4527 21.5865 24.4172 21.6232L24.4111 21.6295L24.4047 21.6356L19.6098 26.2488C19.6098 26.2489 19.6097 26.2489 19.6097 26.2489C19.5371 26.319 19.5 26.4097 19.5 26.5C19.5 26.5903 19.5371 26.681 19.6097 26.7511C19.6097 26.7511 19.6098 26.7511 19.6098 26.7512L24.4047 31.3644L24.4111 31.3705L24.4172 31.3768C24.4527 31.4135 24.4967 31.444 24.5469 31.4656C24.5972 31.4871 24.6522 31.499 24.7085 31.4999C24.7647 31.5009 24.8202 31.4909 24.8714 31.471C24.9226 31.4511 24.9679 31.422 25.0049 31.3863C25.0419 31.3507 25.07 31.3094 25.0885 31.2654C25.1069 31.2215 25.1157 31.1751 25.1149 31.1289C25.114 31.0827 25.1035 31.0365 25.0834 30.993C25.0632 30.9495 25.0335 30.909 24.995 30.8744L24.9884 30.8686L24.9821 30.8624L21.7214 27.7253L20.8272 26.865L22.0681 26.865L33.6842 26.865C33.7962 26.865 33.8998 26.822 33.9733 26.7513C34.0462 26.6813 34.0833 26.5904 34.0833 26.5C34.0833 26.4096 34.0462 26.3187 33.9733 26.2487C33.8998 26.178 33.7962 26.135 33.6842 26.135L22.0681 26.135L20.8272 26.135L21.7214 25.2747L24.9821 22.1376Z"
                      fill="#FFB65A"
                      stroke="white"
                    />
                  </svg>
                  <span>{_t("Go Back To Home")} </span>
                </a>
              </div>
              <img src="../images/logo.svg" id="wrapper-3-logo" alt="logo" />
            </Container>
          </div>
          <Container>
            <div className="authbar-inner">
              <div className="fullbar">
                <div className="right-contentbar">
                  {/* OTP Screen  */}
                  <div className="resend-text">
                    <h4 className="text-none">
                      {_t("OTP Sent On ")}
                      {userRegister?.email}
                    </h4>
                    <p>{_t("Please enter the code sent to your email")}</p>
                    <p style={{ color: "#EF7833" }}>
                      <i>({_t("Note: Kindly check spam as well")}.)</i>
                    </p>
                    <Form className="loginform otp-Verification">
                      <OTPInput
                        className="forminput"
                        value={OTP}
                        onChange={otpinputChange}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                      />
                    </Form>

                    {!showResend && (
                      <p className="timer">
                        {" "}
                        {_t("Resend code")} <span>{showTimer}s</span>
                      </p>
                    )}
                    {showResend && (
                      <Button className="btn-fill" onClick={sendOtpAgain}>
                        {_t("Resend OTP")}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className="terms-bar">
              {localStorage.getItem("i18nextLng") == "es" ? (
                <Link
                  className="link-bar"
                  to="https://skortorent.com/es/terms-conditions.html"
                >
                  {_t("Terms & Conditions")}
                </Link>
              ) : (
                <Link
                  className="link-bar"
                  to="https://skortorent.com/terms-conditions.html"
                >
                  {_t("Terms & Conditions")}
                </Link>
              )}
              {localStorage.getItem("i18nextLng") == "es" ? (
                <Link
                  className="link-bar"
                  to="https://skortorent.com/es/privacy-policy.html"
                >
                  {" "}
                  {_t("Privacy Policy")}
                </Link>
              ) : (
                <Link
                  className="link-bar"
                  to="https://skortorent.com/privacy-policy.html"
                >
                  {" "}
                  {_t("Privacy Policy")}
                </Link>
              )}
              {localStorage.getItem("i18nextLng") == "es" ? (
                <Link
                  className="link-bar"
                  to="https://skortorent.com/es/cookies-es.html"
                >
                  {" "}
                  {_t("Cookies Policy")}
                </Link>
              ) : (
                <Link
                  className="link-bar"
                  to="https://skortorent.com/cookies.html"
                >
                  {" "}
                  {_t("Cookies Policy")}
                </Link>
              )}
            </div>
          </Container>
        </div>
      </Fragment>
    );
  }
};

export default Register;
